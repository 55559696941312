import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import logo from '../assets/images/d6-support-logo.png';
import '../assets/styles/Header.scss';
import { connect } from "react-redux";
import MobileNav from './mobile-nav-v2';
import store from "../redux-js/store/index";
import axios from "axios";

import jQuery from 'jquery';
jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}





class Header extends Component {

    constructor(props){
        super(props);

        this.state = {

            client: {
                portal_first_page_url:'/',
                portal_first_report_url:'/',
                projects_url: '/',
                is_seo_client: "0"
            },
            info_fetched: false,
            page_slug: '',
            client_slug: '',
            current_user:{
                photo: '',
                role: '',
                photo_letter: 'D'
            },

            photo_letter : '',

            show_user_actions_popup: false
        }

        this.forceRerender          = this.forceRerender.bind(this);
        this.toggleUserActionPopup  = this.toggleUserActionPopup.bind(this);
        this.handleClickOutside     = this.handleClickOutside.bind(this);



        //Here ya go
        this.unlisten = this.props.history.listen((location, action) => {
            this.setCurrentPage(location.pathname);
        });


    }

    setCurrentPage(url){

        setTimeout(function () {

            if (url.indexOf('/portal/page/') !== -1){

                jQuery('.main-menu .nav-item:not(.nav-dashboard)').removeClass('cur');
                jQuery('.main-menu .nav-dashboard').addClass('cur');

            } else if (url.indexOf('/seo-reports/report/') !== -1){

                jQuery('.main-menu .nav-item:not(.nav-reports)').removeClass('cur');
                jQuery('.main-menu .nav-reports').addClass('cur');

            }
            else if (url.indexOf('/make-request/') !== -1){

                jQuery('.main-menu .nav-item:not(.nav-make-request)').removeClass('cur');
                jQuery('.main-menu .nav-make-request').addClass('cur');
            }
            else if (url.indexOf('/portal/projects/') !== -1){

                jQuery('.main-menu .nav-item:not(.nav-work-requests)').removeClass('cur');
                jQuery('.main-menu .nav-work-requests').addClass('cur');

            }
            else if (url.indexOf('/d6-flex/') !== -1){

                jQuery('.main-menu .nav-item:not(.nav-d6-flex)').removeClass('cur');
                jQuery('.main-menu .nav-d6-flex').addClass('cur');

            }

        },1);
    }

    setClientInfo(page, slug){

        let ds = this;


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/client/get-info/'+ slug + '/' + page + '/')

            .then(res => {

                this.setState({
                    info_fetched: true
                });

                if (res.data.slug){

                    this.setState({
                        client: res.data,
                        info_fetched: true
                    })

                } else{

                    if(ds.props.current_user.role === 'client'){

                        window.location.href = '/' + ds.props.current_user.slug + '/profile/';
                        //this.props.history.push('/projects/' + this.props.current_user.slug + '/' );

                    }else{

                        //window.location.href = '/admin/';
                        //this.props.history.push('/' );
                    }
                }
            })

            .catch((error) => {


            });

    }

    componentDidMount(){

        this.unsubscribe = store.subscribe(() => {

            let store_state = store.getState();

            if(store_state){


                if(store_state.site_data.page === 'portal' && store_state.project_book_slug !== this.state.page_slug){

                    this.setState({page_slug: store_state.project_book_slug});
                    this.setClientInfo( 'portal', store_state.project_book_slug);
                }

                else if (store_state.site_data.page === 'project-scope' && store_state.client_slug !== this.state.client_slug){

                    this.setState({client_slug: store_state.site_data.client_slug});
                    this.setClientInfo( 'project-scope', store_state.site_data.client_slug);
                }

                else if (store_state.site_data.page === 'project-scope-single' && store_state.page_slug !== this.state.page_slug){
                    this.setState({page_slug: store_state.site_data.page_slug});
                    this.setClientInfo( 'project-scope-single', store_state.site_data.page_slug);
                }

                else if (store_state.site_data.page === 'profile' && store_state.page_slug !== this.state.page_slug){

                    this.setState({page_slug: store_state.site_data.client_slug});
                    this.setClientInfo( 'profile', store_state.site_data.client_slug);
                }

            }

            //this.props.setPaymentMade(true);

        });



        this.setState({current_user: this.props.current_user});

        if (this.props.current_user.first_name){
            this.setState({photo_letter : this.props.current_user.first_name.charAt(0)});
        }

        document.addEventListener('mousedown', this.handleClickOutside);


        //nav-item

    }


    componentWillUnmount() {

        this.unsubscribe();
        this.unlisten();

        document.removeEventListener('mousedown', this.handleClickOutside);
    }



    componentWillReceiveProps(nextProps) {



    }


    forceRerender(){


        //this is to fix the NavLink activeClassName
        this.setState({rand: Math.random()});
    }


    handleClickOutside(event) {

        if (! jQuery(event.target).closest('.user-action').length){

            if (this.state.show_user_actions_popup){
                this.setState({show_user_actions_popup: false});
            }

        }
    }

    toggleUserActionPopup(e){

        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        this.setState({show_user_actions_popup: !this.state.show_user_actions_popup});

    }


    render() {



        if( !this.state.info_fetched ){
            return (<div style={{height:'71px'}}></div>);
        }

        this.setCurrentPage(this.props.location.pathname);



        if ( this.state.current_user.wp_user_id ){



            if (this.state.current_user.role === 'client'){

                //console.log(this.state.current_user);

                return (

                    <div>
                        <MobileNav client={this.state.client} />

                        <header className="v2-header">

                            <div className="header-container">
                                <a href="#nav-open" className="breadcrumbs"><span></span></a>

                                <div className="header-wrap">
                                    <div className="logo-wrap">
                                        <Link to={this.state.client.portal_first_page_url} className="logo"><img src={logo} className="App-logo" alt="logo" /></Link>
                                    </div>


                                    <div className="logged-user">

                                        {
                                            (this.state.client && this.state.client.company) &&

                                            <div>{this.state.client.company}</div>
                                        }


                                        <div className="photo user-action" onClick={this.toggleUserActionPopup}>


                                            {
                                                (this.state.client && this.state.client.photo) &&

                                                <img src={this.state.client.photo} alt=""/>
                                            }

                                            {/*
                                             (this.state.current_user.photo)
                                             ? (<img src={this.state.current_user.photo} alt=""/>)
                                             : (<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                             */
                                            }

                                        </div>

                                        <div className={'user-actions'}>
                                            <span className="arrow user-action" onClick={this.toggleUserActionPopup}><i className="fas fa-chevron-down"></i></span>

                                            <div className={'user-action-popup user-action'  + ((this.state.show_user_actions_popup)?' show':'')} onClick={this.toggleUserActionPopup}>
                                                <ul>

                                                    <li><Link to={'/' + this.state.current_user.slug + '/profile'} className="signout-link"><span>Profile</span></Link></li>
                                                    <li><Link to={'/' + this.state.current_user.slug + '/billing'} className="signout-link"><span>Billing</span></Link></li>
                                                    <li><Link to={'/logout'} className="signout-link"><span>Sign-Out</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    <nav>
                                        <ul className="main-menu">

                                            {
                                                this.state.client.portal_first_page_url !== '' &&

                                                <li>
                                                    <NavLink className="nav-item nav-dashboard" to={this.state.client.portal_first_page_url}><span>Projects</span></NavLink>
                                                </li>
                                            }


                                            {
                                                this.state.client.is_seo_client === "1" &&

                                                <li>
                                                    <NavLink className="nav-item nav-reports" to={this.state.client.portal_first_report_url}><span>Reports</span></NavLink>
                                                </li>
                                            }


                                            {
                                                this.state.client.projects_url &&
                                                <li>
                                                    <NavLink className="nav-item nav-work-requests" to={this.state.client.projects_url}><span>Work Requests</span></NavLink>
                                                </li>
                                            }

                                            <li>
                                                <NavLink className="nav-item nav-make-request" to={'/portal/projects/'+this.state.current_user.slug+'/make-request/'}><span>Make a Request</span></NavLink>
                                            </li>

                                            {/*<li>*/}
                                                {/*<NavLink className="nav-item nav-d6-flex" to="/d6-flex/templates"><span>D6 Flex</span></NavLink>*/}
                                            {/*</li>*/}

                                        </ul>
                                    </nav>

                                </div>
                            </div>

                        </header>
                    </div>

                )


            } else {


                return (

                    <div>
                        <MobileNav client={this.state.client} />

                        <header className="v2-header">



                            <div className="header-container">
                                <a href="#nav-open" className="breadcrumbs"><span></span></a>
                                <div className="header-wrap">

                                    <div className="logo-wrap">
                                        <Link to={'/admin/'} className="logo"><img src={logo} className="App-logo" alt="logo"/></Link>
                                    </div>

                                    <div className="logged-user">



                                        {
                                            (this.state.client && this.state.client.company) &&

                                            <div>{this.state.client.company}</div>
                                        }


                                        <div className="photo user-action" onClick={this.toggleUserActionPopup}>

                                            {
                                                (this.state.client && this.state.client.photo) &&

                                                <img src={this.state.client.photo} alt=""/>
                                            }

                                        </div>

                                        <div className={'user-actions'}>
                                            <span className="arrow user-action" onClick={this.toggleUserActionPopup}><i className="fas fa-chevron-down"></i></span>

                                            <div className={'user-action-popup user-action' + ((this.state.show_user_actions_popup)?' show':'')} onClick={this.toggleUserActionPopup}>
                                                <ul>
                                                    <li><Link to={'/admin/'} className="signout-link"><span>Admin</span></Link></li>
                                                    <li><Link to={'/logout'} className="signout-link"><span>Sign-Out</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <nav>
                                        <ul className="main-menu">

                                            {
                                                this.state.client.portal_first_page_url !== '' &&

                                                <li>
                                                    <NavLink className="nav-item nav-dashboard" to={this.state.client.portal_first_page_url}><span>Projects</span></NavLink>
                                                </li>
                                            }


                                            {
                                                this.state.client.is_seo_client === "1" &&

                                                <li>
                                                    <NavLink className="nav-item nav-reports" to={this.state.client.portal_first_report_url}><span>Reports</span></NavLink>
                                                </li>
                                            }

                                            {
                                                this.state.client.projects_url &&
                                                <li>
                                                    <NavLink className="nav-item nav-work-requests" to={this.state.client.projects_url}><span>Work Requests</span></NavLink>
                                                </li>
                                            }



                                            {
                                                this.state.client.projects_url && this.state.client.slug &&
                                                <li>
                                                    <NavLink className="nav-item nav-make-request"
                                                             to={'/portal/projects/' + this.state.client.slug + '/make-request/'}><span>Make a Request</span></NavLink>
                                                </li>
                                            }


                                            {/*<li>*/}
                                                {/*<NavLink className="nav-item nav-d6-flex" to="/d6-flex/templates"><span>D6 Flex</span></NavLink>*/}
                                            {/*</li>*/}



                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </header>
                    </div>

                )

            }



        }


        else{



            return (

                <div>
                    <MobileNav client={this.state.client} />

                    <header className="v2-header">

                        <div className="header-container">
                            <a href="#nav-open" className="breadcrumbs"><span></span></a>
                            <div className="header-wrap">
                                <div className="logo-wrap">

                                    {
                                        this.state.client.portal_first_page_url !== '' &&
                                        <Link to={this.state.client.portal_first_page_url} className="logo"><img src={logo} className="App-logo" alt="logo" /></Link>
                                    }

                                    {
                                        this.state.client.portal_first_page_url === '' &&
                                        <Link to="/login" className="logo"><img src={logo} className="App-logo" alt="logo" /></Link>
                                    }

                                </div>

                                <div className="logged-user">

                                    {
                                        (this.state.client && this.state.client.company) &&

                                        <div>{this.state.client.company}</div>
                                    }


                                    <div className="photo user-action" onClick={this.toggleUserActionPopup}>


                                        {
                                            (this.state.client && this.state.client.photo) &&

                                            <img src={this.state.client.photo} alt=""/>
                                        }


                                    </div>

                                    <div className={'user-actions'}>
                                        <span className="arrow user-action" onClick={this.toggleUserActionPopup}><i className="fas fa-chevron-down"></i></span>

                                        <div className={'user-action-popup user-action'  + ((this.state.show_user_actions_popup)?' show':'')} onClick={this.toggleUserActionPopup}>
                                            <ul>
                                                <li><Link to={'/login'} className="signout-link"><span>Sign-in</span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <nav>
                                    <ul className="main-menu">


                                        {

                                            this.state.client.portal_first_page_url !== '' &&
                                            <li>
                                                <NavLink className="nav-item nav-dashboard" to={this.state.client.portal_first_page_url}><span>Projects</span></NavLink>
                                            </li>
                                        }


                                        {
                                            this.state.client.is_seo_client === "1" &&

                                            <li>
                                                <NavLink className="nav-item nav-reports" to={this.state.client.portal_first_report_url}><span>Reports</span></NavLink>
                                            </li>
                                        }

                                        {
                                            this.state.client.projects_url &&
                                            <li>
                                                <NavLink className="nav-item nav-work-requests" to={this.state.client.projects_url}><span>Work Requests</span></NavLink>
                                            </li>
                                        }


                                        {
                                            this.state.client.slug &&

                                            <li>
                                                <NavLink className="nav-item nav-make-request" to={'/portal/projects/'+this.state.client.slug+'/make-request/'}><span>Make a Request</span></NavLink>
                                            </li>
                                        }


                                        {
                                            !this.state.client.slug &&

                                            <li>
                                                <NavLink className="nav-item nav-make-request" to={'/make-request/'}><span>Make a Request</span></NavLink>
                                            </li>
                                        }



                                        {/*<li>*/}
                                            {/*<NavLink className="nav-item nav-d6-flex" to="/d6-flex/templates"><span>D6 Flex</span></NavLink>*/}
                                        {/*</li>*/}


                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </header>
                </div>

            )

        }


    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));