import React, { Component } from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment';
import { convertServerDateToLocal } from '../../helpers/helpers'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class SupportRequests extends Component {

    constructor(props) {
        super(props);

        this.state = {
            requests_fetched: false,
        };



    }


    componentWillUnmount(){


    }


    fetchData(filter_status){

        //console.log('work_request_slug',this.props.work_request_slug);


        //let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };
        let headers = { };




        axios.get(process.env.REACT_APP_API_ENDPOINT + '/get-requests/' + this.props.client_slug + '/?status='+filter_status, headers )
            .then(res => {

                let requests = res.data.requests;

                if( requests ) {

                    this.setState({
                        requests: requests,
                    });
                }

                this.setState({
                    requests_fetched: true,
                });

            })

            .catch((error) => {

                this.setState({
                    request_fetched: true,
                })
            });

    }

    componentDidMount(){

        let filter_status = this.props.filter_status?this.props.filter_status:'';

        this.fetchData(filter_status);

    }

    componentWillReceiveProps(nextProps){


        let filter_status = nextProps.filter_status ? nextProps.filter_status : '';

        this.fetchData(filter_status);
    }



    getDate(date){


        var d = convertServerDateToLocal(date);

        return moment(d).fromNow(true) + ' ago';

    }

    getStatusClass(status){

        return 'status-' + status.toLowerCase(status);

    }

    render() {

        if (!this.state.requests_fetched){

            return <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
        }


        return (
            <div className="support-requests-wrap">
                <div className="card">

                    <div className="card-header d-flex align-items-center justify-content-between">
                        <h6 className="mg-b-0">Support Requests</h6>
                    </div>

                    <div className="list-group list-group-flush">




                        {

                            this.state.requests.length <= 0 &&
                            <div className="list-group-item ">No results</div>
                        }


                        {
                            this.state.requests.length > 0 &&

                            <div>
                                {this.state.requests.map((request, i) =>
                                    <div className="request-item list-group-item pd-sm-x-20" key={i}>
                                        <div className="row tx-13">
                                            <div className="col-md-6"><Link to={'/portal/projects/'+this.props.client_slug+'/request/'+request.long_id}>{request.subject}</Link></div>
                                            <div className="col-md-2">{request.first_name + ' '+ request.last_name}</div>
                                            <div className="col-md-2"><span className={'request-status '+ this.getStatusClass(request.status)}>{request.status}</span></div>
                                            <div className="col-md-2"><span className="date" title={request.date_added}>{this.getDate(request.date_added)}</span></div>

                                        </div>
                                    </div>
                                )}
                            </div>

                        }


                    </div>
                </div>
            </div>
        );
    }
}


const Comments = connect(mapStateToProps, mapDispatchToProps)(SupportRequests);


export default Comments;


