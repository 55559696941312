import React, { Component } from "react";
import axios from 'axios';
//import { Link } from "react-router-dom";
import Dropzone from 'react-dropzone'
import Editor  from './editor';
import { EditorState, ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';


//import {stateToHTML} from 'draft-js-export-html';
//import {stateFromHTML} from 'draft-js-import-html';
//import { createEditorStateWithText } from 'draft-js-plugins-editor';

import { connect } from "react-redux";
import { cacheContent } from "../../../redux-js/actions/index";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        cacheContent: content => dispatch(cacheContent(content))
    };
}


class InteractiveMakeRequestForm extends Component {

    constructor(props) {
        super(props);

        let plainText = "";

        //console.log(localStorage.getItem("request_content"));

        if (localStorage.getItem("request_content")){
            plainText = localStorage.getItem("request_content");
        }


        const blocksFromHtml = htmlToDraft(plainText);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);


        this.features  =[
            {
                name:'Form',icon:'fab fa-wpforms',checked:false,
                list:[
                    {name: 'Contact Form', checked: false}, { name: 'Opt-in Form', checked: false}, { name: 'Request a Quote Form', checked: false}, { name: 'Other', checked: false}
                ],
                other_field: false,
                other_value: ''
            },
            {
                name:'Section',icon:'fa fa-th-large',checked:false,
                list:[
                    {name: 'Call to Action', checked: false}, {name: 'Text/Image', checked: false}, {name: 'Text/Video', checked: false}, {name: 'Image Slider', checked: false}, {name: 'Blockquote', checked: false}, {name: 'Testimonials', checked: false}, {name: 'FAQ', checked: false}, {name: 'Other', checked: false}
                ],
                other_field: false,
                other_value: ''
            },
            {
                name:'Page',icon:'fab fa-wpforms',checked:false,
                list:[
                    {name: 'Landing Page', checked: false}, {name: 'Testimonials', checked: false}, {name: 'Photo Gallery', checked: false}, {name: 'Videos', checked: false}, {name: 'Contact Us', checked: false}, {name: 'About', checked: false}, {name: 'Services', checked: false}, {name: 'Career', checked: false}, {name: 'Blog', checked: false}, {name: 'Other', checked: false}
                ],
                other_field: false,
                other_value: ''
            },
            {
                name:'Integration',icon:'fa fa-cubes',checked:false,
                list:[
                    {name: 'Google Maps', checked: false}, {name: 'MailChimp', checked: false}, {name: 'Constant Contact', checked: false}, {name: 'Active Campaign', checked: false}, {name: 'Facebook', checked: false}, {name: 'Instagram', checked: false}, {name: 'Twitter', checked: false}, {name: 'Other', checked: false}
                ],
                other_field: false,
                other_value: ''
            },
            {
                name:'SEO',icon:'fa fa-chart-bar',checked:false,
                list:[
                    {name: 'Setup Google Analytics', checked: false}, {name: 'Install Yoast SEO', checked: false}, {name: 'Meta Tags/Description', checked: false}, {name: 'Other', checked: false}
                ],
                other_field: false,
                other_value: ''
            },
            {
                name:'Hosting',icon:'fa fa-tools',checked:false,
                list:[
                    {name: 'Migration', checked: false}, {name: 'Install SSL', checked: false}, {name: 'Other', checked: false}
                ],
                other_field: false,
                other_value: ''
            }
        ];

        this.state = {

            errorMsg: '',
            msg: '',
            content: '',
            attachments: [],
            btnEnabled: true,
            cache_key: '',
            email:'',
            first_name:'',
            last_name:'',
            editorState: editorState,//createEditorStateWithText((plainText))
            features: JSON.parse(JSON.stringify(this.features))

        };



        this.handleInputChange       = this.handleInputChange.bind(this);
        this.handleOtherInputChange  = this.handleOtherInputChange.bind(this);
        this.handleSubmit            = this.handleSubmit.bind(this);
        this.handleDropFiles         = this.handleDropFiles.bind(this);
        this.editorContent           = this.editorContent.bind(this);


        this.theEditor = React.createRef();


    }



    componentDidMount(){


        if (this.props.client_slug){

            this.setState({cache_key: 'requests-' + this.props.client_slug});

        }

        this.setState({
            first_name: this.props.current_user.first_name,
            last_name: this.props.current_user.last_name,
            email: this.props.current_user.email,
        });

        let ds = this;

        window.$j(document).on('click','.editor-placeholder',function () {

            window.$j(this).css({display:'none'});

            if(ds.theEditor.current) {
                ds.theEditor.current.focus();
            }

        });

        //console.log(this.props);

    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleOtherInputChange(event, idx) {

        const target = event.target;
        const value = target.value;

        let features = this.state.features;
        features[idx].other_value = value;

        this.setState({features:features});
    }


    editorContent(content){

        this.setState({content:content});

        localStorage.setItem("request_content",content);
        //stateToHTML(window.testsasfdasdf.current.editor.getEditorState().getCurrentContent())

    }

    handleSubmit(event) {

        event.preventDefault();

        this.setState({'btnEnabled': false});


        let formData = new FormData();

        this.state.attachments.map((file) => {
            return formData.append('Files[]',file);
        });

        formData.append('email', this.state.email);
        formData.append('first_name', this.state.first_name);
        formData.append('last_name', this.state.last_name);
        formData.append('content', this.state.content);
        formData.append('features', JSON.stringify(this.state.features));


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token, 'Content-Type': 'multipart/form-data'} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/support-requests-v2', formData , headers)
            .then(res => {

                //console.log(res);
                //console.log(res.data);

                if (res.data.success){

                    this.setState({
                        email: '',
                        first_name: '',
                        last_name: '',
                        msg: res.data.msg,
                        errorMsg: '',
                        attachments: [],
                        features: this.features
                        //editorState: EditorState.createEmpty()// NOT proper way to reset
                    });

                    console.log(this.features);


                    localStorage.setItem("request_content", "");


                    if (this.state.cache_key){
                        //clear the cache
                        this.props.cacheContent({key: this.state.cache_key, content: '' });

                    }

                    this.theEditor.current.clearEditor();


                }else {

                    this.setState({
                        errorMsg: res.data.msg
                    });

                }

                this.setState({'btnEnabled': true});
            })

            .catch((error) => {

                //console.log(error.response.data);
                this.setState({
                    errorMsg: error.response.data.message
                });
                this.setState({'btnEnabled': true});

            });


    }


    handleDropFiles (acceptedFiles){

        let attachments = this.state.attachments;


        for (var i = 0; i < acceptedFiles.length; i++){
            attachments.push(acceptedFiles[i]);
        }

        this.setState({attachments:attachments});

    }

    deleteAttachment(e, idx){


        let attachments = this.state.attachments;

        attachments.splice(idx, 1);
        this.setState({attachments: attachments});


        //console.log(this.state.attachments);
    }



    toggleFeatureClick(idx){

        let features = this.state.features;
        features[idx].checked = !features[idx].checked;

        this.setState({features:features});
    }

    toggleSubFeatureClick(idx, idx2){

        let features = this.state.features;
        features[idx].list[idx2].checked = !features[idx].list[idx2].checked;

        if (features[idx].list[idx2].name === 'Other'){

            if (features[idx].list[idx2].checked){
                features[idx].other_field = true;
            }else{
                features[idx].other_field = false;
            }

        }

        this.setState({features:features});

    }

    render() {


        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;


        if(errorMsg){
            errorMsg = <AlertDanger state={this.state} />
        }

        if (msg){
            msg = <AlertSuccess state={this.state} />
        }

        return (

            <div>

                <div style={{padding:'50px 0'}}>
                    <p>Coming Soon</p>
                </div>

                {
                    false &&

                    <form className="request-form-interactive" style={{padding: '50px 0'}} onSubmit={this.handleSubmit}
                          ref={(f) => {
                              this.formRef = f;
                          }}>

                        <h3 className="h3-heading">Feature Requests</h3>
                        <h4 className="h4-heading">Would you like to specify any specific features?</h4>

                        <ul className="feature-list">
                            {this.state.features.map((feature, i) =>

                                <li onClick={() => this.toggleFeatureClick(i)}
                                    className={ feature.checked ? 'feature-item item-row checked' : 'feature-item item-row' }
                                    key={i}>
                                    <span>
                                        <i className={feature.icon}></i>
                                        {feature.name}
                                    </span>
                                </li>
                            )}
                        </ul>

                        <div>
                            {
                                this.state.features.map((feature, i) =>

                                    <div key={i}>
                                        {
                                            feature.checked &&

                                            <div className="feature-sub-list-wrap">
                                                <h4>{feature.name}</h4>

                                                <ul className="feature-list feature-sub-list">
                                                    {feature.list.map((list, x) =>
                                                        <li onClick={() => this.toggleSubFeatureClick(i, x)}
                                                            className={ list.checked ? 'feature-item item-row checked' : 'feature-item item-row' }
                                                            key={x}>
                                                        <span>
                                                            {list.name}
                                                        </span>
                                                        </li>
                                                    )}
                                                </ul>

                                                {
                                                    feature.other_field &&

                                                    <div className="other-item">
                                                        <input type="text" className="form-control"
                                                               placeholder="Please specify"
                                                               onChange={(e) => this.handleOtherInputChange(e, i)}/>
                                                    </div>
                                                }


                                            </div>

                                        }
                                    </div>
                                )}
                        </div>


                        <div className="form-editor">
                            <div className="form-group">


                                <div className="draftjs-editor-wrap">


                                    <Editor
                                        editorState={this.state.editorState}
                                        ref={this.theEditor}
                                        onChange={this.editorContent}
                                        placeholder=""
                                    />

                                    <div className="editor-placeholder">
                                        <div className="editor-placeholder-inner">
                                            <div className="user-photo"><img src={this.props.current_user.photo}
                                                                             alt=""/></div>
                                            Hello {this.props.current_user.company},<br /><br />
                                            Please describe your request here.
                                        </div>
                                    </div>


                                    <Dropzone
                                        onDrop={this.handleDropFiles}

                                        accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf, application/zip, application/atom+xml, audio/mp4, video/mp4,application/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/vnd.adobe.photoshop, text/csv, text/plain, text/html, application/postscript, "
                                        multiple={true}
                                        //maxSize={200000}
                                    >

                                        {({getRootProps, getInputProps}) => (
                                            <div className="attach-file-btn" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <span className="attach-icon"></span>
                                            </div>
                                        )}
                                    </Dropzone>


                                </div>

                                {
                                    this.state.attachments &&
                                    <div className="attachments">

                                        {this.state.attachments.map((attachment, i) =>
                                            <div className="attachment-item item-row" key={i}>
                                                <span onClick={((e) => this.deleteAttachment(e, i))}>&times;</span>
                                                {attachment.name}
                                            </div>
                                        )}

                                    </div>

                                }

                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <div className="form-label">Contact Email Address</div>
                                        <input placeholder="" name="email" type="text" value={this.state.email}
                                               onChange={this.handleInputChange} className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <div className="form-label">First Name</div>
                                        <input placeholder="" name="first_name" type="text"
                                               value={this.state.first_name} onChange={this.handleInputChange}
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <div className="form-label">Last Name</div>
                                        <input placeholder="" name="last_name" type="text" value={this.state.last_name}
                                               onChange={this.handleInputChange} className="form-control"/>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">


                                <div className="form-submit">
                                    <button disabled={!this.state.btnEnabled} className="btn btn-primary" type="submit">
                                        <span>Submit To D6</span></button>
                                </div>

                                {
                                    !this.state.btnEnabled &&
                                    <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                                }

                                {errorMsg}
                                {msg}

                            </div>
                        </div>
                    </form>
                }
            </div>
        );
    }
}



function AlertDanger(props) {

    let msg = (
        <div className="alert alert-danger">
            <div dangerouslySetInnerHTML={{__html: props.state.errorMsg}}></div>

        </div>
    );


    return ( msg );

}

function AlertSuccess(props) {


    let msg = (
        <div className="alert alert-success">
            <div dangerouslySetInnerHTML={{__html: props.state.msg}}></div>
        </div>
    );

    return ( msg );
}


export default connect(mapStateToProps, mapDispatchToProps)(InteractiveMakeRequestForm);
