import React, { Component } from "react";
import axios from 'axios';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import '../../assets/styles/Availability.scss';
import Calendar from '@toast-ui/react-calendar';
import moment from 'moment';
import Header from '../header';
import { setSiteData } from "../../redux-js/actions/index";


const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


const myTheme = {
    // Theme object to extends default dark theme.
};



class AvailabilityPage extends Component {

    calendarRef = React.createRef();

    constructor(props) {
        super(props);


        this.state = {
            calendar_fetched: false,
            calendarInstance: null,
            isReadOnly: true,
            renderRange: '',
            schedules:[

            ],
            timezones:[],
            calendarList: [

                {
                    id: '1',
                    name: 'My Calendar',
                    color: '#FFFFFF',
                    bgColor: '#9e5fff',
                    borderColor: '#9e5fff'
                },{
                    id: '2',
                    name: 'Meeting',
                    color: '#FFFFFF',
                    bgColor: '#bbdc00',
                    borderColor: '#bbdc00'
                },{
                    id: '3',
                    name: 'Private',
                    color: '#FFFFFF',
                    bgColor: '#03bd9e',
                    borderColor: '#03bd9e'
                }, {
                    id: '4',
                    name: 'Company',
                    color: '#FFFFFF',
                    bgColor: '#00a9ff',
                    borderColor: '#00a9ff'
                },{
                    id: '5',
                    name: 'Holiday',
                    color: '#FFFFFF',
                    bgColor: '#ff4040',
                    borderColor: '#ff4040'
                },{
                    id: '6',
                    name: 'Day Off',
                    color: '#FFFFFF',
                    bgColor: '#9d9d9d',
                    borderColor: '#9d9d9d'
                }
            ]
        };

    }


    handleClickNextButton = () => {

        this.state.calendarInstance.next();
        this.setRenderRangeText();
    };

    handleClickPrevButton = () => {


        this.state.calendarInstance.prev();
        this.setRenderRangeText();
    };


    handleClickTodayButton = () => {


        this.state.calendarInstance.today();
        this.setRenderRangeText();
    };


    handleClickDayname = (ev) => {
        // view : week, day
        console.group('onClickDayname');
        console.log(ev.date);
        console.groupEnd();
    };


    makeId() {

        let length   = 32;
        let text     = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }


     setRenderRangeText() {

        const calendarInstance = this.calendarRef.current.getInstance();

        let renderRange = document.getElementById('renderRange');
        let options = calendarInstance.getOptions();
        let viewName = calendarInstance.getViewName();
        let html = [];



        if (viewName === 'day') {

            html.push((calendarInstance.getDate().getTime()).format('YYYY.MM.DD'));

        } else if (viewName === 'month' &&

            (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)) {


            html.push(moment(calendarInstance.getDate().getTime()).format('MMMM'));
            html.push('<span>' + moment(calendarInstance.getDate().getTime()).format('YYYY') + '</span>');


        } else {
            html.push(moment(calendarInstance.getDateRangeStart().getTime()).format('YYYY.MM.DD'));
            html.push(' ~ ');
            html.push(moment(calendarInstance.getDateRangeEnd().getTime()).format(' MM.DD'));
        }

        renderRange.innerHTML = html.join('');
    }


    calEvents(){

        const calendarInstance = this.calendarRef.current.getInstance();

        let that = this;


        calendarInstance.on({
            'clickMore': function(e) {
                //console.log('clickMore', e);
            },
            'clickSchedule': function(e) {
                //console.log('clickSchedule', e);
            },
            'clickDayname': function(date) {
                //console.log('clickDayname', date);
            },
            'beforeCreateSchedule': function(e) {
                //console.log('beforeCreateSchedule', e);
                that.addSchedule(e)
            },
            'beforeUpdateSchedule': function(e) {

                that.updateSchedule(e);

            },
            'beforeDeleteSchedule': function(e) {
                //console.log('beforeDeleteSchedule', e);
                that.deleteSchedule(e);

            },
            'afterRenderSchedule': function(e) {
                //var schedule = e.schedule;
                //var element = calendarInstance.getElement(schedule.id, schedule.calendarId);
                //console.log('afterRenderSchedule', element);
            },
            'clickTimezonesCollapseBtn': function(timezonesCollapsed) {
                //console.log('timezonesCollapsed', timezonesCollapsed);

                if (timezonesCollapsed) {
                    that.state.calendarInstance.setTheme({
                        'week.daygridLeft.width': '77px',
                        'week.timegridLeft.width': '77px'
                    });
                } else {
                    that.state.calendarInstance.setTheme({
                        'week.daygridLeft.width': '60px',
                        'week.timegridLeft.width': '60px'
                    });
                }

                return true;
            }
        });


    }


    findCalendar(id) {

        let found;

        this.state.calendarList.forEach(function(calendar) {
            if (calendar.id === id) {
                found = calendar;
            }
        });

        return found || this.state.calendarList[1];
    }




    addSchedule(scheduleData){


        let schedule_id = this.makeId();

        let calendar = scheduleData.calendar || this.findCalendar(scheduleData.calendarId);

        let schedule = {
            id: schedule_id,
            title: scheduleData.title,
            isAllDay: scheduleData.isAllDay,
            start: scheduleData.start,
            end: scheduleData.end,
            category: scheduleData.isAllDay ? 'allday' : 'time',
            dueDateClass: '',
            location: scheduleData.location,
            raw: {
                class: scheduleData.raw['class']
            },
            state: scheduleData.state
        };


        if (calendar) {
            schedule.calendarId = calendar.id;
            schedule.color = calendar.color;
            schedule.bgColor = calendar.bgColor;
            schedule.borderColor = calendar.borderColor;
        }


        this.state.calendarInstance.createSchedules([schedule]);


        //this.refreshScheduleVisibility();


        let db_schedule = {

            'id'                : schedule_id,
            'calendarId'        : scheduleData.calendarId,
            'title'             : scheduleData.title,
            'start'             : scheduleData.start,
            'end'               : scheduleData.end,
            'isAllDay'          : scheduleData.isAllDay,
            'location'          : scheduleData.location,
            'state'             : scheduleData.state,

        };

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/calendar/schedule/add', db_schedule ,headers )
            .then(res => {

                if (res.data.success){



                }else{

                    alert('Error: Please try again!');

                }

            })

            .catch((error) => {
                localStorage.setItem('redirect',this.props.history.location.pathname);
                window.location.href = '/login';

            });


    }


    updateSchedule(scheduleData){



        scheduleData.schedule.start = scheduleData.start;
        scheduleData.schedule.end = scheduleData.end;

        this.state.calendarInstance.updateSchedule(scheduleData.schedule.id, scheduleData.schedule.calendarId, scheduleData.schedule);



        let currentSchedules = this.state.schedules;


        currentSchedules.push(

            {
                id: 2,
                calendarId: scheduleData.schedule.calendarId,
                title: scheduleData.schedule.title,
                category: 'allday',
                dueDateClass: '',
                start: scheduleData.schedule.start,
                end: scheduleData.schedule.end
            }

        );



        let db_schedule = {

            'id'                : scheduleData.schedule.id,
            'calendarId'        : scheduleData.schedule.calendarId,
            'title'             : scheduleData.schedule.title,
            'start'             : scheduleData.schedule.start,
            'end'               : scheduleData.schedule.end,
            'isAllDay'          : scheduleData.schedule.isAllDay,
            'location'          : scheduleData.schedule.location,
            'state'             : scheduleData.schedule.state,

        };


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/calendar/schedule/update', db_schedule ,headers )
            .then(res => {

                if (res.data.success){



                }else{

                    alert('Error: Please try again!');

                }

            })

            .catch((error) => {
                localStorage.setItem('redirect',this.props.history.location.pathname);
                window.location.href = '/login';

            });


        //this.setState({schedules: currentSchedules});



        //this.refreshScheduleVisibility();

    }

    deleteSchedule(scheduleData){

        this.state.calendarInstance.deleteSchedule(scheduleData.schedule.id, scheduleData.schedule.calendarId);

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/calendar/schedule/delete', {id: scheduleData.schedule.id} ,headers )
            .then(res => {

                if (res.data.success){


                }else{

                    alert('Error: Please try again!');
                }

            })

            .catch((error) => {
                localStorage.setItem('redirect',this.props.history.location.pathname);
                window.location.href = '/login';

            });


    }


    refreshScheduleVisibility() {



        let that = this;

        let calendarElements = Array.prototype.slice.call(document.querySelectorAll('#calendarList input'));

        this.state.calendarList.forEach(function(calendar) {
            that.state.calendarInstance.toggleSchedules(calendar.id, !calendar.checked, false);
        });

        this.state.calendarInstance.render(true);

        calendarElements.forEach(function(input) {
            let span = input.nextElementSibling;
            span.style.backgroundColor = input.checked ? span.style.borderColor : 'transparent';
        });


    }


    async getSchedules() {


        //let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        await axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/calendar')
            .then(res => {


                if (res.data.schedules){




                    /*
                     * convert UTC time to browser timezone
                     */
                    for (let x = 0; x < res.data.schedules.length; x++){


                        var start_date = new Date(res.data.schedules[x].start);
                        res.data.schedules[x].start = start_date;

                        var end_date = new Date(res.data.schedules[x].end);
                        res.data.schedules[x].end = end_date;

                    }


                    this.setState({
                        schedules: res.data.schedules,
                        timezones: res.data.config.timezones
                    });


                }

                this.setState({calendar_fetched: true});

            })

            .catch((error) => {

                this.setState({calendar_fetched: true});

            });






        const calendarInstance = this.calendarRef.current.getInstance();

        this.setState({calendarInstance:calendarInstance});


        this.setRenderRangeText();
        this.calEvents();


    }


    componentDidMount(){


        this.props.setSiteData({page: 'availability-calendar'});

        if (this.props.current_user.role === 'administrator'){
            this.setState({isReadOnly: false});
        }

        this.getSchedules();

    }


    componentWillUnmount(){

        localStorage.setItem('redirect',this.props.location.pathname);

    }

    render() {




        //console.log(JSON.stringify(this.state.schedules));


        return (

            <div className="page-wrap">

                <div>

                    <Header />

                    <div className="content content-fixed">
                        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">

                            <main>
                                <div className="availability-content">

                                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                        <div>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                                    <li className="breadcrumb-item active" aria-current="page">Calendar</li>
                                                </ol>
                                            </nav>


                                            <h4 className="mg-b-10 tx-spacing--1">D6 Availability Calendar</h4>

                                        </div>
                                        <div className="d-none d-md-block">

                                        </div>
                                    </div>


                                    <div className="card">
                                        <div className="card-body">
                                            <div className="text-center">

                                                <div className="availability-text-intro pd-t-20">
                                                    <p>Client Collaboration by Coordinating our Calendars</p>

                                                    <p>Below is our team's general availability so that you can plan your projects & deadlines. We will do our best to post updates & general blocks of time we are available so you can plan your tasks and projects.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mg-t-15">
                                        <div className="card-body">
                                            <div>

                                                <div className="row">

                                                    <div className="col-lg-3">

                                                        <div className="calendar-guide">
                                                            <h3>Calendar Guide</h3>

                                                            <div className="guide cal-guide-1">
                                                                <h4>Submission Deadlines</h4>
                                                                <p className="tx-12 tx-color-03"> Based upon your plan, submissions for website support will be reviewed during designated timeframes.</p>
                                                            </div>

                                                            <div className="guide cal-guide-2">
                                                                <h4>Out of Office</h4>
                                                                <p className="tx-12 tx-color-03">D6 has a variety of additional days throughout the year that it will not be available for clients. We wil do our best to keep this calendar updated with these dates.</p>
                                                            </div>

                                                            <div className="guide cal-guide-3">
                                                                <h4>Weekend and Holiday Closures</h4>

                                                                <p className="tx-12 tx-color-03">D6 is closed during national holidays that we will try to update on this calendar.</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-9">

                                                        {

                                                            !this.state.calendar_fetched
                                                                ?
                                                                (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>)
                                                                :
                                                                (



                                                                <div className="card">
                                                                    <div className="card-body">

                                                                        <div className="availability-cal-wrap">

                                                                            <div className="availability-cal-inner">
                                                                                <div id="menu">

                                                                                    <div id="renderRange" className="render-range"></div>

                                                                                    <span id="menu-navi">

                                                                                        <button type="button" onClick={this.handleClickPrevButton}>
                                                                                          <i className="fa fa-chevron-left"></i>
                                                                                        </button>
                                                                                        <button type="button" onClick={this.handleClickTodayButton}>Today</button>
                                                                                        <button type="button" onClick={this.handleClickNextButton}>
                                                                                          <i className="fa fa-chevron-right"></i>
                                                                                        </button>
                                                                                    </span>


                                                                                </div>


                                                                                <div className="cal-wrap">
                                                                                    <Calendar
                                                                                        ref={this.calendarRef}
                                                                                        onClickDayname={this.handleClickDayname}
                                                                                        calendars={this.state.calendarList}
                                                                                        defaultView="month"
                                                                                        view="month"
                                                                                        disableDblClick={false}
                                                                                        height="90%"
                                                                                        isReadOnly={this.state.isReadOnly}
                                                                                        month={{
                                                                                            startDayOfWeek: 0
                                                                                        }}
                                                                                        schedules={
                                                                                            this.state.schedules
                                                                                        }

                                                                                        scheduleView
                                                                                        taskView
                                                                                        template={{
                                                                                            milestone(schedule) {
                                                                                                return `<span style="color:#fff;background-color: ${schedule.bgColor};">${
                                                                                                    schedule.title
                                                                                                    }</span>`;
                                                                                            },
                                                                                            milestoneTitle() {
                                                                                                return 'Milestone';
                                                                                            },
                                                                                            allday(schedule) {
                                                                                                return `${schedule.title}<i class="fa fa-refresh"></i>`;
                                                                                            },
                                                                                            alldayTitle() {
                                                                                                return 'All Day';
                                                                                            }
                                                                                        }}
                                                                                        theme={myTheme}
                                                                                        //timezones={this.state.timezones}
                                                                                        useDetailPopup
                                                                                        useCreationPopup
                                                                                        week={{
                                                                                            showTimezoneCollapseButton: true,
                                                                                            timezonesCollapsed: true
                                                                                        }}

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                                )



                                                        }

                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>


                                    <div className="cal-foot-notes mg-t-15">

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card">

                                                    <div className="card-body">
                                                        <div className="item">
                                                            <h6 className="mg-0 mg-b-20">Submission Deadlines</h6>
                                                            <p className="tx-12 tx-color-03 mg-b-0">You man submit your requests through our support form a any day or time throughout the week via our dedicated support form.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="item">
                                                            <h6 className="mg-0 mg-b-20">Out of Office</h6>
                                                            <p className="tx-12 tx-color-03 mg-b-0">D6 has a variety of additional days throughout the year that it will not be available for clients.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="item">
                                                            <h6 className="mg-0 mg-b-20">Weekend and Holiday Closures</h6>

                                                            <p className="tx-12 tx-color-03 mg-b-0">D6 is closed during weekends & generally follow national holiday closures. We will try to update on this calendar if needed.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </main>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AvailabilityPage));