import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from 'axios';
import './assets/styles/App.scss';
import "./df/lib/@fortawesome/fontawesome-free/css/all.min.css";
import "./df/lib/ionicons/css/ionicons.min.css";
import "./df/lib/jqvmap/jqvmap.min.css";

import "./df/assets/css/df.css";
import "./df/assets/css/df.dashboard.css";
import "./df/assets/css/skin.light.css";



//import { appendScript } from './components/helpers/helpers';


import { connect } from "react-redux";
import { setCurrentUser, setSiteDetails } from "./redux-js/actions/index";


import Home from './components/hourly-support/home'
import Login from './components/hourly-support/login';
import LostPassword from './components/hourly-support/lost-password';
import Logout from './components/hourly-support/logout';

//import SignUp from './components/hourly-support/signup';
//import SupportPlansPage from './components/hourly-support/support-plans';
//import HowItWorksPage from './components/hourly-support/how-it-works';
//import RequestSupportHourly from './components/hourly-support/request-support-hourly';


//import Page from './components/page';

import AvailabilityPage from './components/hourly-support/availability';

//import MobileNav from './components/mobile-nav';
import MakeAPayment from './components/hourly-support/make-a-payment';
import Card from './components/hourly-support/card';

//import ProjectApprovalForm from './components/hourly-support/project-approval-form';
//import Projects from './components/hourly-support/projects';

import Admin from './components/admin/admin';
import AdminProjectBooks from './components/admin/project-books';


//import DashForge from './components/dashforge';

import SignUp from './components/signup/signup';

import page404 from './components/404';
//import D6Flex from './components/d6-flex/main';

//PROJECT BOOK
import PortalMain from './components/project-book/main'


import MakeRequest from './components/support/make-request-public'
import singleRequestPublic from './components/support/single-request-public';

//import jQuery from 'jquery';
//jQuery = window.$j;






// Add a request interceptor
axios.interceptors.request.use((config) => {
    // Do something before request is sent
    return config;
}, (error) => {

    //console.log(error);
    // Do something with request error
    return Promise.reject(error);
});


// Add a response interceptor
axios.interceptors.response.use((response) => {

    //console.log('response',response)
    // Do something with response data
    return response;

}, (error) => {

    if (error.response.data.data.status === 403){

        //window.location.href = '/login';

    }else{

        //window.location.href = '/';
    }

    //console.log(error.response);

    // Do something with response error
    return Promise.reject(error);
});



const mapStateToProps = state => {
    return { ...state };
};





function mapDispatchToProps(dispatch) {
    return {
        setCurrentUser: currentUser => dispatch(setCurrentUser(currentUser)),
        setSiteDetails:  details => dispatch(setSiteDetails(details))
    };
}


class App extends Component {

    constructor(props){
        super(props);

        this.state = {

            current_user_fetched: false,
            site_details_fetched: false,
            current_user: {},
        }
    }


    loadScrips(){


    }

    componentWillReceiveProps(nextProps){


    }

    componentWillUpdate(nextProps, nextState){


    }

    componentDidUpdate(prevProps){



    }


    windowFocus(){

        /**
         * Stop / Run interval on window focus
         */


        window.is_focus = true;

        window.focus_interval = setInterval(function () {

            if (document.hasFocus()){
                window.is_focus = true;
            }else{
                window.is_focus = false;
            }

        },2000);

    }

    componentWillUnmount(){

        clearInterval(window.focus_interval);
    }

    componentDidMount(){

        this.windowFocus();

        //console.log(React.version);


        /***
         * Important
         */

        if ( !JSON.parse(localStorage.getItem('currentUser')) ) {

            //IMPORT to initialize token
            //otherwise API queries will return errors, token undefined
            localStorage.setItem('currentUser', JSON.stringify({token:''}));

        }


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/details')
            .then(res => {

                this.props.setSiteDetails(res.data.details);

                //this.setState({current_user: res.data.user})

                this.setState({site_details_fetched: true});

            })

            .catch((error) => {

                this.props.setSiteDetails({});
                this.setState({site_details_fetched: true});

                //console.log(error.config);
            });



        if (JSON.parse(localStorage.getItem('currentUser')).token) {

            let headers = {headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token}};

            axios.get(process.env.REACT_APP_API_ENDPOINT + '/current-user', headers)
                .then(res => {

                    this.props.setCurrentUser(res.data.user);

                    //this.setState({current_user: res.data.user})

                    this.setState({current_user_fetched: true});

                })

                .catch((error) => {

                    localStorage.setItem('currentUser', JSON.stringify({}));
                    //console.log(error.response.data);
                    this.props.setCurrentUser({});
                    this.setState({current_user_fetched: true});

                    //console.log(error.config);
                });

        }else{


            this.setState({current_user_fetched: true});

        }


        //jQuery('body').css({'min-height':jQuery(window).height() + 1});


        //jQuery(window).resize(function () {
            //jQuery('body').css({'min-height':jQuery(window).height() + 1});
            //jQuery('main').css({'height':jQuery(window).height() -70});
        //});


    }


    render() {


        if (!this.state.current_user_fetched || !this.state.site_details_fetched){
            //we will make sure we get the current user first
            return null;
        }


        this.loadScrips();

        return (
          <div className="App">

              <Router>

                      <div>


                          <Switch>
                              {/*<Route exact path="/login" component={Login}/>*/}
                              <Route exact path="/signup" component={SignUp}/>
                              {/*<Route path="" component={Home}/>*/}

                              {/*<Route exact path="/dashforge" component={DashForge}/>*/}

                              <Route exact path="/404" component={page404}/>
                              <Route exact path="/login" component={Login}/>
                              <Route exact path="/login/:login_as" component={Login}/>
                              <Route exact path="/lost-password" component={LostPassword}/>
                              <Route exact path="/logout" component={Logout}/>
                              {/*<Route exact path="/sign-up" component={SignUp}/>*/}
                              {/*<Route path="/request" component={RequestSupportHourly}/>*/}
                              {/*<Route path="/support-plans" component={SupportPlansPage}/>*/}
                              {/*<Route path="/how-it-works" component={HowItWorksPage}/>*/}
                              <Route path="/availability" component={AvailabilityPage}/>


                              {/*<Route path="/pages/:page_slug" component={Page}/>*/}


                              <Route path="/make-a-payment" component={MakeAPayment}/>
                              <Route path="/card/:client" component={Card}/>

                              {/*<Route path="/projects/:client_slug/:status" component={Projects}/>*/}
                              {/*<Route path="/projects/:client_slug" component={Projects}/>*/}
                              {/*<Route path="/projects/" component={Projects}/>*/}
                              {/*<Route path="/project/:project_scope_slug" component={ProjectApprovalForm}/>*/}


                              <Route path="/make-request" component={MakeRequest}/>
                              <Route path="/request/:request_long_id" component={singleRequestPublic}/>

                              <Route path="/portal" component={PortalMain}/>

                              <Route exact path="/admin/portals/" component={AdminProjectBooks}/>
                              <Route exact path="/admin/" component={Admin}/>


                              {/*<Route path="/d6-flex" component={D6Flex}/>*/}


                              <Route path="" component={Home}/>

                              {/*<Route path="" render={(props) => (<Home current_user={this.state.current_user} {...props}/>)} />*/}

                          </Switch>

                      </div>

              </Router>

          </div>
        );
  }
}




// We need an intermediary variable for handling the recursive nesting.
//const AppWrapped = withStyles()(App);

export default connect(mapStateToProps, mapDispatchToProps)(App);