import React, { Component } from "react";

import axios from 'axios';

class ProfileForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            client: {
                first_name:'',
                last_name: '',
                company: '',
                email: ''
            }
        };


        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);

    }



    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        var client = this.state.client;
        client[name] = value

        this.setState({client});
    }


    handleSubmit(event) {

        event.preventDefault();

        const profile = this.state.client;

        this.setState({btnEnabled: false});

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/profile/update', { profile }, headers)
            .then(res => {

                //console.log(res);
                //console.log(res.data);

                if (res.data.success){

                    this.setState({
                        msg: res.data.msg,
                        errorMsg: '',
                    });

                    this.props.profileUpdated(this.state.client);


                }else {

                    this.setState({
                        errorMsg: res.data.msg
                    });

                }

                this.setState({btnEnabled: true});

            })

            .catch((error) => {

                this.setState({btnEnabled: true});
                //console.log(error.response);
                //this.setState({errorMsg: error.response.data.message});

            });


    }


    componentDidMount(){

        this.setState({client:this.props.client});

        window.$j('body').on('focus', '.profile-form .form-control', function () {

            window.$j(this).closest('label').addClass('focus');
        });

        window.$j('body').on('blur', '.profile-form .form-control', function () {

            window.$j(this).closest('label').removeClass('focus');
        });

    }


    render() {

        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;

        return (
            <form className="profile-form" onSubmit={this.handleSubmit}>

                <div className="form-group">
                    <label>
                        <span className="label-text">First Name</span>
                        <input name="first_name" type="text" value={this.state.client.first_name} onChange={this.handleInputChange} className="form-control" />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <span className="label-text">Last Name</span>
                        <input name="last_name" type="text" value={this.state.client.last_name} onChange={this.handleInputChange} className="form-control" />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <span className="label-text">Email Address</span>
                        <input name="email" type="text" value={this.state.client.email} onChange={this.handleInputChange} className="form-control" />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <span className="label-text">Company</span>
                        <input name="company" type="text" value={this.state.client.company} onChange={this.handleInputChange} className="form-control" />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <span className="label-text">New Password</span>
                        <input name="password" type="password" value={this.state.client.password} onChange={this.handleInputChange} className="form-control" />
                    </label>
                </div>


                <div>
                    <button disabled={!this.state.btnEnabled} className="btn btn-primary" type="submit"><span>Save</span></button> &nbsp;

                    {
                        !this.state.btnEnabled &&
                        <span><i className="fa fa-spinner fa-spin"></i></span>

                    }

                    <div style={{marginTop:'20px'}}>
                        {errorMsg &&

                            <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: errorMsg}}></div>
                        }

                        {msg &&
                            <div className="alert alert-success" dangerouslySetInnerHTML={{__html: msg}}></div>
                        }
                    </div>
                </div>


            </form>
        );
    }
}





export default ProfileForm;


