import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from '../assets/images/d6-support-logo.png';
import '../assets/styles/Header.scss';
import { connect } from "react-redux";
//import MobileNav from './mobile-nav-public';
import store from "../redux-js/store/index";
import axios from "axios";

import { authHeader } from './helpers/helpers'

import jQuery from 'jquery';
jQuery = window.$j;



const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class Header extends Component {

    constructor(props){
        super(props);

        this.state = {

            current_user:{
                company: 'Guest',
                photo: process.env.REACT_APP_URL + '/images/avatar.jpg',
                role: '',
            },

            page_slug:'',
            client_slug: '',
            header:{
                logo_link: '/'
            },
            header_fetch: false,
            show_user_actions_popup: false
        }




    }

    setHeader(data){


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/header/?'+ data, authHeader())

            .then(res => {


                this.setState({
                    header: res.data.header,
                    header_fetch: true
                });


            })

            .catch((error) => {


            });

    }


    componentDidMount(){


        this.unsubscribe = store.subscribe(() => {

            let store_state = store.getState();

            if(store_state){


                if( store_state.site_data && store_state.site_data.client_slug ){

                    this.setState({client_slug: store_state.site_data.client_slug})
                    this.setHeader('client_slug='+store_state.site_data.client_slug);

                }

                else if( store_state.site_data && store_state.site_data.page_slug ){

                    this.setState({page_slug: store_state.site_data.page_slug})
                    this.setHeader('page_slug='+store_state.site_data.page_slug);

                }

                else if ( !jQuery.isEmptyObject(store_state.site_data) ){

                    this.setHeader('');

                }

            }

            //this.props.setPaymentMade(true);

        });


        if (this.props.current_user && this.props.current_user.first_name){

            this.setState({current_user: this.props.current_user});
        }


    }

    componentWillUnmount() {


        this.unsubscribe();
        //this.unlisten();

    }



    componentWillReceiveProps(nextProps) {



    }

    componentDidUpdate(){

        if (window.runHeaderScripts){
            window.runHeaderScripts();
        }
    }

    setCurrentPage(url){



        setTimeout(function () {

            if (url.indexOf('/portal/dashboard/') !== -1){

                window.$j('.navbar-menu .nav-link:not(.nav-dashboard)').removeClass('cur');
                window.$j('.navbar-menu .nav-dashboard').addClass('cur');

            }

            else if (url.indexOf('/seo-reports/report/') !== -1){


                window.$j('.navbar-menu .nav-link:not(.nav-reports)').removeClass('cur');
                window.$j('.navbar-menu .nav-reports').addClass('cur');

            }

            else if (url.indexOf('/portal/page/') !== -1){

                window.$j('.navbar-menu .nav-link:not(.nav-projects)').removeClass('cur');
                window.$j('.navbar-menu .nav-projects').addClass('cur');

            }

            else if (url.indexOf('/make-request/') !== -1 || url.indexOf('/portal/projects/') !== -1){

                window.$j('.navbar-menu .nav-link:not(.nav-support)').removeClass('cur');
                window.$j('.navbar-menu .nav-support').addClass('cur');

            }




        },1);


    }



    render() {



        this.setCurrentPage(window.location.pathname);


        return (

            <header className="navbar navbar-header navbar-header-fixed">
                <a href="#menu" id="mainMenuOpen" className="burger-menu"><i data-feather="menu"></i></a>
                <div className="navbar-brand">
                    <NavLink to={this.state.header.logo_link} className="df-logo"><img src={logo} alt=""/></NavLink>
                </div>
                <div id="navbarMenu" className="navbar-menu-wrapper">

                    <div className="navbar-menu-header">
                        <NavLink to={this.state.header.logo_link} className="df-logo"><img src={logo} alt=""/></NavLink>
                        <a id="mainMenuClose" href="#menu"><i data-feather="x"></i></a>
                    </div>

                    {
                        this.state.header_fetch &&

                        <ul className="nav navbar-menu justify-content-center">
                            <li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>


                            {this.state.header.nav_links.map((link, i) =>

                                <li className={ 'nav-item' + ( link.sub_menu.length > 0 ? ' with-sub' : '' )} key={i}>

                                    {
                                        link.sub_menu.length <= 0 &&
                                        <NavLink to={link.link} className={'nav-link ' + link.class}><i
                                            data-feather="pie-chart"></i> {link.text}</NavLink>
                                    }

                                    {
                                        link.sub_menu.length > 0 &&
                                        <a href="#subnav" className={'nav-link ' + link.class}><i data-feather="pie-chart"></i> {link.text}
                                        </a>
                                    }

                                    {
                                        link.sub_menu.length > 0 &&


                                        <ul className="navbar-menu-sub">

                                            { link.sub_menu.map((link, x) =>
                                                <li key={x} className="nav-sub-item"><NavLink to={link.link}
                                                                                           className="nav-sub-link"><i
                                                    data-feather={link.icon}></i>{link.text}</NavLink></li>
                                            )}

                                        </ul>
                                    }


                                </li>
                            )}

                        </ul>

                    }
                </div>
                <div className="navbar-right">

                    {
                        this.state.header_fetch &&

                        <div className="d-flex align-items-center">


                            <div className="mg-r-10 text-right lh-3">
                                <span className="d-block tx-semibold tx-12">{this.state.header.name}</span>
                                <span className="d-block tx-color-03 tx-12">{this.state.header.sub_name}</span>
                            </div>

                            <div className="dropdown dropdown-profile mg-0-f">

                                <a href="#adminnav" className="dropdown-link">
                                    <div className="avatar avatar-md"><img src={this.state.header.photo} className="pd-2 rounded-circle wd-50 bd-gray-100 bd bd-2 ht-50 lh-2 bd-2 " alt=""/></div>
                                </a>

                                <div className="dropdown-menu dropdown-menu-right tx-13">

                                    {this.state.header.cur_user_role !== 'User' &&
                                        <div>
                                            <div className="avatar avatar-lg mg-b-15 pd-5 rounded-circle bg-gray-100"><img src={this.state.header.cur_user_photo} className="rounded-circle " alt=""/></div>
                                            <h6 className="tx-semibold mg-b-5">{this.state.header.cur_user_name}</h6>
                                            <p className="mg-b-25 tx-12 tx-color-03">{this.state.header.cur_user_role}</p>
                                        </div>
                                    }

                                    {this.state.header.account_links.map((link,i) =>
                                        <Link key={i} to={link.link} className="dropdown-item"><i data-feather={link.icon}></i> {link.text}</Link>
                                    )}

                                </div>
                            </div>
                        </div>

                    }
                </div>

            </header>

        )

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header);