import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import logo from '../assets/images/d6-support-logo.png';
import store from "../redux-js/store/index";
import axios from "axios";
import { authHeader } from './helpers/helpers'

import { setSiteData } from "../redux-js/actions/index";
import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class Aside extends Component {

    constructor(props){
        super(props);

        this.state = {
            sidebar: {logo_link:'/', nav_links:[]},
            sidebar_fetch: false
        }

    }


    setSidebar(data){



        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/sidebar/?'+ data, authHeader())

            .then(res => {

                this.setState({
                    sidebar: res.data.sidebar,
                    sidebar_fetch: true
                });

            })

            .catch((error) => {


            });

    }

    componentDidMount() {



        jQuery('body').addClass('with-aside');


        this.unsubscribe = store.subscribe(() => {

            let store_state = store.getState();

            if(store_state){


                if(store_state.site_data && store_state.site_data.client_slug){

                    this.setState({client_slug: store_state.site_data.client_slug})
                    this.setSidebar('client_slug='+store_state.site_data.client_slug);

                }

                else if(store_state.site_data && store_state.site_data.page_slug){

                    this.setState({page_slug: store_state.site_data.page_slug})
                    this.setSidebar('page_slug='+store_state.site_data.page_slug);

                }

                else if ( !jQuery.isEmptyObject(store_state.site_data) ){

                    this.setSidebar('');

                }

            }

            //this.props.setPaymentMade(true);

        });




    }

    componentWillUnmount(){
        jQuery('body').removeClass('with-aside');
    }

  render() {

      if (window.runAsideScripts){
          setTimeout(function () {
              window.runAsideScripts();
          },10)
      }


    return (


        <aside className="aside aside-fixed">
            <div className="aside-header">
                <NavLink to={this.state.sidebar.logo_link}  className="aside-logo">
                    <img src={logo} alt=""/>
                </NavLink>
                <a href="#menu" className="aside-menu-link">
                    <i data-feather="menu"></i>
                    <i data-feather="x"></i>
                </a>
            </div>
            <div className="aside-body">

                <ul className="nav nav-aside">

                    {this.state.sidebar.nav_links.map((link, i) =>

                        (
                           <li className={(link.link?'nav-item':'nav-label') + (i>0 && !link.link?' mg-t-25':'') + (link.sub_menu && link.sub_menu.length > 0?' with-sub':'')} key={i}>


                               {
                                   link.sub_menu && link.sub_menu.length > 0&&
                                   <a href="#subnav" className="nav-link"><i data-feather={link.icon}></i> <span>{link.text}</span></a>
                               }

                               {
                                   link.sub_menu && link.sub_menu.length <= 0 && link.link &&
                                   <NavLink exact={true} to={link.link} className="nav-link"><i data-feather={link.icon}></i> <span>{link.text}</span></NavLink>
                               }

                               {
                                   !link.link &&
                                   <span>{link.text}</span>
                               }

                               {
                                   link.sub_menu && link.sub_menu.length > 0 &&

                                   <ul>
                                       {link.sub_menu.map((sub, x) =>
                                        <li key={x}><NavLink to={sub.link}>{sub.text}</NavLink></li>
                                       )}
                                   </ul>
                               }

                           </li>
                        )

                    )}


                </ul>
            </div>
        </aside>




    );
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Aside));
