import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
import { connect } from "react-redux";


import '../../assets/styles/Request.scss';
import Header from '../header';


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class AdminProjectBooks extends Component {

    constructor(props){
        super(props);

        this.state = {
            project_books:[],
            project_books_fetched: false
        }
    }

    componentWillUnmount(){

    }



    componentDidMount(){

        if (this.props.current_user.role !== 'administrator'){
            this.props.history.push('/login');

            return;
        }

        this.getProjectBooks();


        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});


    }


    getProjectBooks(){



        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/project-books/all', headers)
            .then(res => {

                if (res.data.success) {

                    this.setState({
                        project_books: res.data.project_books
                    });

                } else {

                    //redirect here

                }

                this.setState({project_books_fetched: true});
            })


            .catch((error) => {

                this.props.history.push('/login');

                //console.log(error.response.data);
                //this.setState({project_books_fetched: true});

            });

    }


    render() {

        return (
            <div className="page-wrap">

                <div>

                    <Header />

                    <main>
                        <div className="container-fluid">
                            <div className="wrap">
                                <div className="page-content availability-content">

                                    <h2>Project Books</h2>


                                    {

                                        !this.state.project_books_fetched
                                            ?
                                            (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>)
                                            :
                                            ('')
                                    }


                                    {

                                        (this.state.project_books.length)

                                            ?(
                                            <div className="requests-list">

                                                <div className="request-item header-item">
                                                    <div className="row">
                                                        <div className="col-md-10 request-title">Title</div>
                                                        <div className="col-md-2 request-date">Date</div>
                                                    </div>
                                                </div>

                                                {this.state.project_books.map((project_book,i) =>

                                                    <div className="request-item item-row" key={i}>
                                                        <div className="row">
                                                            <div className="col-md-10 request-title"><Link to={'/project-book/'+project_book.slug}><span>{project_book.title}</span></Link></div>
                                                            <div className="col-md-2 request-date">{project_book.date}</div>
                                                        </div>
                                                    </div>

                                                )}
                                            </div>
                                        )

                                            : ('')

                                    }


                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        );


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminProjectBooks));