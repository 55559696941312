import React, { Component } from "react";
import axios from 'axios';
import  CommentForm from  './comment-form-v2';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment';
import { convertServerDateToLocal } from '../../helpers/helpers'

import jQuery from 'jquery';
jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class ConnectedComments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            comments_fetched: false,
            comments: [],
            photo: process.env.REACT_APP_URL + '/images/avatar.jpg',
        };


        this.comment_added       = this.comment_added.bind(this);

    }


    componentWillUnmount(){


    }

    componentDidMount(){


        if (this.props.request.client && this.props.request.client.photo){
            this.setState({photo:this.props.request.client.photo});
        }

        //console.log('work_request_slug',this.props.work_request_slug);


        //let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };
        let headers = { };


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/request-comments/' + this.props.request_long_id + '/', headers )
            .then(res => {


                let comments = res.data.comments;

                if( comments ) {

                    this.setState({
                        comments: comments,
                    });
                }

                this.setState({
                    comments_fetched: true,
                });


                if (window.location.hash && jQuery(window.location.hash).length){

                    jQuery('html, body, main').scrollTop(jQuery(window.location.hash).offset().top)
                }

            })

            .catch((error) => {

                this.setState({
                    request_fetched: true,
                })
            });

    }


    comment_added(comment){

        var comments = this.state.comments;

        comments.push(comment);

        this.setState({comments: comments});
    }


    getDate(date){

        //var d = new Date(date + ' UTC');
        var d = convertServerDateToLocal(date);

        //var d = date;

        return moment(d).fromNow(true) + ' ago';

    }


    render() {

        if (!this.state.comments_fetched){

            return <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
        }


        return (
            <div className="work-request-comments">

                {this.state.comments.map((comment, i) =>
                    <div className="card mg-t-10"  key={i}>
                        <div className="card-body">
                            <div className="comment-item" id={'comment-'+comment.id}>


                                    {
                                        comment.user.photo !== '' &&
                                        <div className="photo">
                                            <img src={comment.user.photo} alt=""/>
                                        </div>
                                    }

                                    {
                                        comment.user.photo === '' &&
                                        <div className="photo no-photo">

                                        </div>
                                    }



                                <div className="comment-content">
                                    <div className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03 mg-b-10">{comment.user.first_name +' '+ comment.user.last_name} <span className="sep">|</span> <span title={comment.date}>{this.getDate(comment.date_converted)}</span></div>

                                    <div className="hs-editor-content hs-editor-comment-content">
                                        <div dangerouslySetInnerHTML={{__html: comment.comment}}></div>

                                        {
                                            (comment.attachments.length)

                                                ? (
                                                <div className="request-attachments">

                                                    <strong><span className="fa fa-paperclip"></span> Attachments</strong>

                                                    {comment.attachments.map((attachment, i) =>
                                                        <div className="attachment-item item-row" key={i}>
                                                            <a rel="noopener noreferrer" target="_blank"
                                                               href={attachment.url}>{attachment.file_name}</a>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                                : ('')
                                        }

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {
                    ((this.state.comments_fetched ))//&& this.props.current_user.wp_user_id
                        ?
                        (
                            <div className="card mg-t-10">
                                <div className="card-header">
                                    <h6 className="mg-0">Post a Comment</h6>
                                </div>
                                <div className="card-body">
                                    <div className="comment-item comment-item-form">

                                        <div className="photo">
                                            <img src={this.props.current_user.photo ? this.props.current_user.photo : this.state.photo} alt=""/>
                                        </div>

                                        <div className="comment-content">
                                            <div className="request-form-wrap">
                                                <CommentForm comment_added={this.comment_added} request_long_id={this.props.request_long_id}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (

                            <div className="comment-item"><Link to={'/login'}>Sign In</Link> to post comment</div>
                        )
                }


            </div>
        );
    }
}


const Comments = connect(mapStateToProps, mapDispatchToProps)(ConnectedComments);


export default Comments;


