import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";

import '../../assets/styles/Home.scss';
import '../../assets/styles/Request.scss';


import { connect } from "react-redux";


import Header from '../header-admin'
import Client from '../account/client';


//import jQuery from 'jquery';
//jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class Home extends Component {

    constructor(props){
        super(props);

        this.state = {

        }
    }

    componentDidMount(){


        if (this.props.location.pathname === '/'){

            //this.props.history.push('/request');
            this.props.history.push('/admin');

        }

        //throw new Error('I crashed!');

        /***
         * Important
         */
        //jQuery('main').css({'height':jQuery(window).height() -70});
        //jQuery('main .wrap').css({'min-height':jQuery(window).height() -69});

    }


    componentWillReceiveProps(nextProps) {

        //this.setState({current_user: nextProps.current_user});
    }


  render() {


    return (


        <div>
            <div className="page-wrap">

                <Route path={`/:client`} component={Client}/>

                {/*<Route path={`/:client`} render={(props) => (<Client current_user={this.state.current_user} {...props}/>)} />*/}

                <Route
                    exact
                    path={this.props.match.path}
                    render={() =>

                        <div>

                            <Header />

                            <main>
                                <div className="container-fluid">
                                    <div className="wrap">
                                        <div className="page-content">

                                            <h2>Home</h2>

                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>

                    }
                />

            </div>
        </div>



    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));