import React, { Component } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import RegularMakeRequestForm from './partials/make-request-form'
import InteractiveMakeRequestForm from './partials/make-request-interactive-form'
import { setSiteData } from "../../redux-js/actions/index";

import '../../assets/styles/Editor.scss';
import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class MakeRequest extends Component {


    constructor(props){
        super(props);

        this.state = {
        }

    }

    componentDidMount(){



        jQuery('body').addClass('make-request-page');


        if (!this.props.match.params.client_slug){

            if (this.props.current_user.role === 'client'){


                window.location.href = '/portal/projects/' + this.props.current_user.slug + '/';
                //this.props.history.push('/portal/projects/' + this.props.current_user.slug + '/' );

            }else{

                //window.location.href = '/';
                //this.props.history.push('/' );
            }
        }

        else {

            //if (this.props.current_user.role !== 'client'){
                //this.props.history.push('/make-request/' );
            //}
        }


        this.props.setSiteData({client_slug: this.props.match.params.client_slug});


    }

    componentWillUnmount(){
        localStorage.setItem('redirect',this.props.location.pathname);

        jQuery('body').removeClass('make-request-page');
    }

    render() {


        return (

            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <main>
                        <div style={{'maxWidth':'1000px','margin':'0 auto'}}>

                            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                <div>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">


                                            {
                                                this.props.match.params.client_slug &&
                                                <li className="breadcrumb-item"><Link to={'/portal/projects/'+this.props.match.params.client_slug+'/'}>Work Requests</Link></li>
                                            }

                                            <li className="breadcrumb-item active" aria-current="page">Make a Request</li>
                                        </ol>
                                    </nav>
                                    <h4 className="mg-b-10 tx-spacing--1">Make a Request</h4>

                                </div>
                                <div className="d-none d-md-block">
                                    {
                                        this.props.match.params.client_slug &&

                                        <Link className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" to={'/portal/projects/'+this.props.match.params.client_slug+'/'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file wd-10 mg-r-5"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                            View All Requests
                                        </Link>
                                    }
                                </div>
                            </div>

                            <div className="card">

                                <div className="card-body pd-y-30">
                                    <div className="row">
                                        <div className="col-lg-12">

                                            <div className="make-request-form-wrap">

                                                <div className="pd-t-0">
                                                    <p className="tx-13 tx-color-02 mg-b-0 "  style={{maxWidth:'400px'}}>Use this page to make a request to D6. We will review & get back to you with a scope/turnaround. </p>
                                                </div>


                                                {/*<div className="request-form-type">*/}
                                                    {/*<div className="row">*/}
                                                        {/*<div className="col-md-6">*/}
                                                            {/*<NavLink exact activeClassName='active' to={'/portal/projects/'+this.props.match.params.client_slug+'/make-request/'}><span>Start a Request via Message</span></NavLink>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="col-md-6">*/}
                                                            {/*<NavLink exact activeClassName='active' to={'/portal/projects/'+this.props.match.params.client_slug+'/make-request/interactive-form'}><span>Make a Request Using our Interactive Form</span></NavLink>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}

                                                {/*</div>*/}




                                                <Route exact path={`${this.props.match.path}/`} component={RegularMakeRequestForm}/>
                                                <Route exact path={`${this.props.match.path}/interactive-form`} component={InteractiveMakeRequestForm}/>

                                                {/*<RequestForm client_slug={this.props.match.params.client} />*/}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}
 

export default connect(mapStateToProps, mapDispatchToProps)(MakeRequest);