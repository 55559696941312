import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setSiteData } from "../../redux-js/actions/index";
import { appendScript, appendCSS } from '../../components/helpers/helpers';

import jQuery from 'jquery';
jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class SingleSpreadsheet extends Component {

    constructor(props) {
        super(props);

        this.state = {

            errorMsg: '',
            msg: '',
            btnEnabled: true,
            sheets: [],
            title: '',

            spreadsheet_fetched: false,
            current_user: {},

        };

    }


    getSpreadsheet(spreadsheet_slug){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/spreadsheet/' + spreadsheet_slug + '/' )
            .then(res => {




                this.setState({
                    spreadsheet_fetched: true,
                    sheets: res.data.sheets,
                    title: res.data.title
                });


                setTimeout(function () {

                    window.dataTableIns = [];

                    res.data.sheets.forEach(function (v, k) {


                        var table_options = {
                            //paging: false,
                            pageLength: 50,
                            responsive: {

                            }
                            //scrollY:        200,
                            //deferRender:    true,
                            //scroller:       true
                        };

                        table_options = Object.assign(v.table_options, table_options);


                        window.dataTableIns[k] = jQuery('#table'+k).DataTable(table_options);



                    });


                    jQuery('select.datatable-sheets-select').on('change', function () {
                        jQuery('.datatable-sheet').removeClass('show');
                        jQuery(jQuery(this).val()).addClass('show');

                        jQuery('.d6-data-tables').DataTable().draw('page');

                    });

                    jQuery('.d6-data-tables-wrap').addClass('pakita');


                },1);





            })

            .catch((error) => {

                this.setState({
                    spreadsheet_fetched: true,
                })
            });




    }



    componentDidMount(){



        if (!window.dataTable){
            window.dataTable = true;
            appendScript(process.env.REACT_APP_URL + '/data-tables/datatables.min.js');
            appendScript(process.env.REACT_APP_URL + '/data-tables/responsive/js/dataTables.responsive.min.js');
            //appendScript(process.env.REACT_APP_URL + '/data-tables/scroller/js/dataTables.scroller.min.js');

            appendCSS(process.env.REACT_APP_URL + '/data-tables/datatables.min.css');
            appendCSS(process.env.REACT_APP_URL + '/data-tables/responsive/css/responsive.dataTables.min.css');
            //appendCSS(process.env.REACT_APP_URL + '/data-tables/scroller/css/scroller.dataTables.min.css');
        }

        //this.props.setSiteData({client_slug: this.props.current_user.slug, page: 'project-scope'});

        this.getSpreadsheet(this.props.match.params.spreadsheet_slug);

        this.props.setSiteData({client_slug: this.props.match.params.client_slug});

    }


    componentWillReceiveProps(nextProps){

        if (window.dataTableIns.length){
            window.dataTableIns.forEach(function(v,k){
                v.destroy();
            })
        }

        this.setState({spreadsheet_fetched:false});
        this.getSpreadsheet(nextProps.match.params.spreadsheet_slug);


    }

    componentWillUnmount(){


    }


    render() {



        return (
            <div className="content content-fixed">


                <div style={{'maxWidth':'100%','margin':'0 auto'}}>

                    <div className="data-table-content">

                        {
                            !this.state.spreadsheet_fetched &&
                                <div>
                                    <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                                </div>
                        }

                        {
                            this.state.spreadsheet_fetched &&

                            (
                                <div>
                                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                        <div>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">



                                                    <li className="breadcrumb-item active" aria-current="page">Spreadsheet</li>
                                                </ol>
                                            </nav>


                                            <h4 className="mg-b-10 tx-spacing--1">{this.state.title}</h4>

                                        </div>
                                        <div className="d-md-block">

                                            {
                                                this.state.spreadsheet_fetched &&

                                                <div className="datatable-sheets-select-wrap">
                                                    <select className="form-control datatable-sheets-select">
                                                        {
                                                            this.state.sheets.map((sheet, i)=>
                                                                <option key={i} value={'#sheet'+i}>{sheet.title}</option>
                                                            )

                                                        }
                                                    </select>
                                                </div>

                                            }

                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="data-table-cards">
                                            {
                                                this.state.sheets.map((sheet, i)=>

                                                <div className={'datatable-sheet card ' + (!i?'show':'')} id={'sheet'+i} key={i}>
                                                    <div className="card-header">
                                                        <h6 className="mg-0">{sheet.title}</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div dangerouslySetInnerHTML={{__html: sheet.table}}></div>
                                                    </div>
                                                </div>
                                                )

                                            }

                                        </div>
                                    </div>
                                </div>
                            )

                        }


                    </div>
                </div>
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleSpreadsheet);