import React, { Component } from "react";
import { withRouter } from "react-router-dom";//Route,
import { connect } from "react-redux";
import { appendScript } from '../../components/helpers/helpers';
import axios from 'axios';

import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};

function mapDispatchToProps(dispatch) {
    return {

    };
}


class ChartTemplate extends Component {

    constructor(props){
        super(props);

        this.state = {

            current_page: {},
            data_fetched: false,
            chart_data: {},
        }
    }



    templateData(current_page){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/template/data/?template=chart-template&page_id=' + current_page.id, {})
            .then(res => {


                this.setState({
                    chart_data: res.data.chart_data,
                    data_fetched: true,
                });


            })

            .catch((error) => {


            });


    }


    setPage(current_page){

        this.setState({current_page: current_page});

        this.templateData(current_page);
    }


    componentDidMount(){


        jQuery('body').addClass('project-book-custom-page');


        this.setPage(this.props.current_page);


        if(!window.Chart){
            appendScript( '/df/lib/chart.js/Chart.bundle.min.js');
        }


        if (!jQuery.flot) {

            appendScript('/df/assets/js/df.sampledata.js');
            appendScript('/df/lib/jquery.flot/jquery.flot.js');
            appendScript('/df/lib/jquery.flot/jquery.flot.stack.js');
            appendScript('/df/lib/jquery.flot/jquery.flot.resize.js');
        }

    }

    componentWillReceiveProps(nextProps) {

        this.setPage(nextProps.current_page);
    }


    componentWillUnmount() {

        jQuery('body').removeClass('project-book-custom-page');
    }



    renderCharts(){



        let ds = this;


        if (!window.Chart || !jQuery.plot){

            setTimeout(function () {
                ds.renderCharts();
            },500);

            return ;
        }



        setTimeout(function () {

            var ctx1 = document.getElementById('chartBar1').getContext('2d');

            new window.Chart(ctx1, {

                type: 'bar',
                data: ds.state.chart_data.chart1,
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                        labels: {
                            display: false
                        }
                    },
                    scales: {
                        xAxes: [{
                            display: false,
                            barPercentage: 0.5
                        }],
                        yAxes: [{
                            gridLines: {
                                color: '#ebeef3'
                            },
                            ticks: {
                                fontColor: '#8392a5',
                                fontSize: 10,
                                min: 80,
                                max: 200
                            }
                        }]
                    }
                }
            });


            /**
             *
             * PIE CHART
             */

            var datapie = ds.state.chart_data.pie_chart;

            var optionpie = {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display: false,
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                }
            };

            // For a pie chart
            var ctx2 = document.getElementById('chartDonut');
            new window.Chart(ctx2, {
                type: 'doughnut',
                data: datapie,
                options: optionpie
            })


        }, 100);

        setTimeout(function () {


            jQuery.plot('#flotChart1', [{
                data: ds.state.chart_data.acquisitions[0],
                color: '#c0ccda',
                lines: {
                    fill: true,
                    fillColor: '#f5f6fa'
                }
            },{
                data: ds.state.chart_data.acquisitions[1],
                color: '#0168fa',
                lines: {
                    fill: true,
                    fillColor: '#d1e6fa'
                }
            }], {
                series: {
                    shadowSize: 0,
                    lines: {
                        show: true,
                        lineWidth: 1.5
                    }
                },
                grid: {
                    borderWidth: 0,
                    labelMargin: 0
                },
                yaxis: {
                    show: false,
                    max: 65
                },
                xaxis: {
                    show: false,
                    min: 40,
                    max: 100
                }
            });


            jQuery.plot('#flotChart2', [{
                data: ds.state.chart_data.device_sessions[1],
                color: '#66a4fb',
                lines: {
                    show: true,
                    lineWidth: 1.5,
                    fill: .03
                }
            },{
                data: ds.state.chart_data.device_sessions[0],
                color: '#00cccc',
                lines: {
                    show: true,
                    lineWidth: 1.5,
                    fill: true,
                    fillColor: '#fff'
                }
            },{
                data: ds.state.chart_data.device_sessions[2],
                color: '#e3e7ed',
                bars: {
                    show: true,
                    lineWidth: 0,
                    barWidth: .5,
                    fill: 1
                }
            }], {
                series: {
                    shadowSize: 0
                },
                grid: {
                    aboveData: true,
                    color: '#e5e9f2',
                    borderWidth: {
                        top: 0,
                        right: 1,
                        bottom: 1,
                        left: 1
                    },
                    labelMargin: 0
                },
                yaxis: {
                    show: false,
                    min: 0,
                    max: 100
                },
                xaxis: {
                    show: true,
                    min: 40,
                    max: 80,
                    ticks: 6,
                    tickColor: 'rgba(0,0,0,0.04)'
                }
            });



            var df3data1 = [[0,12],[1,10],[2,7],[3,11],[4,15],[5,20],[6,22],[7,19],[8,18],[9,20],[10,17],[11,19],[12,18],[13,14],[14,9]];
            var df3data2 = [[0,0],[1,0],[2,0],[3,2],[4,5],[5,2],[6,12],[7,15],[8,10],[9,8],[10,10],[11,7],[12,2],[13,4],[14,0]];
            var df3data3 = [[0,2],[1,1],[2,2],[3,4],[4,2],[5,1],[6,0],[7,0],[8,5],[9,2],[10,8],[11,6],[12,9],[13,2],[14,0]];
            var df3data4 = [[0,0],[1,5],[2,2],[3,0],[4,2],[5,7],[6,10],[7,12],[8,8],[9,6],[10,4],[11,2],[12,0],[13,0],[14,0]];

            var flotChartOption1 = {
                series: {
                    shadowSize: 0,
                    bars: {
                        show: true,
                        lineWidth: 0,
                        barWidth: .5,
                        fill: 1
                    }
                },
                grid: {
                    aboveData: true,
                    color: '#e5e9f2',
                    borderWidth: 0,
                    labelMargin: 0
                },
                yaxis: {
                    show: false,
                    min: 0,
                    max: 25
                },
                xaxis: {
                    show: false
                }
            };

            jQuery.plot('#flotChart3', [{
                data: df3data1,
                color: '#e5e9f2'
            },{
                data: df3data2,
                color: '#66a4fb'
            }], flotChartOption1);


            jQuery.plot('#flotChart4', [{
                data: df3data1,
                color: '#e5e9f2'
            },{
                data: df3data3,
                color: '#7ee5e5'
            }], flotChartOption1);

            jQuery.plot('#flotChart5', [{
                data: df3data1,
                color: '#e5e9f2'
            },{
                data: df3data4,
                color: '#f77eb9'
            }], flotChartOption1);



        }, 100)
    }

    render() {


        return (

            <div className="custom-page-wrap">


                {
                    !this.state.data_fetched &&
                    <div>
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    </div>
                }


                {
                    this.state.data_fetched &&


                    <div className="row row-xs">
                        <div className="col-lg-8 col-xl-9">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <h6 className="mg-b-5">Website Audience Metrics</h6>
                                        <p className="tx-12 tx-color-03 mg-b-0">Audience to which the users belonged
                                            while on the current date range.</p>
                                    </div>

                                </div>
                                <div className="card-body pd-lg-25">
                                    <div className="row align-items-sm-end">
                                        <div className="col-lg-7 col-xl-8">
                                            <div className="chart-six">
                                                <canvas id="chartBar1" className="chartjs-render-monitor"></canvas>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-4 mg-t-30 mg-lg-t-0">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-12">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between mg-b-5">
                                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
                                                            New Users</h6>
                                                        <span className="tx-10 tx-color-04">65% goal reached</span>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-end justify-content-between mg-b-5">
                                                        <h5 className="tx-normal tx-rubik lh-2 mg-b-0">13,596</h5>
                                                        <h6 className="tx-normal tx-rubik tx-color-03 lh-2 mg-b-0">
                                                            20,000</h6>
                                                    </div>
                                                    <div className="progress ht-4 mg-b-0 op-5">
                                                        <div className="progress-bar bg-teal wd-65p" role="progressbar"
                                                             aria-valuenow="65" aria-valuemin="0"
                                                             aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-12 mg-t-30 mg-sm-t-0 mg-lg-t-30">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between mg-b-5">
                                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
                                                            Page Views</h6>
                                                        <span className="tx-10 tx-color-04">45% goal reached</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between mg-b-5">
                                                        <h5 className="tx-normal tx-rubik mg-b-0">83,123</h5>
                                                        <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                            <small>250,000</small>
                                                        </h5>
                                                    </div>
                                                    <div className="progress ht-4 mg-b-0 op-5">
                                                        <div className="progress-bar bg-orange wd-45p"
                                                             role="progressbar" aria-valuenow="45" aria-valuemin="0"
                                                             aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-12 mg-t-30">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between mg-b-5">
                                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
                                                            Page Sessions</h6>
                                                        <span className="tx-10 tx-color-04">20% goal reached</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between mg-b-5">
                                                        <h5 className="tx-normal tx-rubik mg-b-0">16,869</h5>
                                                        <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                            <small>85,000</small>
                                                        </h5>
                                                    </div>
                                                    <div className="progress ht-4 mg-b-0 op-5">
                                                        <div className="progress-bar bg-pink wd-20p" role="progressbar"
                                                             aria-valuenow="20" aria-valuemin="0"
                                                             aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-12 mg-t-30">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between mg-b-5">
                                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">
                                                            Bounce Rate</h6>
                                                        <span className="tx-10 tx-color-04">85% goal reached</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between mg-b-5">
                                                        <h5 className="tx-normal tx-rubik mg-b-0">28.50%</h5>
                                                        <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                            <small>30.50%</small>
                                                        </h5>
                                                    </div>
                                                    <div className="progress ht-4 mg-b-0 op-5">
                                                        <div className="progress-bar bg-primary wd-85p"
                                                             role="progressbar" aria-valuenow="85" aria-valuemin="0"
                                                             aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mg-b-0">Sessions By Channel</h6>
                                </div>
                                <div className="card-body pd-lg-25">
                                    <div className="chart-seven">
                                        <canvas id="chartDonut" width="452" height="376"
                                                className="chartjs-render-monitor"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer pd-20">
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5">
                                                Organic Search</p>
                                            <div className="d-flex align-items-center">
                                                <div className="wd-10 ht-10 rounded-circle bg-pink mg-r-5"></div>
                                                <h6 className="tx-normal tx-rubik mg-b-0">1,320
                                                    <small className="tx-color-04">25%</small>
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">
                                                Email</p>
                                            <div className="d-flex align-items-center">
                                                <div className="wd-10 ht-10 rounded-circle bg-primary mg-r-5"></div>
                                                <h6 className="tx-normal tx-rubik mg-b-0">987
                                                    <small className="tx-color-04">20%</small>
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-6 mg-t-20">
                                            <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">
                                                Referrral</p>
                                            <div className="d-flex align-items-center">
                                                <div className="wd-10 ht-10 rounded-circle bg-teal mg-r-5"></div>
                                                <h6 className="tx-normal tx-rubik mg-b-0">2,010
                                                    <small className="tx-color-04">30%</small>
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-6 mg-t-20">
                                            <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">
                                                Social Media</p>
                                            <div className="d-flex align-items-center">
                                                <div className="wd-10 ht-10 rounded-circle bg-orange mg-r-5"></div>
                                                <h6 className="tx-normal tx-rubik mg-b-0">1,054
                                                    <small className="tx-color-04">25%</small>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mg-t-10">
                            <div className="card">
                                <div className="card-header pd-b-0 bd-b-0 pd-t-20 pd-lg-t-25 pd-l-20 pd-lg-l-25">
                                    <h6 className="mg-b-5">Acquisition</h6>
                                    <p className="tx-12 tx-color-03 mg-b-0">Tells you where your visitors originated
                                        from, such as search engines, social networks or website referrals. <a href="#link">Learn
                                            more</a></p>
                                </div>
                                <div className="card-body pd-sm-20 pd-lg-25">
                                    <div className="row row-sm">
                                        <div className="col-sm-5 col-md-12 col-lg-6 col-xl-5">
                                            <div className="media">
                                                <div
                                                    className="wd-40 ht-40 rounded bd bd-2 bd-primary d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-bar-chart-2 wd-20 ht-20 tx-primary stroke-wd-3">
                                                        <line x1="18" y1="20" x2="18" y2="10"></line>
                                                        <line x1="12" y1="20" x2="12" y2="4"></line>
                                                        <line x1="6" y1="20" x2="6" y2="14"></line>
                                                    </svg>
                                                </div>
                                                <div className="media-body mg-l-10">
                                                    <h4 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-5">
                                                        33.50%</h4>
                                                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">
                                                        Bounce Rate</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-sm-5 col-md-12 col-lg-6 col-xl-5 mg-t-20 mg-sm-t-0 mg-md-t-20 mg-lg-t-0">
                                            <div className="media">
                                                <div
                                                    className="wd-40 ht-40 rounded bd bd-2 bd-gray-500 d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-bar-chart-2 wd-20 ht-20 tx-gray-500 stroke-wd-3">
                                                        <line x1="18" y1="20" x2="18" y2="10"></line>
                                                        <line x1="12" y1="20" x2="12" y2="4"></line>
                                                        <line x1="6" y1="20" x2="6" y2="14"></line>
                                                    </svg>
                                                </div>
                                                <div className="media-body mg-l-10">
                                                    <h4 className="tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-5">
                                                        9,065</h4>
                                                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-0">
                                                        Page Sessions</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="chart-eight">
                                        <div id="flotChart1" className="flot-chart"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-8 col-lg-4 col-xl mg-t-10">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mg-b-0">Device Sessions</h6>
                                </div>
                                <div className="card-body">
                                    <div className="row row-xs">
                                        <div className="col-4 col-lg">
                                            <div className="d-flex align-items-baseline">
                                                <span className="d-block wd-8 ht-8 rounded-circle bg-primary"></span>
                                                <span
                                                    className="d-block tx-10 tx-uppercase tx-medium tx-spacing-1 tx-color-03 mg-l-7">Mobile</span>
                                            </div>
                                            <h4 className="tx-normal tx-rubik tx-spacing--1 mg-l-15 mg-b-0">6,098</h4>
                                        </div>
                                        <div className="col-4 col-lg">
                                            <div className="d-flex align-items-baseline">
                                                <span className="d-block wd-8 ht-8 rounded-circle bg-teal"></span>
                                                <span
                                                    className="d-block tx-10 tx-uppercase tx-medium tx-spacing-1 tx-color-03 mg-l-7">Desktop</span>
                                            </div>
                                            <h4 className="tx-normal tx-rubik tx-spacing--1 mg-l-15 mg-b-0">3,902</h4>
                                        </div>
                                        <div className="col-4 col-lg">
                                            <div className="d-flex align-items-baseline">
                                                <span className="d-block wd-8 ht-8 rounded-circle bg-gray-300"></span>
                                                <span
                                                    className="d-block tx-10 tx-uppercase tx-medium tx-spacing-1 tx-color-03 mg-l-7">Other</span>
                                            </div>
                                            <h4 className="tx-normal tx-rubik tx-spacing--1 mg-l-15 mg-b-0">1,065</h4>
                                        </div>
                                    </div>

                                    <div className="chart-nine">
                                        <div id="flotChart2" className="flot-chart"
                                             style={{padding: '0px', position: 'relative'}}>
                                            <canvas className="flot-base" width="682" height="284" style={{
                                                direction: 'ltr',
                                                position: 'absolute',
                                                left: '0px',
                                                top: '0px',
                                                width: '341.344px',
                                                height: '142px'
                                            }}></canvas>
                                            <div className="flot-text" style={{
                                                position: 'absolute',
                                                top: '0px',
                                                left: '0px',
                                                bottom: '0px',
                                                right: '0px',
                                                fontSize: 'smaller',
                                                color: 'rgb(84, 84, 84)'
                                            }}>
                                                <div className="flot-x-axis flot-x1-axis xAxis x1Axis" style={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    left: '0px',
                                                    bottom: '0px',
                                                    right: '0px'
                                                }}>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '4px',
                                                        textAlign: 'center'
                                                    }}>40
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '45px',
                                                        textAlign: 'center'
                                                    }}>45
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '86px',
                                                        textAlign: 'center'
                                                    }}>50
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '127px',
                                                        textAlign: 'center'
                                                    }}>55
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '167px',
                                                        textAlign: 'center'
                                                    }}>60
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '208px',
                                                        textAlign: 'center'
                                                    }}>65
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '249px',
                                                        textAlign: 'center'
                                                    }}>70
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '290px',
                                                        textAlign: 'center'
                                                    }}>75
                                                    </div>
                                                    <div className="flot-tick-label tickLabel" style={{
                                                        position: 'absolute',
                                                        'maxwidth': '37px',
                                                        top: '128px',
                                                        left: '330px',
                                                        textAlign: 'center'
                                                    }}>80
                                                    </div>
                                                </div>
                                            </div>
                                            <canvas className="flot-overlay" width="682" height="284" style={{
                                                direction: 'ltr',
                                                position: 'absolute',
                                                left: '0px',
                                                top: '0px',
                                                width: '341.344px',
                                                height: '142px'
                                            }}></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 mg-t-10">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mg-b-0">Top Traffic Source</h6>
                                </div>
                                <div className="card-body tx-center">
                                    <h4 className="tx-normal tx-rubik tx-40 tx-spacing--1 mg-b-0">29,931</h4>
                                    <p className="tx-12 tx-uppercase tx-semibold tx-spacing-1 tx-color-02">Organic
                                        Search</p>
                                    <p className="tx-12 tx-color-03 mg-b-0">Measures your user's sources that generate
                                        traffic metrics to your website for this month.</p>
                                </div>
                                <div className="card-footer bd-t-0 pd-t-0">
                                    <button
                                        className="btn btn-sm btn-block btn-outline-primary btn-uppercase tx-spacing-1">
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mg-t-10">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-7">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">3,605</h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-primary mg-b-5">
                                                Click Through</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">No. of clicks to ad that consist of
                                                a single impression.</p>
                                        </div>
                                        <div className="col-5">
                                            <div className="chart-ten">
                                                <div id="flotChart3" className="flot-chart"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mg-t-10">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-7">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">3,266</h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-teal mg-b-5">
                                                View Through</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">Estimated daily unique views per
                                                visitor on the ads.</p>
                                        </div>
                                        <div className="col-5">
                                            <div className="chart-ten">
                                                <div id="flotChart4" className="flot-chart"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mg-t-10">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-7">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">8,765</h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-pink mg-b-5">
                                                Total Conversions</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">Estimated total conversions on ads
                                                per impressions to ads.</p>
                                        </div>
                                        <div className="col-5">
                                            <div className="chart-ten">
                                                <div id="flotChart5" className="flot-chart"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mg-t-10">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="lh-5 mg-b-0">Total Visits</h6>
                                </div>
                                <div className="card-body pd-y-15 pd-x-10">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                                            <thead>
                                            <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                                <th className="wd-5p">Link</th>
                                                <th>Page Title</th>
                                                <th className="text-right">Percentage (%)</th>
                                                <th className="text-right">Value</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className="align-middle text-center"><a href="#link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-external-link wd-12 ht-12 stroke-wd-3">
                                                        <path
                                                            d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                        <polyline points="15 3 21 3 21 9"></polyline>
                                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                                    </svg>
                                                </a></td>
                                                <td className="align-middle tx-medium">Homepage</td>
                                                <td className="align-middle text-right">
                                                    <div className="wd-150 d-inline-block">
                                                        <div className="progress ht-4 mg-b-0">
                                                            <div className="progress-bar bg-teal wd-65p"
                                                                 role="progressbar" aria-valuenow="65" aria-valuemin="0"
                                                                 aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-right"><span className="tx-medium">65.35%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center"><a href="#link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-external-link wd-12 ht-12 stroke-wd-3">
                                                        <path
                                                            d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                        <polyline points="15 3 21 3 21 9"></polyline>
                                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                                    </svg>
                                                </a></td>
                                                <td className="align-middle tx-medium">Our Services</td>
                                                <td className="align-middle text-right">
                                                    <div className="wd-150 d-inline-block">
                                                        <div className="progress ht-4 mg-b-0">
                                                            <div className="progress-bar bg-primary wd-85p"
                                                                 role="progressbar" aria-valuenow="85" aria-valuemin="0"
                                                                 aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right"><span className="tx-medium">84.97%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center"><a href="#link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-external-link wd-12 ht-12 stroke-wd-3">
                                                        <path
                                                            d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                        <polyline points="15 3 21 3 21 9"></polyline>
                                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                                    </svg>
                                                </a></td>
                                                <td className="align-middle tx-medium">List of Products</td>
                                                <td className="align-middle text-right">
                                                    <div className="wd-150 d-inline-block">
                                                        <div className="progress ht-4 mg-b-0">
                                                            <div className="progress-bar bg-warning wd-45p"
                                                                 role="progressbar" aria-valuenow="45" aria-valuemin="0"
                                                                 aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right"><span className="tx-medium">38.66%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center"><a href="#link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-external-link wd-12 ht-12 stroke-wd-3">
                                                        <path
                                                            d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                        <polyline points="15 3 21 3 21 9"></polyline>
                                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                                    </svg>
                                                </a></td>
                                                <td className="align-middle tx-medium">Contact Us</td>
                                                <td className="align-middle text-right">
                                                    <div className="wd-150 d-inline-block">
                                                        <div className="progress ht-4 mg-b-0">
                                                            <div className="progress-bar bg-pink wd-15p"
                                                                 role="progressbar" aria-valuenow="15" aria-valuemin="0"
                                                                 aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right"><span className="tx-medium">16.11%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center"><a href="#link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-external-link wd-12 ht-12 stroke-wd-3">
                                                        <path
                                                            d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                        <polyline points="15 3 21 3 21 9"></polyline>
                                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                                    </svg>
                                                </a></td>
                                                <td className="align-middle tx-medium">Product 50% Sale</td>
                                                <td className="align-middle text-right">
                                                    <div className="wd-150 d-inline-block">
                                                        <div className="progress ht-4 mg-b-0">
                                                            <div className="progress-bar bg-teal wd-60p"
                                                                 role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                                                 aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right"><span className="tx-medium">59.34%</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mg-t-10">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="lh-5 mg-b-0">Browser Used By Users</h6>
                                </div>
                                <div className="card-body pd-y-15 pd-x-10">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                                            <thead>
                                            <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                                <th className="wd-5p">&nbsp;</th>
                                                <th>Browser</th>
                                                <th className="text-right">Sessions</th>
                                                <th className="text-right">Bounce Rate</th>
                                                <th className="text-right">Conversion Rate</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td><i className="fab fa-chrome tx-primary op-6"></i></td>
                                                <td className="tx-medium">Google Chrome</td>
                                                <td className="text-right">13,410</td>
                                                <td className="text-right">40.95%</td>
                                                <td className="text-right">19.45%</td>
                                            </tr>
                                            <tr>
                                                <td><i className="fab fa-firefox tx-orange"></i></td>
                                                <td className="tx-medium">Mozilla Firefox</td>
                                                <td className="text-right">1,710</td>
                                                <td className="text-right">47.58%</td>
                                                <td className="text-right">19.99%</td>
                                            </tr>
                                            <tr>
                                                <td><i className="fab fa-safari tx-primary"></i></td>
                                                <td className="tx-medium">Apple Safari</td>
                                                <td className="text-right">1,340</td>
                                                <td className="text-right">56.50%</td>
                                                <td className="text-right">11.00%</td>
                                            </tr>
                                            <tr>
                                                <td><i className="fab fa-edge tx-primary"></i></td>
                                                <td className="tx-medium">Microsoft Edge</td>
                                                <td className="text-right">713</td>
                                                <td className="text-right">59.62%</td>
                                                <td className="text-right">4.69%</td>
                                            </tr>
                                            <tr>
                                                <td><i className="fab fa-opera tx-danger"></i></td>
                                                <td className="tx-medium">Opera</td>
                                                <td className="text-right">380</td>
                                                <td className="text-right">52.50%</td>
                                                <td className="text-right">8.75%</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mg-t-10">
                            <div className="card card-dashboard-table">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th colSpan="3">Acquisition</th>
                                            <th colSpan="3">Behavior</th>
                                            <th colSpan="3">Conversions</th>
                                        </tr>
                                        <tr>
                                            <th>Source</th>
                                            <th>Users</th>
                                            <th>New Users</th>
                                            <th>Sessions</th>
                                            <th>Bounce Rate</th>
                                            <th>Pages/Session</th>
                                            <th>Avg. Session</th>
                                            <th>Transactions</th>
                                            <th>Revenue</th>
                                            <th>Rate</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><a href="#link">Organic search</a></td>
                                            <td><strong>350</strong></td>
                                            <td><strong>22</strong></td>
                                            <td><strong>5,628</strong></td>
                                            <td><strong>25.60%</strong></td>
                                            <td><strong>1.92</strong></td>
                                            <td><strong>00:01:05</strong></td>
                                            <td><strong>340,103</strong></td>
                                            <td><strong>$2.65M</strong></td>
                                            <td><strong>4.50%</strong></td>
                                        </tr>
                                        <tr>
                                            <td><a href="#link">Social media</a></td>
                                            <td><strong>276</strong></td>
                                            <td><strong>18</strong></td>
                                            <td><strong>5,100</strong></td>
                                            <td><strong>23.66%</strong></td>
                                            <td><strong>1.89</strong></td>
                                            <td><strong>00:01:03</strong></td>
                                            <td><strong>321,960</strong></td>
                                            <td><strong>$2.51M</strong></td>
                                            <td><strong>4.36%</strong></td>
                                        </tr>
                                        <tr>
                                            <td><a href="#link">Referral</a></td>
                                            <td><strong>246</strong></td>
                                            <td><strong>17</strong></td>
                                            <td><strong>4,880</strong></td>
                                            <td><strong>26.22%</strong></td>
                                            <td><strong>1.78</strong></td>
                                            <td><strong>00:01:09</strong></td>
                                            <td><strong>302,767</strong></td>
                                            <td><strong>$2.1M</strong></td>
                                            <td><strong>4.34%</strong></td>
                                        </tr>
                                        <tr>
                                            <td><a href="#link">Email</a></td>
                                            <td><strong>187</strong></td>
                                            <td><strong>14</strong></td>
                                            <td><strong>4,450</strong></td>
                                            <td><strong>24.97%</strong></td>
                                            <td><strong>1.35</strong></td>
                                            <td><strong>00:02:07</strong></td>
                                            <td><strong>279,300</strong></td>
                                            <td><strong>$1.86M</strong></td>
                                            <td><strong>3.99%</strong></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {this.renderCharts()}

                    </div>

                }

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChartTemplate));