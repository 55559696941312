import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setSiteData } from "../../../redux-js/actions/index";
import moment from 'moment';
import { convertServerDateToLocal } from '../../helpers/helpers'

import  Comments from  './../partials/comments-v2';

import jQuery from 'jquery';
jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class SingleRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {

            errorMsg: '',
            msg: '',
            btnEnabled: true,

            request: {

            },

            client:{},
            comment:{},

            request_fetched: false,
            current_user: {},

        };

        this.handleInputChange  = this.handleInputChange.bind(this);
        this.setStatus          = this.setStatus.bind(this);
    }


    postRequestComment(){

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/request-comments/', this.state.comment )
            .then(res => {


            })

            .catch((error) => {


            });

    }

    getRequest(){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/get-request/' + this.props.request_long_id + '/' )
            .then(res => {


                let request = res.data.request;


                if(request) {



                    if(this.props.public && request.client.support_url_slug){

                        /**
                         * Redirect to client portal
                         */

                        window.location.href =  '/portal/projects/'+ request.client.support_url_slug + '/request/' + this.props.request_long_id + '/';


                    }else{

                        this.setState({
                            request_fetched: true,
                            request: request,
                        });

                    }


                }

                else{

                    window.location.href = '/';
                    //this.props.history.push('/' );

                }

            })

            .catch((error) => {

                this.setState({
                    request_fetched: true,
                })
            });




    }

    componentDidMount(){



        if (this.props.current_user.role === 'client'){


            this.props.setSiteData({client_slug: this.props.current_user.slug, page: 'project-scope'});

        }


        this.getRequest();



        jQuery(document).on('click','.single-request-wrap a', function (e) {

            if (jQuery(this).attr('href').indexOf(window.location.host) === -1){
                //e.preventDefault();
                window.open(jQuery(this).attr('href'));
                return false;
            }

        });

    }


    componentWillUnmount(){


    }


    handleSubmitComment(){



    }


    handleInputChange(event) {

        const target = event.target;
        const name = target.name;
        let value = target.value;

        if (target.type === 'checkbox'){
            value = target.checked;
        }

        this.setState({[name]: value});


        //this.setState({[name]: value});


    }

    setStatus(status){


        jQuery('.request-status').removeClass('status-pending status-in-progress status-completed');

        jQuery('.request-status').text(status).addClass(this.getStatusClass(status));

        let data = {
            status: status,
            request_long_id: this.state.request.long_id
        };

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/request-status-set/', data, headers )
            .then(res => {


            })

            .catch((error) => {


            });

    }


    getDate(date){


        var d = convertServerDateToLocal(date);

        return moment(d).fromNow(true) + ' ago';

    }

    getStatusClass(status){

        return 'status-' + status.toLowerCase(status);

    }



    render() {



        return (

            <div style={{'maxWidth':'1000px','margin':'0 auto'}}>

                {
                    this.state.request_fetched &&

                    (
                        <div>
                            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                <div>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                            {
                                                this.state.request.client.support_url_slug &&
                                                <li className="breadcrumb-item"><Link to={'/portal/projects/'+this.state.request.client.support_url_slug+'/'}>Work Requests</Link></li>
                                            }


                                            {
                                                !this.state.request.client.support_url_slug && this.props.public&&
                                                <li className="breadcrumb-item">Work Requests</li>
                                            }



                                            <li className="breadcrumb-item active" aria-current="page">Request</li>
                                        </ol>
                                    </nav>


                                    <h4 className="mg-b-10 tx-spacing--1">{this.state.request.subject}</h4>

                                </div>
                                <div className="d-none d-md-block">
                                    {
                                        this.state.request.client.support_url_slug &&

                                        <Link className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" to={'/portal/projects/'+this.state.request.client.support_url_slug+'/'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file wd-10 mg-r-5"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                            View All Requests
                                        </Link>
                                    }
                                </div>
                            </div>

                            <div className="single-request-wrap single-request-wrap-v2">
                                <div className="request-content-wrap">
                                    <div className="card">

                                        <div className="card-header d-block d-sm-flex align-items-center justify-content-between z-index-50">
                                            <h6 className="mg-b-0">

                                                {
                                                    !this.state.request.client.id &&

                                                    <span>{this.state.request.first_name + ' ' + this.state.request.last_name}</span>
                                                }


                                                {
                                                    this.state.request.client.id  &&

                                                    <span>{this.state.request.client.first_name + ' ' + this.state.request.client.last_name +' / '+ this.state.request.client.company}</span>
                                                }

                                            </h6>

                                            <div className="d-flex align-items-center mg-xs-t-10 mg-md-t-0">
                                                <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03 mg-r-10" title={this.state.request.date}>{this.getDate(this.state.request.date_converted)}</span>

                                                {
                                                    this.props.current_user.role === 'administrator' &&
                                                    <div className="request-status-wrap">

                                                        <span className={'request-status change-status '+ this.getStatusClass(this.state.request.status)}>{this.state.request.status}</span>

                                                        <div className="change-status-wrap">
                                                            <button onClick={()=>this.setStatus('Pending')} className="pending">Pending</button>
                                                            <button onClick={()=>this.setStatus('In-Progress')} className="in-progress">In-Progress</button>
                                                            <button onClick={()=>this.setStatus('Completed')} className="completed">Completed</button>
                                                        </div>

                                                    </div>

                                                }

                                                {
                                                    this.props.current_user.role !== 'administrator' &&
                                                    <div className="request-status-wrap">
                                                        <span className={'request-status '+ this.getStatusClass(this.state.request.status)}>{this.state.request.status}</span>
                                                    </div>
                                                }

                                            </div>


                                        </div>


                                        <div className="card-body pd-y-30">


                                            <div>

                                                <div className="">

                                                    <div className="row">
                                                        <div className="col-lg-12">


                                                                <div className="request-content">
                                                                    <div dangerouslySetInnerHTML={{__html: this.state.request.request}}></div>

                                                                    {
                                                                        (this.state.request.attachments.length)

                                                                            ? (
                                                                            <div className="request-attachments">

                                                                                <strong><span className="fa fa-paperclip"></span> Attachments</strong>

                                                                                {this.state.request.attachments.map((attachment, i) =>
                                                                                    <div className="attachment-item item-row" key={i}>
                                                                                        <a rel="noopener noreferrer" target="_blank"
                                                                                           href={attachment.url}>{attachment.file_name}</a>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                            : ('')
                                                                    }


                                                                </div>



                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>


                                    </div>

                                    <Comments request={this.state.request} request_long_id={this.props.request_long_id} />



                                </div>
                            </div>
                        </div>
                    )

                }
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleRequest);