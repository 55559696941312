import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/Projects.scss';
import { authHeader } from '../helpers/helpers'
import { setSiteData } from "../../redux-js/actions/index";
import { connect } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import  Requests from  './partials/support-requests';
import jQuery from 'jquery';
jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


function formatDate(date, input) {



    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();




    if (input){

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');

    } else{

        var monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        return monthNames[month -1] + ' '+ day + ', '+ year;
    }

}


class Project extends Component {

    constructor(props) {
        super(props);

        this.state = {
            updateErrorMsg: '',
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            projects: [],
            project_scope_fetched: false,
            current_user: {},
            project_update_data:{
                title: '',
                estimated_completion:'',
                date_completed:'',
                approved_by:'',
                items: []
            },

            project_update_idx: 0
        };


        this.handleInputChange          = this.handleInputChange.bind(this);
        this.handleItemInputChange      = this.handleItemInputChange.bind(this);
        this.handleEditorChange         = this.handleEditorChange.bind(this);
        this.handleSubmit               = this.handleSubmit.bind(this);
        this.addItem                    = this.addItem.bind(this);
        this.setUpdateData              = this.setUpdateData.bind(this);
    }

    getProjectScopes(props){


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/project-scope-client-projects/' + props.match.params.client_slug + '/?status='+props.match.params.status )
            .then(res => {


                let projects = res.data.projects;

                this.setState({
                    project_scope_fetched: true,
                    projects:    projects ,
                })

            })

            .catch((error) => {

                this.setState({
                    project_scope_fetched: true,
                })
            });

    }

    componentDidMount(){




        if (!this.props.match.params.client_slug){



            if (this.props.current_user.role === 'client'){


                window.location.href = '/portal/projects/' + this.props.current_user.slug + '/';
                //this.props.history.push('/portal/projects/' + this.props.current_user.slug + '/' );

            }else{

                //window.location.href = '/';
                //this.props.history.push('/' );
            }
        }


        this.props.setSiteData({client_slug: this.props.match.params.client_slug, page: 'project-scope'});

        this.getProjectScopes(this.props);

        this.setState({
            current_user: this.props.current_user
        });



        if (this.props.current_user.role === 'client' && this.props.match.params.client_slug !== this.props.current_user.slug){

            /*
             * Do not allow a logged in client to access different client
             */
            window.location.href = '/portal/projects/' + this.props.current_user.slug + '/';

        }



        /**
         * Replace URLs
         */


        jQuery('body').on('click','.project-scope-item .psi-wrap a', function (e) {


            /**
             *
             * Make sure it has http text so it wont affect relative url like -> /portal/project/project-slug
             */

            if (jQuery(this).attr('href').indexOf('http') !== -1){
                e.preventDefault();
                window.open(process.env.REACT_APP_EXTERNAL_LINK_MASK + '?url='+ jQuery(this).attr('href'));
                return false;
            }

        });

        jQuery('body').on('mousedown','.project-scope-item .psi-wrap a', function (e) {

            //window.open(process.env.REACT_APP_EXTERNAL_LINK_MASK + '?url='+ jQuery(this).attr('href'));

            if (
                    jQuery(this).attr('href').indexOf(process.env.REACT_APP_EXTERNAL_LINK_MASK) === -1
                    &&
                    jQuery(this).attr('href').indexOf('http') !== -1
                ){
                jQuery(this).attr('href',process.env.REACT_APP_EXTERNAL_LINK_MASK + '?url='+ jQuery(this).attr('href'));
            }

        });




    }

    componentWillReceiveProps(nextProps){

        this.getProjectScopes(nextProps);
    }


    componentWillUnmount(){

        localStorage.setItem('redirect',this.props.location.pathname);

    }


    handleSubmit(){


        this.setState({updateErrorMsg: '', msg: ''});

        let project_update_data = this.state.project_update_data;
        project_update_data.short_description = window.editor.getContent();



        let data = {
            project: project_update_data,
        };



        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/update-project-scope', data , authHeader())
            .then(res => {

                if(res.data.success){


                    //window.xxxxyyy = jQuery;
                    jQuery('#edit-project-scope-modal').modal('hide');

                    var projects = this.state.projects;

                    //.formatDate

                    projects[this.state.project_update_idx] = res.data.project;

                    this.setState({
                        projects: projects
                    })

                }else{

                    this.setState({
                        updateErrorMsg: res.data.msg
                    });
                }

            })


            .catch((error) => {

                ///console.log(error);
                this.setState({updateErrorMsg: error.response.data.message});

            });


    }


    addItem(){


        let project_update_data = this.state.project_update_data;

        project_update_data.items.push({
            title: '',
            hours: '',
            cost:'',
            percent_completed:''
        });

        this.setState({project_update_data: project_update_data});

    }


    removeItem(item, idx){

        let project_update_data = this.state.project_update_data;

        project_update_data.items.splice(idx, 1);

        this.setState({project_update_data: project_update_data});


    }

    handleInputChange(event) {

        const target = event.target;
        const name = target.name;
        let value = target.value;

        if (target.type === 'checkbox'){
            value = target.checked;
        }

        var project_update_data = this.state.project_update_data;
        project_update_data[name] = value;

        this.setState({project_update_data: project_update_data});

    }


    handleItemInputChange(i, event) {

        const target = event.target;
        const name = target.name;
        let value = target.value;

        var project_update_data = this.state.project_update_data;

        project_update_data.items[i][name] = value;

        this.setState({project_update_data: project_update_data});
    }

    handleEditorChange(event){

        let project_update_data = this.state.project_update_data;

        project_update_data.short_description = event.level.content;

        this.setState({
            project_update_data: project_update_data
        });


    }

    setUpdateData(project, idx){

        //console.log(JSON.parse(JSON.stringify(project)));

        let data = JSON.parse(JSON.stringify(project));

        data.date_completed = formatDate(data.date_completed, true);
        data.estimated_completion = formatDate(data.estimated_completion, true);

        this.setState({
            project_update_data: data,//copy without reference
            project_update_idx: idx,
            editor_content: data.short_description
        });

        if (window.editor){

            window.editor.render();
        }


    }

    render() {


        return (

                <div className="content content-fixed">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                        <main>
                            <div style={{'maxWidth':'1000px','margin':'0 auto'}}>

                                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                    <div>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">

                                                <li className="breadcrumb-item active" aria-current="page">Work Requests</li>
                                            </ol>
                                        </nav>
                                        <h4 className="mg-b-10 tx-spacing--1">Work Requests</h4>

                                    </div>
                                    <div className="d-none d-md-block">
                                        {
                                            this.props.match.params.client_slug &&

                                            <Link className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" to={'/portal/projects/'+this.props.match.params.client_slug+'/make-request/'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file wd-10 mg-r-5"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                                Make a Request
                                            </Link>
                                        }
                                    </div>
                                </div>

                                <div className="page-wrap">

                                    <div className="project-scope-wrap">

                                        {/*<Header post_type="project-scope" />*/}

                                        {/*<main className="project-scope-wrap">*/}
                                            <div>
                                                <div>


                                                    <div className="card">
                                                        <div className="card-body">

                                                            <div className="project-scope-top-buttons">
                                                                <div className="ps-button">

                                                                    <span className="fa fa-filter"></span> Filter

                                                                    <ul className="ps-filter mg-0">
                                                                        <li className="all"><NavLink activeClassName='active' to={"/portal/projects/"+ this.props.match.params.client_slug +'/all/'}>All</NavLink></li>
                                                                        <li className="pending"><NavLink activeClassName='active' to={"/portal/projects/"+ this.props.match.params.client_slug +'/pending/'}>Pending</NavLink></li>
                                                                        <li className="in-progress"><NavLink activeClassName='active' to={"/portal/projects/"+ this.props.match.params.client_slug +'/in-progress/'}>In-Progress</NavLink></li>
                                                                        <li className="completed"><NavLink activeClassName='active' to={"/portal/projects/"+ this.props.match.params.client_slug +'/completed/'}>Completed</NavLink></li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {

                                                       this.state.project_scope_fetched && this.state.projects.length > 0 &&

                                                        (
                                                            <div className="project-scope-details-wrap">


                                                                {
                                                                    this.state.projects.map((project, idx) =>


                                                                    <div className="project-scope-item" key={idx}>

                                                                        <div className="row">
                                                                            <div className="col-md-8 col-lg-5 psi-col-1">

                                                                                <div className="psi-wrap">
                                                                                    <h3>{project.title}</h3>

                                                                                    <div dangerouslySetInnerHTML={{__html: project.short_description}}></div>

                                                                                    {
                                                                                        project.status !== 'pending' &&
                                                                                        <div className="approved-by">Approved By: {project.approved_by}</div>
                                                                                    }


                                                                                    {
                                                                                        this.state.current_user.role === 'administrator'
                                                                                        &&
                                                                                        <button className="edit" data-toggle="modal" data-target="#edit-project-scope-modal" onClick={()=>this.setUpdateData(project, idx)}>Edit</button>
                                                                                    }


                                                                                </div>

                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 psi-col-2">

                                                                                <div className="psi-wrap">
                                                                                    <div className="psi-wrap-inner">
                                                                                        <div className="text-center">

                                                                                            {
                                                                                                project.status === 'pending' &&

                                                                                                <div className="status-wrap">
                                                                                                    <div className="status">Status</div>
                                                                                                    <div className="project-scope-status-label">

                                                                                                        {
                                                                                                            (this.props.current_user.role === 'staff') &&
                                                                                                            <Link to={'/login/'}><span className="pending">Approve Estimate</span></Link>
                                                                                                        }

                                                                                                        {
                                                                                                            // ( this.props.current_user.role === 'administrator' || this.props.current_user.role === 'client' )
                                                                                                            this.props.current_user.role !== 'staff' &&
                                                                                                            <Link to={'/portal/project/'+project.slug+'/'}><span className="pending">Approve Estimate</span></Link>
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div className="date">{project.estimated_completion}</div>
                                                                                                    <div className="completion-txt">ESTIMATED COMPLETION</div>
                                                                                                </div>
                                                                                            }


                                                                                            {
                                                                                                project.status === 'in-progress' &&

                                                                                                <div className="status-wrap">
                                                                                                    <div className="status">Status</div>
                                                                                                    <div className="project-scope-status-label">
                                                                                                        <span className="in-progress">In Progress</span>
                                                                                                    </div>
                                                                                                    <div className="date">{project.estimated_completion}</div>
                                                                                                    <div className="completion-txt">ESTIMATED COMPLETION</div>
                                                                                                </div>
                                                                                            }


                                                                                            {
                                                                                                project.status === 'completed' &&

                                                                                                <div className="status-wrap">
                                                                                                    <div className="status">Status</div>
                                                                                                    <div className="project-scope-status-label">
                                                                                                        <span className="completed">Completed</span>
                                                                                                    </div>
                                                                                                    <div className="date">{project.date_completed}</div>
                                                                                                </div>
                                                                                            }


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12 col-lg-4 psi-col-3">

                                                                                <div className="psi-wrap">
                                                                                    <div className="items">
                                                                                        {
                                                                                            project.items.map((item, i) =>
                                                                                            <div key={i} className="item">
                                                                                                <div className="title">{item.title}</div>
                                                                                                <div className="hours"><span>{item.hours}</span></div>
                                                                                                <div className={'item-progress ' + project.status}>
                                                                                                    <span style={{width:item.percent_completed+'%'}}></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>

                                                                                    {
                                                                                        //this.state.current_user.role === 'client' || this.state.current_user.role === 'administrator'
                                                                                        this.state.current_user.role !== 'staff' && project.total !== "0" &&

                                                                                        <div className="item-total">
                                                                                            <div className={project.status}>Total: <span>${project.total}</span></div>
                                                                                        </div>

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                )}
                                                            </div>
                                                        )
                                                    }



                                                    <Requests client_slug={this.props.match.params.client_slug} filter_status={this.props.match.params.status} />


                                                </div>

                                            </div>
                                        {/*</main>*/}
                                    </div>



                                    {
                                        this.state.current_user.role === 'administrator' && this.state.project_update_data &&

                                        <div className="modal fade" id="edit-project-scope-modal" tabIndex={-1} role="dialog" aria-labelledby="edit-project-scope-modal-label" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="edit-project-scope-modal-label">Edit</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body">

                                                        <div className="form-group">
                                                            <div className="form-label">Title</div>
                                                            <input type="text" name="title" className="form-control" value={this.state.project_update_data.title} onChange={this.handleInputChange}/>
                                                        </div>


                                                        <div className="form-group">
                                                            <div className="form-label">Details</div>

                                                            <Editor
                                                                apiKey={process.env.REACT_APP_TINY_API}
                                                                initialValue={this.state.editor_content}
                                                                readonly={false}
                                                                init={{
                                                                    readonly : false,
                                                                    menubar: false,

                                                                    //force_br_newlines : true,
                                                                    //force_p_newlines : false,
                                                                    //forced_root_block : 'div',
                                                                    //apply_source_formatting: false,
                                                                    plugins: [

                                                                        //tinymcespellchecker
                                                                        'print preview fullpage powerpaste searchreplace autolink directionality',
                                                                        ' advcode code visualblocks visualchars fullscreen image link media mediaembed',
                                                                        'template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime',
                                                                        'advlist lists wordcount  a11ychecker imagetools textpattern help formatpainter ',
                                                                        'permanentpen pageembed linkchecker  noneditable',
                                                                        //d6-checklist

                                                                    ],

                                                                    external_plugins: {

                                                                    },

                                                                    color_map: [
                                                                        "000000", "Black",
                                                                        "993300", "Burnt orange",
                                                                        "333300", "Dark olive",
                                                                        "003300", "Dark green",
                                                                        "003366", "Dark azure",
                                                                        "000080", "Navy Blue",
                                                                        "333399", "Indigo",
                                                                        "333333", "Very dark gray",
                                                                        "800000", "Maroon",
                                                                        "FF6600", "Orange",
                                                                        "808000", "Olive",
                                                                        "008000", "Green",
                                                                        "008080", "Teal",
                                                                        "0000FF", "Blue",
                                                                        "666699", "Grayish blue",
                                                                        "808080", "Gray",
                                                                        "FF0000", "Red",
                                                                        "FF9900", "Amber",
                                                                        "99CC00", "Yellow green",
                                                                        "339966", "Sea green",
                                                                        "33CCCC", "Turquoise",
                                                                        "3366FF", "Royal blue",
                                                                        "800080", "Purple",
                                                                        "999999", "Medium gray",
                                                                        "FF00FF", "Magenta",
                                                                        "FFCC00", "Gold",
                                                                        "FFFF00", "Yellow",
                                                                        "00FF00", "Lime",
                                                                        "00FFFF", "Aqua",
                                                                        "00CCFF", "Sky blue",
                                                                        "993366", "Red violet",
                                                                        "FFFFFF", "White",
                                                                        "FF99CC", "Pink",
                                                                        "FFCC99", "Peach",
                                                                        "FFFF99", "Light yellow",
                                                                        "CCFFCC", "Pale green",
                                                                        "CCFFFF", "Pale cyan",
                                                                        "99CCFF", "Light sky blue",
                                                                        "CC99FF", "Plum"
                                                                    ],


                                                                    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat',

                                                                    height: 300,// jQuery(document).height()

                                                                    setup: function (editor) {

                                                                        window.editor = editor;

                                                                        editor.on('PreInit', function (event) {

                                                                            //let ed = event.target;//, dom = ed.dom;
                                                                        });

                                                                    },

                                                                }}
                                                                //onChange={this.handleEditorChange}
                                                            />


                                                        </div>
                                                        <div className="form-group">

                                                            <div className="form-label">Status</div>
                                                            <select name="status" className="form-control" value={this.state.project_update_data.status} onChange={this.handleInputChange}>
                                                                <option value="pending">Pending</option>
                                                                <option value="in-progress">In Progress</option>
                                                                <option value="completed">Completed</option>
                                                            </select>
                                                        </div>

                                                        <div className="row">

                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <div className="form-label">Estimated Completion</div>
                                                                    <input type="date" name="estimated_completion" className="form-control" value={this.state.project_update_data.estimated_completion} onChange={this.handleInputChange}/>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <div className="form-label">Date Completed</div>
                                                                    <input type="date" name="date_completed" className="form-control" value={this.state.project_update_data.date_completed} onChange={this.handleInputChange}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="form-label">Approved By</div>
                                                            <input type="text" name="approved_by" className="form-control" value={this.state.project_update_data.approved_by} onChange={this.handleInputChange}/>
                                                        </div>

                                                        <div className="project-scope-list-items">
                                                            {
                                                                this.state.project_update_data.items.map((item, i) =>
                                                                    <div key={i} className="item">

                                                                        <div className="remove-item" onClick={()=>this.removeItem(item, i)}>&times;</div>

                                                                        <div className="form-group">
                                                                            <div className="form-label">Title</div>
                                                                            <input type="text" className="form-control" value={item.title} name="title" onChange={e => this.handleItemInputChange(i, e)}/>
                                                                        </div>

                                                                        <div className="row">

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <div className="form-label">Hours</div>
                                                                                    <input type="text" className="form-control" value={item.hours} name="hours" onChange={e => this.handleItemInputChange(i, e)}/>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <div className="form-label">Cost</div>
                                                                                    <input type="text" className="form-control" value={item.cost?item.cost.replace(/,/g,''):''} name="cost" onChange={e => this.handleItemInputChange(i, e)}/>
                                                                                </div>
                                                                            </div>


                                                                            {/*<div className="title">{item.title}</div>*/}
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <div className="form-label">Percent Completed</div>
                                                                                    <div>
                                                                                        <input className="form-control" type="number" value={item.percent_completed} name="percent_completed" min="0" max="100" onChange={e => this.handleItemInputChange(i, e)} />
                                                                                        {/*<div className="text-right"><strong>{item.percent_completed}</strong>%</div>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                        <div className="add-project-scope-item">
                                                            <span onClick={this.addItem}>+ add item</span>
                                                        </div>

                                                        <div>
                                                            <button className="btn btn-primary" onClick={this.handleSubmit} ><span>Update</span></button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Project);