import React, { Component } from "react";
import { withRouter } from "react-router-dom";//Route,
import { connect } from "react-redux";
//import { NavLink } from "react-router-dom";
import { authHeader } from '../helpers/helpers'
import axios from 'axios';
//import { appendScript, appendCSS } from '../../components/helpers/helpers';

import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};

function mapDispatchToProps(dispatch) {
    return {

    };
}


class TableTemplate extends Component {

    constructor(props){
        super(props);

        this.state = {
            btnEnabled: true,
            current_page: {},
            page_url: '',
            wp_tables:[],
            form: {
                wp_table_id: '',
            },
            data_fetched: false,
        }

        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);

    }


    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var form = this.state.form;
        form[name] = value

        this.setState({form});
    }


    handleSubmit(event) {

        event.preventDefault();


        this.setState({btnEnabled: false});

        // Create an object of formData
        const formData = new FormData();

        formData.append("wp_table_id",this.state.form.wp_table_id);
        formData.append("page_id", this.state.current_page.id);
        formData.append("template", 'table-template');


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/save', formData , authHeader())
            .then(res => {


                if (res.data.success){

                    this.setState({
                        errorMsg: '',
                    });

                }else {

                    this.setState({
                        errorMsg: res.data.error_msg,
                    });

                }


                this.setState({
                    btnEnabled: true,
                    page_url: res.data.page_url,
                });

            })

            .catch((error) => {

                this.setState({btnEnabled: true});

            });

    }


    templateData(current_page){


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/template/data/?template=table-template&page_id=' + current_page.id, authHeader())
            .then(res => {

                let form        = { wp_table_id: res.data.wp_table_id };
                let data_url    = res.data.page_url;


                this.setState({
                    page_url: data_url,
                    data_fetched: true,
                    wp_tables: res.data.wp_tables,
                    form: form
                });


            })

            .catch((error) => {

            });

    }


    setPage(current_page){

        this.setState({current_page: current_page});


        this.templateData(current_page);
    }

    componentDidMount(){

        let ds = this;

        this.setPage(this.props.current_page);

        jQuery('body').addClass('project-book-custom-page');



        window.addEventListener('message',function(e) {
            let key = e.message ? 'message' : 'data';
            let data = e[key];

            if (data.action && data.action === 'table-dom-change'){

                ds.resizeIframe(data.height);
            }
            //run function//
        },false);
    }

    componentWillUnmount() {

        jQuery('body').removeClass('project-book-custom-page');
    }

    componentWillReceiveProps(nextProps) {

        this.setPage(nextProps.current_page);
    }



    resizeIframe(height){

        //var height = jQuery('.table-template iframe#table-iframe')[0].contentWindow.document.body.scrollHeight;

        if (height){
            jQuery('.table-template iframe#table-iframe').css({height: height});
        }

    }


    render() {



        return (

            <div className="custom-page-wrap">


                {
                    !this.state.data_fetched &&
                    <div>
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    </div>
                }


                {
                    this.state.data_fetched &&

                    <div className="table-template">

                        {
                            console.log(this.state.page_url)
                        }

                        {
                            this.state.page_url !== '' &&

                            <iframe id="table-iframe" src={this.state.page_url} frameBorder="0" title="Table"></iframe>
                        }


                        {
                            (!this.state.page_url && (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff')) &&

                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mg-0">WP Tables</h6>
                                </div>
                                <div className="card-body">

                                    <form action="" onSubmit={this.handleSubmit}>

                                        <div className="custom-page-fields">

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-label">Select Table</div>

                                                        <select value={this.state.form.wp_table_id} name="wp_table_id" className="form-control custom-select" onChange={this.handleInputChange}>

                                                            <option value="" key={0}></option>

                                                            {
                                                                this.state.wp_tables.map((table, i)=>
                                                                    <option key={i+1} value={table.id}>{table.title}</option>
                                                                )
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <button disabled={!this.state.btnEnabled} className="btn btn-primary" type="submit">Submit</button>

                                        {
                                            (this.state.errorMsg) &&
                                            <div className="alert alert-danger mg-t-15">{this.state.errorMsg}</div>
                                        }

                                    </form>

                                </div>
                            </div>

                        }


                    </div>

                }

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableTemplate));