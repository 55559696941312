import React, { Component } from "react";
//import Header from '../header-v2';
//import { Link } from "react-router-dom";
import '../../assets/styles/Request.scss';
import { connect } from "react-redux";
import { setSiteData } from "../../redux-js/actions/index";
import RequestSingle from './partials/request-single'

import jQuery from 'jquery';
jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class SingleRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {


        };

    }

    componentDidMount(){

        jQuery('body').addClass('make-request-page');



        if (!this.props.match.params.client_slug){

            if (this.props.current_user.role === 'client'){


                window.location.href = '/portal/projects/' + this.props.current_user.slug + '/';
                //this.props.history.push('/portal/projects/' + this.props.current_user.slug + '/' );

            }else{

                //window.location.href = '/';
                //this.props.history.push('/' );
            }
        }

        else {

            //if (this.props.current_user.role !== 'client'){
            //this.props.history.push('/make-request/' );
            //}


        }


        this.props.setSiteData({client_slug: this.props.match.params.client_slug, page: 'project-scope'});


    }

    componentWillUnmount(){

        jQuery('body').removeClass('make-request-page');
    }


    render() {



        return (

            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <main>
                        <RequestSingle request_long_id={this.props.match.params.request_long_id} public={false}/>
                    </main>
                </div>
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleRequest);