import React, { Component } from "react";
import { withRouter } from "react-router-dom";//Route,
import { connect } from "react-redux";
import axios from 'axios';

import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};

function mapDispatchToProps(dispatch) {
    return {

    };
}


class SitemapBuilderTemplate extends Component {

    constructor(props){
        super(props);

        this.state = {
            sitemap: {
                content:''
            },
            current_page: {}
        }
    }


    componentDidMount(){

        this.setState({current_page: this.props.current_page});

        jQuery('body').addClass('project-book-custom-page');

        this.getSitemap(this.props.current_page);

    }


    componentWillReceiveProps(nextProps) {

        this.setState({current_page: nextProps.current_page});

        this.getSitemap(nextProps.current_page);
    }


    componentWillUnmount() {

        jQuery('#sub-item-actions-nav').remove();
        jQuery('body').removeClass('project-book-custom-page');
    }

    sitemapSortable(){

        var ds = this;

        jQuery( function() {

            jQuery( ".sitemap-lvl" ).sortable({
                cancel: ':input,button,.textarea',//fix contenteditable editing and focus issue
                connectWith: ".sitemap-lvl",
                handle: '.handle',
                start: function( event, ui ) {

                },
                update:function( event, ui ) {
                    ds.saveSitemap();
                }
            })//.disableSelection();


            //jQuery('.sitemap-lvl').bind('sortstart', function(event, ui) {
            //jQuery('.ui-sortable-placeholder').append();
            //});

        } );

    }



    runJQuery(){


        var ds = this;


        jQuery(document).off("keyup.sitemapItemKeyup");
        jQuery(document).off("keyup.sitemapSubItemKeyup");

        jQuery(document).off("click.sitemapAddItem");
        jQuery(document).off("click.sitemapEditText");
        jQuery(document).off("click.sitemapAddSubItem");
        jQuery(document).off("click.sitemapSubItemColor");


        jQuery(document).off("keypress.sitemapSubItemKeypress");

        //jQuery('.item .textarea').off('keyup').unbind('keyup');
        //jQuery(document).off("click", ".act .add", function () {});
        //jQuery(document).unbind("click", ".act .add", function () {});

        //jQuery('.add-section-btn, .act .remove, .sitemap-control a, .act .add').off('click').unbind('click');


        setTimeout(function () {


            jQuery('.txt .textarea').attr('contenteditable', false);

            ds.sitemapSortable();

            var newSitemapLvl = '<ul class="sitemap-lvl">'+
                                    '<li>' +
                                        '<div class="item-wrap">' +
                                            '<div class="item">' +
                                                '<div class="handle"></div>' +
                                                '<div class="txt">'+
                                                    '<div class="textarea" contenteditable="false">New Page</div>' +
                                                    '<div class="sub-items">'+
                                                        '<div class="add-sub-item">+</div>'+
                                                    '</div>' +
                                                '</div>' +
                                                '<div class="act">' +
                                                    '<span class="add">+</span> ' +
                                                    '<span class="remove">&times;</span>' +
                                                '</div>' +
                                            '</div>' +
                                        '</div>' +
                                        '<ul class="sitemap-lvl ui-sortable"></ul>' +
                                    '</li>' +
                                '</ul>';


            var newSitemapItem = '<li>' +
                                        '<div class="item-wrap">' +
                                            '<div class="item">' +
                                                '<div class="handle"></div>' +
                                                '<div class="txt">'+
                                                    '<div class="textarea" contenteditable="false">New Page</div>' +
                                                    '<div class="sub-items">'+
                                                        '<div class="add-sub-item">+</div>'+
                                                    '</div>' +
                                                '</div>' +
                                                '<div class="act">' +
                                                    '<span class="add">+</span> ' +
                                                    '<span class="remove">&times;</span>' +
                                                '</div>' +
                                            '</div>' +
                                        '</div>' +
                                        '<ul class="sitemap-lvl ui-sortable"></ul>' +
                                '</li>';



            jQuery(document).on('click.sitemapEditText','.txt .textarea',function () {


                if ( jQuery(this).attr('contenteditable') === 'false') {

                    //jQuery( ".sitemap-lvl" ).sortable("disable"); // fix contenteditable editing text issue

                    jQuery(this).trigger('blur');
                    jQuery(this).attr('contenteditable', true);

                    jQuery(this).focus();
                    document.execCommand('selectAll', false, null);

                }

            });


            jQuery(document).on('click.sitemapAddItem','.act .add',function () {


                if(jQuery(this).hasClass('home')){

                    if (jQuery(this).parents('.sitemap-section').find('ul.sitemap-lvl').length){

                        jQuery(this).parents('.sitemap-section').find('ul.sitemap-lvl').eq(0).append(newSitemapItem);

                    }else{

                        jQuery(this).parents('.sitemap-section').append(newSitemapLvl);
                    }

                }

                else{


                    var $list = jQuery(this).closest('li').find('ul').first();

                    if ($list.length){

                        $list.append(newSitemapItem);

                    }else{

                        jQuery(this).closest('li').append(newSitemapLvl);

                    }

                }


                setTimeout(function () {
                    //jQuery( ".sitemap-lvl" ).sortable('destroy');
                    ds.sitemapSortable();
                },100);


                ds.saveSitemap();

            });


            var smaps  = 1;

            jQuery('.sitemap-builder-container').css({'transform': 'scale('+smaps+')'});


            jQuery('.sitemap-control span').on('click',function () {

                if (jQuery(this).hasClass('up')){
                    smaps = smaps + .1;
                }else{
                    smaps = smaps - .1;
                }

                if(smaps <= 0.5) {
                    smaps = .5;
                }

                if(smaps > 1){
                    smaps = 1;
                }

                jQuery('.sitemap-builder-container').css({'transform': 'scale('+smaps+')'});

            });



            jQuery(document).on('click','.act .remove',function () {

                if(jQuery(this).hasClass('home')){

                    jQuery(this).parents('.sitemap-section').remove();

                }

                else{


                    jQuery(this).closest('li').remove();

                }

                ds.saveSitemap();

            });


            var ajax_delay = (function(){

                var ajax_timer = 0;

                return function(callback, ms){
                    clearTimeout (ajax_timer);
                    ajax_timer = setTimeout(callback, ms);
                };

            })();

            jQuery(document).on('keyup.sitemapItemKeyup','.item .textarea',function () {


                //jQuery(this).text(jQuery(this).text());

                //jQuery(this).css({height: '0.875em'});


                //var sH = parseFloat(jQuery(this)[0].scrollHeight);
                //jQuery(this).css({height: (sH/14) + 'em' });//16 base font-size


                ajax_delay(function(){

                    ds.saveSitemap();

                }, 1000 );

            });


            jQuery(document).on('blur','.item .textarea',function () {

                //ds.sitemapSortable();//re-init

                jQuery(this).attr('contenteditable', false);
            });




            jQuery(document).bind('keypress.sitemapSubItemKeypress', function (event) {

                let keycode = (event.keyCode ? event.keyCode : event.which);



                if(parseInt(keycode) === 13){

                    var $el = jQuery(event.target);

                    if($el.attr('contenteditable') && $el.hasClass('sub-item-text')){


                        /**
                         * Add sub item on enter
                         */

                        if ($el.text()){

                            $el.attr('contenteditable',false);
                            $el.trigger('blur');

                            $el.closest('.sub-item').after('<div class="sub-item bg-primary"><span class="sub-item-text" contenteditable="true"></span><span class="sub-item-actions"></span></div>');
                            $el.closest('.sub-item').next().find('.sub-item-text').focus();
                        }


                        return false;

                    } else if($el.attr('contenteditable') && $el.hasClass('textarea')){

                        $el.attr('contenteditable',false);
                        $el.trigger('blur');
                        return false;
                    }

                }
            });




            /***
             * Sub Item
             */

            jQuery(document).on('click.sitemapAddSubItem', '.item .add-sub-item', function () {

                jQuery(this).removeClass('add-sub-item')
                    .addClass('sub-item bg-primary')
                    .html('<span class="sub-item-text"></span><span class="sub-item-actions"></span>')
                    .find('.sub-item-text')
                    .attr('contenteditable', true)
                    .focus();

                document.execCommand('selectAll', false, null);
            });


            jQuery(document).on('keyup.sitemapSubItemKeyup','.item .sub-item .sub-item-text',function () {

                ajax_delay(function(){

                    ds.saveSitemap();

                }, 1000 );

            });



            //////////
            //edit sub item
            //////////


            jQuery(document).on('click.sitemapEditSubItem','.item .sub-item',function () {



                if ( jQuery(this).find('.sub-item-text').attr('contenteditable') === 'false' || jQuery(this).find('.sub-item-text').attr('contenteditable') === undefined) {

                    jQuery(this).find('.sub-item-text').trigger('blur').attr('contenteditable', true).focus();
                    document.execCommand('selectAll', false, null);

                }

            });


            jQuery(document).on('blur','.item .sub-item .sub-item-text',function () {

                jQuery(this).attr('contenteditable', false);
            });




            //////////
            //remove sub item
            //////////

            jQuery(document).on('click.sitemapDeleteSubItem','#sub-item-actions-nav .remove-sub-item',function (e) {

                e.stopPropagation();

                //'<div class="add-sub-item">+</div>'+

                if ($current_sub_item_action_clicked.closest('.sub-items').find('.sub-item').length <= 1){

                    $current_sub_item_action_clicked.closest('.sub-items').append('<div class="add-sub-item">+</div>');
                }

                $current_sub_item_action_clicked.remove();



                ds.saveSitemap();

            });




            //////////
            //show and position sub item actions nav
            //////////

            var $current_sub_item_action_clicked = null;

            jQuery(document).on('click.sitemapSubItemActions', '.item .sub-item .sub-item-actions', function (e) {

                e.stopPropagation();

                $current_sub_item_action_clicked = jQuery(this).closest('.sub-item');

                jQuery('#sub-item-actions-nav').addClass('pakita').css({top:e.pageY, left: e.pageX});

            });




            //////////
            //set sub item color
            //////////


            jQuery(document).on('click.sitemapSubItemColor', '#sub-item-actions-nav span', function () {

                $current_sub_item_action_clicked.attr('class','sub-item ' + jQuery(this).data('color'));

                ds.saveSitemap();
            });


            //////////
            //hide sub item actions nav
            //////////

            jQuery(document).on('click','.sitemap-viewer, .sub-item-action', function () {

                jQuery('#sub-item-actions-nav').removeClass('pakita');
            });




            //////////
            //append sub item actions nav to the body
            //////////


            jQuery('#sub-item-actions-nav').remove();

            jQuery('body').append(`<div id="sub-item-actions-nav">
                                        <div class="sub-item-colors">
                                            <span class="sub-item-action bg-primary" data-color="bg-primary"></span>
                                            <span class="sub-item-action bg-secondary" data-color="bg-secondary"></span>
                                            <span class="sub-item-action bg-success" data-color="bg-success"></span>
                                            <span class="sub-item-action bg-warning" data-color="bg-warning"></span>
                                            <span class="sub-item-action bg-danger" data-color="bg-danger"></span>
                                            <span class="sub-item-action bg-indigo" data-color="bg-indigo"></span>
                                            <span class="sub-item-action bg-purple" data-color="bg-purple"></span>
                                            <span class="sub-item-action bg-orange" data-color="bg-orange"></span>
                                            <span class="sub-item-action bg-teal" data-color="bg-teal"></span>
                                            <span class="sub-item-action bg-pink" data-color="bg-pink"></span>
                                        </div>
                                        
                                        <div class="spacer"></div>
                                        <div class="sub-item-action remove-sub-item"><span class="fa fa-trash-alt"></span> Remove</div>
                                        
                                    </div>`);



            /***
             *
             *
             */


        },100);

    }

    getSitemap(current_page){


        //this.$Progress.start();


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/template/data/?template=sitemap-template&page_id=' + current_page.id, {})
            .then(res => {


                this.setState({
                    sitemap: res.data.sitemap,
                    data_fetched: true,
                });

            })

            .catch((error) => {


            });


    }


    saveSitemap(){

        let content = jQuery('.sitemap-builder-container').html();

        let sitemap = {
            content: content
        };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/sitemap/save/', {sitemap, page_id: this.state.current_page.id} , {})
            .then(res => {


            })

            .catch((error) => {


            });

    }


    render() {


        return (

            <div className="custom-page-wrap">





                {
                    !this.state.data_fetched &&
                    <div>
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    </div>
                }

                {
                    this.state.data_fetched &&


                    <div className="sitemap-viewer">
                        <div className="sitemap-control">
                            <span className="up">+</span>
                            <span className="down">-</span>
                        </div>

                        <div className="sitemap-builder-container-wrap">
                            <div className="sitemap-builder-container" dangerouslySetInnerHTML={{__html: this.state.sitemap.content}}></div>
                        </div>

                        {this.runJQuery()}
                    </div>
                }


            </div>


        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SitemapBuilderTemplate));