import React, { Component } from "react";
import axios from 'axios';
//import { BrowserRouter as Route, Link, Prompt } from "react-router-dom";
import Dropzone from 'react-dropzone'
import Editor from './editor';
import '../../../assets/styles/Editor.scss';

import { connect } from "react-redux";
import { addComment } from "../../../redux-js/actions/index";

const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {
        addComment: comment => dispatch(addComment(comment))
    };
}


class CommentForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            link_text: '',
            content: '',
            attachments: [],
            btnEnabled: true,
            post_name: ''
        };


        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);

        this.handleDropFiles    = this.handleDropFiles.bind(this);

        this.editorContent      = this.editorContent.bind(this);


        this.theEditor = React.createRef();
    }



    editorContent(content){

        this.setState({content:content});

    }

    componentDidMount(){


    }

    componentWillReceiveProps(nextProps) {

        //this.setState({post_name:nextProps.slug});
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmit(event) {

        event.preventDefault();

        this.setState({'btnEnabled': false});


        let formData = new FormData();

        this.state.attachments.map((file) => {
            return formData.append('Files[]',file);
        });

        formData.append('comment', this.state.content);
        formData.append('request_long_id', this.props.request_long_id);


        //'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token,

        var headers = {};

        if ( JSON.parse(localStorage.getItem('currentUser')).token){
            headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token, 'Content-Type': 'multipart/form-data'} };
        }

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/request-comments', formData , headers)
            .then(res => {

                //console.log(res);
                //console.log(res.data);

                if (res.data.success){

                    this.setState({
                        title: '',
                        msg: res.data.msg,
                        errorMsg: '',
                        attachments: []
                    });



                    this.props.comment_added(res.data.comment);

                    //call parent here component to update comment list

                    this.theEditor.current.clearEditor();

                }else {

                    this.setState({
                        errorMsg: res.data.msg
                    });

                }

                this.setState({'btnEnabled': true});
            })


            .catch((error) => {

                //console.log(error.response.data);
                this.setState({
                    errorMsg: 'Error 1010: Please try again later.'//error.response.data.message
                });
                this.setState({'btnEnabled': true});

            });

    }


    handleDropFiles (acceptedFiles){

        let attachments = this.state.attachments;


        for (var i = 0; i < acceptedFiles.length; i++){
            attachments.push(acceptedFiles[i]);
        }

        this.setState({attachments:attachments});

    }

    deleteAttachment(e, idx){


        let attachments = this.state.attachments;

        attachments.splice(idx, 1);
        this.setState({attachments: attachments});


        //console.log(this.state.attachments);
    }



    render() {


        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;

        if(errorMsg){
            errorMsg = <AlertDanger state={this.state} />
        }

        if (msg){
            msg = <AlertSuccess state={this.state} />
        }


        return (
            <form className="request-form" onSubmit={this.handleSubmit}  ref={(f) => {this.formRef = f;}}>

                <div className="form-group">

                    <div className="draftjs-editor-wrap">

                        <Editor
                            ref={this.theEditor}
                            onChange={this.editorContent}
                            placeholder="Add your comment here"
                        />


                        <Dropzone
                            onDrop={this.handleDropFiles}

                            accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf, application/zip, application/atom+xml, audio/mp4, video/mp4,application/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/vnd.adobe.photoshop, text/csv, text/plain, text/html, application/postscript, "
                            multiple={true}
                            //maxSize={200000}
                        >

                            {({getRootProps, getInputProps}) => (
                                <div className="attach-file-btn" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="attach-icon"></div>
                                </div>
                            )}
                        </Dropzone>



                    </div>

                    {
                        this.state.attachments &&
                        <div className="attachments">

                            {this.state.attachments.map((attachment,i) =>
                                <div className="attachment-item item-row" key={i}>
                                        <span onClick={((e) => this.deleteAttachment(e, i))}>&times;</span>
                                        {attachment.name}
                                </div>
                            )}

                        </div>

                    }

                </div>


                <div>
                    <button disabled={!this.state.btnEnabled} className="btn btn-primary" type="submit"><span>Submit</span></button>

                    {
                        !this.state.btnEnabled &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>

                    }

                    {errorMsg}
                    {msg}

                </div>


            </form>
        );
    }
}



function AlertDanger(props) {

    let msg = (
        <div className="alert alert-danger">
            <div dangerouslySetInnerHTML={{__html: props.state.errorMsg}}></div>

        </div>
    );


    return ( msg );

}

function AlertSuccess(props) {


    let msg = (
        <div className="alert alert-success">
            <div dangerouslySetInnerHTML={{__html: props.state.msg}}></div>
        </div>
    );

    return ( msg );
}



export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);


