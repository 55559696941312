import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import Page from './page';
import PagePage from './page-page';

const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {

    };
}


class ProjectBookDevelopment extends Component {

    constructor(props){
        super(props);

        this.state = {

            project_book_page_slug: 'development',

            // page_sub_pages: {
            //     'development-site-1':{
            //         title: 'Development Site 1.0',
            //         slug: 'development-site-1'
            //     },
            //     'development-site-2':{
            //         title: 'Development Site 2.0',
            //         slug: 'development-site-2'
            //     },
            //     'development-q-a':{
            //         title: 'Q/A',
            //         slug: 'development-q-a'
            //     },
            // }
        }
    }


    doRedirect(props){

        //if exact /design
        if (props.match.isExact){
            //let first_page_section = this.state.page_sub_pages[Object.keys(this.state.page_sub_pages)[0]];


            let first_page_section = this.props.project_book_info.sub_pages[this.state.project_book_page_slug][0];



            this.props.history.push('/portal/pages/'+ this.props.match.params.project_book_slug +'/'+ this.state.project_book_page_slug +'/'+ first_page_section['slug'] +'/');
        }

    }


    componentDidMount(){




        this.doRedirect(this.props);


    }

    componentWillReceiveProps(nextProps) {

        this.doRedirect(nextProps)



    }

    render() {

        return (

            <div className="">
                <div className="wrap" style={{padding:'0'}}>


                    <div className="">
                        <Route exact={true} path={`${this.props.match.path}/:page_section`} render={(props) => (<PagePage page={this.state.project_book_page_slug}  project_book_page_pages={this.props.project_book_page_pages} {...props}/>)} />
                        <Route path={`${this.props.match.path}/:page_section/:page_slug`} render={(props) => (<PagePage page={this.state.project_book_page_slug}  project_book_page_pages={this.props.project_book_page_pages} {...props}/>)} />
                    </div>
                </div>
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectBookDevelopment));