import React, { Component } from "react";
import axios from 'axios';
import {CardElement, injectStripe} from 'react-stripe-elements';

import { connect } from "react-redux";





const mapStateToProps = state => {
    return {

    };
};


function mapDispatchToProps(dispatch) {
    return {
    };
}



class ClientCardForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            client: '',
            card: {
                first_name:'',
                last_name: '',
                email: '',
                token: '',
                company:'',
            }
        };


        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
    }



    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        var card = this.state.card;
        card[name] = value;

        this.setState({card});
    }


    async handleSubmit() {

        this.setState({errorMsg: '', msg: ''});

        if (this.state.card.first_name === '') {
            this.setState({errorMsg: 'First name field is required.'});
            return false;
        }

        if (this.state.card.last_name === '') {
            this.setState({errorMsg: 'Last name field is required.'});
            return false;
        }

        if (this.state.card.email === '') {
            this.setState({errorMsg: 'Email field is required.'});
            return false;
        }

        if (this.state.card.company === '') {
            this.setState({errorMsg: 'Company name field is required.'});
            return false;
        }


        let card = this.state.card;

        this.setState({btnEnabled: false});


        let card_holder_name = this.state.card.first_name +' '+this.state.card.last_name;

        let stripe_response = await this.props.stripe.createToken({name: card_holder_name});


        if (stripe_response.error){

            //console.log(error.response.data);
            this.setState({errorMsg: stripe_response.error.message});
            this.setState({btnEnabled:true});


        }else{


            if (stripe_response.token){

                card.token = stripe_response.token;

                card.client = this.state.client;

                //let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

                let headers = { };

                axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/save-card', { card }, headers)
                    .then(res => {

                        if(res.data.success){

                            this.setState(
                                {
                                    card: {
                                        first_name:'',
                                        last_name: '',
                                        email: '',
                                        company: '',
                                        token: '',

                                    },

                                    msg: res.data.msg
                                }
                            );

                            this._element.clear();


                        }else{

                            this.setState({errorMsg: res.data.msg});

                        }

                        this.setState({btnEnabled: true});
                    })


                    .catch((error) => {

                        //console.log(error.response.data);
                        this.setState({errorMsg: error.response.data.message});
                        this.setState({btnEnabled:true});

                    });

            }else{

                this.setState({btnEnabled: true});
            }


        }


    }

    componentWillReceiveProps(nextProps) {


    }


    componentDidMount(){


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-info/' + this.props.client + '/' )
            .then(res => {


                let client = {
                    first_name          : res.data.client.first_name,
                    last_name           : res.data.client.last_name,
                    email               : res.data.client.email,
                    company             : res.data.client.company,
                    service_plan        : res.data.client.service_plan,
                    service_cost        : res.data.client.service_cost

                };

                this.setState({card:client})

            })

            .catch((error) => {


            });


        this.setState({client:this.props.client});

    }


    render() {

        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;

        return (

            <div>

                    {
                        (this.state.card.service_plan && this.state.card.service_plan)?
                            <div className="card-billing-service-plan">
                                {this.state.card.service_plan}
                                <span className="amount">{this.state.card.service_cost}</span>
                            </div>
                        :
                            <div></div>
                    }

                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="first_name" placeholder="First Name"  value={this.state.card.first_name} onChange={this.handleInputChange} className="form-control card-form-control"/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="last_name" placeholder="Last Name"  value={this.state.card.last_name} onChange={this.handleInputChange} className="form-control card-form-control"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="email" placeholder="Email Address" value={this.state.card.email} onChange={this.handleInputChange} className="form-control card-form-control"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="company" placeholder="Company Name"  value={this.state.card.company} onChange={this.handleInputChange} className="form-control card-form-control"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group card-field">
                                <CardElement onReady={(c) => this._element = c}/>
                            </div>
                        </div>

                    </div>

                    <div style={{marginTop:'20px'}}>
                        {errorMsg &&

                        <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: errorMsg}}></div>
                        }

                        {msg &&
                        <div className="alert alert-success" dangerouslySetInnerHTML={{__html: msg}}></div>
                        }
                    </div>

                    <div className="text-center">
                        <button disabled={!this.state.btnEnabled} onClick={this.handleSubmit} type="button" className="btn btn-primary"><span>Submit</span></button>
                    </div>

                    {
                        !this.state.btnEnabled &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>

                    }

            </div>

        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(ClientCardForm));
