import React, { Component } from "react";
import {StripeProvider, Elements} from 'react-stripe-elements';
import SubscribeForm from './subscribe-form';
import { connect } from "react-redux";


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}



class SubscribeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            plan_id: ''
        };

    }

    componentDidMount(){

        //this.setState({plan_id: this.props.plan_id});
    }

    render() {


        if (!this.props.plan_id){

            return null;
        }


        return (
            <StripeProvider apiKey={this.props.site_details.stripe_publishable_key}>
                <div className="modal fade" id="paymentModal" tabIndex={-1} role="dialog" aria-labelledby="paymentModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="paymentModalLabel">Payment Info</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <Elements>
                                    <SubscribeForm clientSubscribe={this.props.clientSubscribe} plan_id={this.props.plan_id} />
                                </Elements>
                            </div>

                        </div>
                    </div>
            </div>
            </StripeProvider>
        );
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(SubscribeModal);