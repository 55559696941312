import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setSiteData } from "../../redux-js/actions/index";

//import jQuery from 'jquery';
//jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class SingleSpreadsheet extends Component {

    constructor(props) {
        super(props);

        this.state = {

            errorMsg: '',
            msg: '',
            btnEnabled: true,

            spreadsheet: {

            },

            spreadsheet_fetched: false,
            current_user: {},

        };

    }


    getSpreadsheet(){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/spreadsheet/' + this.props.request_long_id + '/' )
            .then(res => {


                let spreadsheet = res.data.spreadsheet;


                if(spreadsheet) {



                    this.setState({
                        spreadsheet_fetched: true,
                        spreadsheet: spreadsheet,
                    });


                }

                else{

                    window.location.href = '/';
                    //this.props.history.push('/' );

                }

            })

            .catch((error) => {

                this.setState({
                    spreadsheet_fetched: true,
                })
            });




    }

    componentDidMount(){



        //this.props.setSiteData({client_slug: this.props.current_user.slug, page: 'project-scope'});

        this.getSpreadsheet();

    }


    componentWillUnmount(){


    }




    render() {



        return (
            <div className="content content-fixed">
                <div style={{'maxWidth':'1000px','margin':'0 auto'}}>

                    {
                        this.state.spreadsheet_fetched &&

                        (
                            <div>
                                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                    <div>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                                <li className="breadcrumb-item"><Link to={'/portal/spreadsheets/'+this.props.match.params.client_slug+'/'}>Spreadsheets</Link></li>

                                            </ol>
                                        </nav>


                                        <h4 className="mg-b-10 tx-spacing--1">Spreadsheets</h4>

                                    </div>
                                    <div className="d-none d-md-block">

                                    </div>
                                </div>

                                <div className="">
                                    <div className="">
                                        <div className="card card-body">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )

                    }
                </div>
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleSpreadsheet);