export function addWorkRequest(payload) {

    return {
        type: "ADD_WORK_REQUEST", payload
    }

};


export function addComment(payload) {

    return {
        type: "ADD_COMMENT", payload
    }

};


export function setWorkRequestSlug(payload) {

    return {
        type: "SET_WORK_REQUEST_SLUG", payload
    }

};


export function setProjectBookSlug(payload) {

    return {
        type: "SET_PROJECT_BOOK_SLUG", payload
    }

};

export function setProjectBookPagePages(payload) {

    return {
        type: "SET_PROJECT_BOOK_PAGE_PAGES", payload
    }

};

export function setProjectBookInfo(payload) {

    return {
        type: "SET_PROJECT_BOOK_INFO", payload
    }

};


export function setProjectBookCurrentPage(payload) {

    return {
        type: "SET_PROJECT_BOOK_CURRENT_PAGE", payload
    }

};

export function setPaymentMade(payload) {

    return {
        type: "SET_PAYMENT_MADE", payload
    }

};



export function setCurrentUser(payload) {

    return {
        type: "SET_CURRENT_USER", payload
    }

};


export function setSiteDetails(payload) {

    return {
        type: "SET_SITE_DETAILS", payload
    }

};



export function cacheContent(payload) {

    return {
        type: "CACHE_CONTENT", payload
    }

};




export function setSiteData(payload) {

    return {
        type: "SET_SITE_DATA", payload
    }

};
