import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import SweetAlert from 'sweetalert-react';
import { setProjectBookPagePages } from "../../../redux-js/actions/index";
import { makeKey, authHeader, makeSlug, permissionCan } from '../../helpers/helpers'
import logo from '../../../assets/images/d6-support-logo.png';

//import arrayMove from 'array-move';

//import Tree from './Tree';
import jQuery from 'jquery';
jQuery = window.$j;





const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setProjectBookPagePages: project_book_page_pages => dispatch(setProjectBookPagePages(project_book_page_pages)),
    };
}


class ProjectBookSidebar extends Component {

    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {

            icons:[],
            sidebar_pages: {},
            current_page: {},
            open_pages:[],
            show_icon_selector: false,
            is_administrator: false,
            sub_pages:{},
            sub_pages_user_roles:[],

        }

        this.addPage                        = this.addPage.bind(this);
        this.handleSortSidebarItems         = this.handleSortSidebarItems.bind(this);
        this.doSortableSidebarItems         = this.doSortableSidebarItems.bind(this);


    }



    collapseMenu(page_key){


        setTimeout(function () {

            if (jQuery('.sidebar-menu').find('a span[data-key='+page_key+']').closest('ul.list-lvl2').length){
                //show submenu items
                jQuery('.sidebar-menu').find('a span[data-key='+page_key+']').closest('li').addClass('show-items');
                jQuery('.sidebar-menu').find('a span[data-key='+page_key+']').closest('li').find('>ul').css({display:'block'});
            }

            jQuery('.sidebar-menu').find('a span[data-key='+page_key+']').parents('ul.list-lvl2,ul.list-lvl3').each(function () {

                jQuery('.project-book-sidebar #item-'+jQuery(this).data('id')).addClass('show-items');
                jQuery('.project-book-sidebar #item-'+jQuery(this).data('id')+'>ul').css({display:'block'});

            });


            let page_id = jQuery('.sidebar-menu').find('a span[data-key='+page_key+']').closest('ul.list-lvl2').data('id');

            if (!page_id){
                page_id = jQuery('.sidebar-menu').find('a span[data-key='+page_key+']').closest('li').data('id');
            }


            jQuery('.project-book-sidebar #item-'+page_id).addClass('show-items');
            jQuery('.project-book-sidebar #item-'+page_id+'>ul').css({display:'block'});


        }, 1);

    }

    doMenuUpdate(page, action, page_section){


        let page_key    = page.key;
        let page_index  = page.index;
        let page_id     = page.id;

        if (action === 'rename'){
            window.is_rename_sidebar_page = true;
            jQuery(".project-book-sidebar ul a span[data-key='" + page_key + "']").addClass('editable').attr("contenteditable",true).focus();
            document.execCommand('selectAll',false,null)
        }

        else if (action === 'new-section'){

            this.addPage({
                index: page_index, parent_id: page_id, title: 'New Page', page_section: page_section
            });

        }


        else if (action === 'delete'){

            this.deletePage(page_key, page_section);
        }

        else if (action === 'delete-confirm'){

            this.confirmDeletePage(page_key, page_section);
        }

    }

    confirmDeletePage(page_key, page_section){

        this.setState({
            page_key_to_delete: page_key,
            page_page_section_to_delete: page_section,
            show_confirm_delete_popup: true
        })

    }

    deletePage(page_key, page_section){





        let all_pages           = JSON.parse(JSON.stringify(this.state.sidebar_pages));//clone
        let all_pages_new       = all_pages;
        let found               = false;
        let prev_page_key       = '';
        let prev_page_slug      = '';
        let is_current_page     = this.state.current_page.key ===  page_key;


        for (let key in all_pages_new[page_section]) {

            if (all_pages_new[page_section].hasOwnProperty(key)) {


                if (all_pages_new[page_section][key].key === page_key){


                    found = true;
                    //FOUND
                    all_pages_new[page_section].splice(key, 1);
                    break;

                }

                else{

                    prev_page_key = all_pages_new[page_section][key].key;
                    prev_page_slug = all_pages_new[page_section][key].slug;
                }


                if (all_pages_new[page_section][key].children.length){

                    for (let x in all_pages_new[page_section][key].children) {

                        if (page_key === all_pages_new[page_section][key].children[x].key ){


                            //FOUND
                            found = true;
                            all_pages_new[page_section][key].children.splice(x, 1);
                            break
                        }

                        else{

                            prev_page_key = all_pages_new[page_section][key].children[x].key;
                            prev_page_slug = all_pages_new[page_section][key].children[x].slug;
                        }


                        if (all_pages_new[page_section][key].children[x].children.length){

                            for (let y in all_pages_new[page_section][key].children[x].children) {

                                if (page_key === all_pages_new[page_section][key].children[x].children[y].key ){


                                    //FOUND
                                    found = true;
                                    all_pages_new[page_section][key].children[x].children.splice(y, 1);
                                    break
                                }

                                else{

                                    prev_page_key = all_pages_new[page_section][key].children[x].children[y].key;
                                    prev_page_slug = all_pages_new[page_section][key].children[x].children[y].slug;
                                }


                                if (all_pages_new[page_section][key].children[x].children[y].children.length){

                                    for (let z in all_pages_new[page_section][key].children[x].children[y].children) {

                                        if (page_key === all_pages_new[page_section][key].children[x].children[y].children[z].key ){

                                            //FOUND
                                            found = true;
                                            all_pages_new[page_section][key].children[x].children[y].children.splice(z, 1);
                                            break
                                        }

                                        else{

                                            prev_page_key = all_pages_new[page_section][key].children[x].children[y].children[z].key;
                                            prev_page_slug = all_pages_new[page_section][key].children[x].children[y].children[z].slug;
                                        }

                                    }

                                    if (found){
                                        break;
                                    }

                                }


                            }

                            if (found){
                                break;
                            }

                        }

                    }

                    if (found){
                        break;
                    }

                }

            }

            if (found){
                break;
            }

        }





        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/delete-page', { key: page_key }, authHeader())

            .then(res => {


                if (!this._isMounted) return; //avoid memory leak


                if (res.data.success){


                    if (is_current_page){
                        //redirect if this is the current/open page

                        if(prev_page_key){
                            this.props.history.push('/portal/pages/'+ this.props.match.params.project_book_slug +'/'+ this.props.page +'/'+page_section+'/'+prev_page_key+'/' + prev_page_slug + '/');
                        }else{
                            this.props.history.push('/portal/pages/'+ this.props.match.params.project_book_slug +'/'+ this.props.page +'/'+page_section+'/');
                        }
                    }


                    this.props.setProjectBookPagePages(all_pages_new);

                    this.setState({
                        sidebar_pages: all_pages_new
                    });



                }else{


                    // //reset
                    // this.props.setProjectBookPagePages(all_pages);
                    //
                    // this.setState({
                    //     sidebar_pages: all_pages
                    // });

                    alert(res.data.msg);
                }

            })

            .catch((error) => {


            });


    }

    scripts(){

        let ds = this;
        let menu_item_text = '';

        jQuery('.project-book-sidebar ul a span.editable').off('click');
        jQuery(document).on('click', '.project-book-sidebar ul a span.editable', function(e) {

            e.stopPropagation();
        });


        jQuery('.project-book-sidebar ul a span.page-icon').off('click');
        jQuery(document).on('click', '.project-book-sidebar ul a span.page-icon', function(e) {

            e.stopPropagation();

            return false;

        });


        jQuery('.project-book-sidebar ul a').off('dblclick');
        jQuery(document).on('dblclick', '.project-book-sidebar ul a', function(e) {


            if (true){
                return false; //we now only allowed to rename the page title by clicking "..."
            }


            if (jQuery(this).hasClass('read-only') || jQuery(this).find('span.title').eq(0).hasClass('editable')){

                return false;
            }



            (function (ds) {

                setTimeout(function () {

                    window.is_rename_sidebar_page = true;

                    menu_item_text = jQuery(ds).find('span.title').eq(0).text();
                    jQuery(ds).find('span.title').eq(0).addClass('editable').attr("contenteditable",true).focus();

                    document.execCommand('selectAll',false,null);
                    jQuery( '.list-sortable' ).nestedSortable( 'destroy' );


                },1);

            })(this);

            //let page_key = jQuery(this).data('key');
            //jQuery(".project-book-sidebar ul a span[data-key='" + page_key + "']").addClass('editable').attr("contenteditable",true).focus();


            return false;
        });



        function blurKeyDownEvent($that) {


            ds.doSortableSidebarItems();
            window.is_rename_sidebar_page = false;
            $that.removeAttr("contenteditable").removeClass('editable');
            window.getSelection().removeAllRanges();

            if ($that.text() === ''){
                $that.text('Section Title')
            }


            if (menu_item_text !== $that.text()){


                //console.log(menu_item_text +' !== '+ $that.text());

                //this works for pages only but not folders because folders cannot be selected or become active
                //update if title changed

                let slug = makeSlug($that.text());

                ds.props.updatePage({key:$that.data('key'),title: $that.text(),slug: slug});

            }

            if (window.tiny_editor){
                window.tiny_editor.setMode(window.editor_mode);
                window.tiny_editor.focus();
            }

        }


        jQuery(document).on('keydown', '.project-book-sidebar ul a span.title', function(event){

            let keycode = (event.keyCode ? event.keyCode : event.which);

            if(keycode === 13){

                blurKeyDownEvent(jQuery(this));
            }


        });



        jQuery('.project-book-sidebar ul a span.title').off('blur');
        jQuery(document).on('blur', '.project-book-sidebar ul a span.title', function(e) {

            blurKeyDownEvent(jQuery(this));

            //console.log(jQuery(this).text());
            return false;
        });


        jQuery(document).on('keypress','.project-book-sidebar ul a span.title',function (e) {

            let key = e.which;

            if (key === 13) {

                window.is_rename_sidebar_page = false;
                jQuery(this).removeAttr("contenteditable").removeClass('editable');
                return false;

            }
        });


        jQuery(document).on('click','.project-book-sidebar .action-menu',function(){
            jQuery(this).removeClass('show');
        });

        jQuery(document).on('click','html, body, iframe, .project-book-content-inner', function () {


            if (jQuery('.project-book-sidebar ul li .editable').length){

                jQuery('.project-book-sidebar ul li .editable').removeClass('editable');
            }


            jQuery('.project-book-sidebar .action-menu').removeClass('show');

        });

        //jQuery(document).on('mouseover','iframe', function () {
        //jQuery('.project-book-sidebar .action-menu').removeClass('show');
        //});


        jQuery(document).keyup(function(e) {

            if (e.key === "Escape") { // escape key maps to keycode `27`
                window.is_rename_sidebar_page = false;
                jQuery('.project-book-sidebar .action-menu').removeClass('show');
                jQuery('.project-book-sidebar ul a span').removeAttr("contenteditable").removeClass('editable');
            }

        });

    }

    componentWillUnmount(){

        jQuery('body').removeClass('with-aside');

        this._isMounted = false;


    }

    componentDidMount(){

        this._isMounted = true;




        this.scripts();


        this.setSidebarHeightOnResize();


        let sub_pages_user_roles = {};

        for (var x in this.props.project_book_info.sub_pages) {

            if (this.props.project_book_info.sub_pages.hasOwnProperty(x)) {

                for (var y in this.props.project_book_info.sub_pages[x]) {
                    if (this.props.project_book_info.sub_pages[x].hasOwnProperty(y)) {

                        //console.log(this.props.project_book_info.sub_pages[x][y]);

                        sub_pages_user_roles[this.props.project_book_info.sub_pages[x][y]['slug']] = this.props.project_book_info.sub_pages[x][y]['user_roles'];

                    }
                }

            }
        }


        this.setState({
            sidebar_pages       : this.props.sidebar_pages,
            current_page        : this.props.current_page,
            open_pages          : this.props.open_pages,
            is_administrator    : this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff',
            sub_pages           : this.props.project_book_info.sub_pages,
            other_portals       : this.props.project_book_info.other_portals,
            sub_pages_user_roles: sub_pages_user_roles,
        });




        //console.log(this.props.project_book_info.sub_pages);
        //console.log(this.state.sub_pages);


        this.collapseMenu(this.props.match.params.page_slug);

        this.getIcons();



        jQuery('body').addClass('with-aside');

        setTimeout(function () {

            jQuery('.project-book-sidebar .actions').css({display: 'block'});

        },500);

    }


    componentWillReceiveProps(nextProps) {


        //console.log('',nextProps.sidebar_pages);

        //this.collapseMenu(nextProps);
        //this.setState({force_reload: Math.random()})//force reload this component

        this.setState({
            sidebar_pages: nextProps.sidebar_pages,
            current_page: nextProps.current_page,
            open_pages: nextProps.open_pages,
        });

        setTimeout(function () {

            jQuery('.project-book-sidebar .actions').css({display: 'block'});
        },500);

        //this.collapseMenu(nextProps.match.params.page_slug);

    }


    getIcons(){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/pages-icons', authHeader())

            .then(res => {


                if (!this._isMounted) return; //avoid memory leak

                this.setState({icons: res.data.icons})

            })

            .catch((error) => {


            });

    }

    setSidebarHeightOnResize(){

        //let that = this;

        //jQuery(window).resize(function () {

            //that.setSidebarHeight();

        //});

    }


    setSidebarHeight(){



        setTimeout(function () {


            if (jQuery('.project-book-sidebar-inner').length) {
                let sheight = jQuery(window).height() - jQuery('.project-book-sidebar-inner').offset().top;
                jQuery('.project-book-sidebar-inner').css({height: sheight - 50});
            }


        },1);


    }


    showActionMenu(id){

        jQuery('.project-book-sidebar .action-menu').removeClass('show');
        jQuery('#action-menu-'+id).addClass('show');
    }

    toggleSubMenu(page_id, animate, from){


        if(from === 'title' && jQuery('.project-book-sidebar #item-'+page_id).hasClass('show-items')){

            return ;
        }

        if (animate){

            jQuery('.project-book-sidebar #item-'+page_id).toggleClass('show-items');
            jQuery('.project-book-sidebar #item-'+page_id+'>ul').slideToggle('fast');
            //jQuery('.project-book-sidebar #sub-menu-'+page_id).slideToggle('fast');

        }else{

            jQuery('.project-book-sidebar #item-'+page_id).addClass('show-items');
            jQuery('.project-book-sidebar #item-'+page_id+'>ul').css({display:'block'});
            //jQuery('.project-book-sidebar #sub-menu-'+page_id).css({display:'block'});
        }
    }


    handleSortSidebarItems(pages){


        let open_pages = [];

        jQuery('.project-book-sidebar li.show-items').each(function () {
            if (jQuery(this).data('id')){
                open_pages.push(jQuery(this).data('id'));
            }
        });


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/update-pages-order', { pages: pages, project_book_slug: this.props.match.params.project_book_slug }, authHeader())
            .then(res => {

                if (!this._isMounted) return; //avoid memory leak

                //this.props.setProjectBookPagePages(res.data.pages);


                //this.collapseMenu(this.props.match.params.page_slug);

                this.props.updateProjectBookPages(res.data.pages, open_pages);



                //this.setState({sidebar_pages: []}, function () {
                    //this.setState({sidebar_pages: res.data.pages});
                //});

                //this.collapseMenu(this.props.match.params.page_slug);

            })

            .catch((error) => {

            });

    }


    addReportPage(){

        let title           = this.getDateTitle();
        let slug            = makeSlug(title);
        let page_section    = this.props.match.params.page_section;

        var page = {
            title               : title,
            key                 : makeKey(12),
            content             : '',
            id                  : 0,
            slug                : slug,
            parent              : 0,
            children            : [],
            section             : page_section,
            project_book_slug   : this.props.match.params.project_book_slug
        };


        var pages = this.props.updatePagesArray(page, 'insert', page_section);

        this.setState({sidebar_pages: pages});



        let that = this;
        setTimeout(function () {
            that.toggleSubMenu(0, false, 'add-page');
        },100);



        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/add-page', { page }, authHeader())

            .then(res => {

                if (!this._isMounted) return; //avoid memory leak


                this.props.updatePagesArray(res.data.page, 'title', page_section);
                this.props.history.push('/portal/pages/'+ this.props.match.params.project_book_slug +'/'+ this.props.page +'/'+ page_section + '/' + page.key + '/'+ res.data.page.slug + '/');

                //window.location.href = '/portal/pages/'+ this.props.match.params.project_book_slug +'/'+ this.props.page +'/'+ page_section + '/' + page.key + '/'+ res.data.page.slug + '/';

            })

            .catch((error) => {


            });


    }

    addPage(data){

        //data -->> index, parent_id, title, page_section

        //debugger;


        if (data.parent_id === this.state.current_page.id && this.state.current_page.level === 4){

            //we cannot add sub section under level 3

            data.parent_id = this.state.current_page.parent;

        }


        let title = data.title || 'New Page';


        //let add_to_folder_index = jQuery('.project-book-sidebar a.is-active').closest('ul.root').data('index');
        //let parent              = jQuery('.project-book-sidebar a.is-active').closest('ul').data('id');
        //let parent = this.state.current_page.parent;


        if (!isNaN(data.index)){
            //add_to_folder_index = index;
        }

        if (!isNaN(data.parent_id)){
            //parent = data.parent_id;
        }


        let slug = data.slug ? data.slug : makeSlug(title);

        let page = {
            title       : title,
            key         : makeKey(12),
            content     : '',
            id          : 0,
            slug        : slug,
            parent      : data.parent_id,
            children    : [],
            template    : this.state.is_administrator ? '' : 'wysiwyg-template'
        };

        let pages = this.props.updatePagesArray(page, 'insert', data.page_section);


        this.setState({sidebar_pages: pages});

        page.project_book_slug  = this.props.match.params.project_book_slug;
        //page.section            = this.props.match.params.page_section;
        page.section            = data.page_section;
        //page.order              = num_sections + 1;


        let that = this;
        setTimeout(function () {
            that.toggleSubMenu(data.parent_id, false, 'add-page');
        },100);



        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/add-page', { page }, authHeader())

            .then(res => {

                if (!this._isMounted) return; //avoid memory leak


                this.props.updatePagesArray(res.data.page, 'title', data.page_section);



                this.props.history.push('/portal/pages/' + this.props.match.params.project_book_slug + '/' + this.props.page + '/' + data.page_section + '/' + page.key + '/' + res.data.page.slug + '/');


                setTimeout(function () {
                    window.is_rename_sidebar_page = true;
                    jQuery(".project-book-sidebar ul a span[data-key='" + page.key + "']").addClass('editable').attr("contenteditable", true).focus();
                    document.execCommand('selectAll', false, null)
                }, 100);

                //let pages = this.props.updatePagesArray(res.data.page, 'insert', data.page_section);
                //this.setState({sidebar_pages: pages});


            })

            .catch((error) => {


            });

    }



    doSortableSidebarItems(){

        let that = this;


        if (typeof(jQuery('.list-sortable').nestedSortable) !== "function"){
            return;
        }

        jQuery('.list-sortable').nestedSortable({
            handle: 'div.item-wrap',
            listType:'ul',
            items: 'li',
            toleranceElement: '> div.item-wrap',
            protectRoot: false,
            disableParentChange: false,
            //isTree: false,
            maxLevels: 4,
            distance: 10,


            update: function( event, ui ) {

                jQuery('.list-sortable ul').each(function () {

                    /*
                     * remove empty OL
                     */
                    if(jQuery(this).find('li').length <=  0){
                        jQuery(this).remove();
                    }

                });


                /*
                 * collapse the parent
                 */
                ui.item.closest('ul').show('fast');
                ui.item.closest('ul').closest('li').addClass('show-items');
                ui.item.closest('ul').closest('li').closest('ul').show('fast');
                ui.item.closest('ul').closest('li').closest('ul').closest('li').addClass('show-items');


                //jQuery('.project-book-sidebar #item-'+page_id).toggleClass('show-items');
                //jQuery('.project-book-sidebar #sub-menu-'+page_id).slideToggle('fast');

                let pages = [];
                let x = 0;
                let current_page_new_parent = 0;
                let current_page_new_level  = 1;


                jQuery('.sidebar-menu>ul>li').each(function () {

                    if(that.state.current_page.id === jQuery(this).data('id')){
                        current_page_new_level  = 1;
                        current_page_new_parent = 0;
                    }

                    pages.push({
                        id: jQuery(this).data('id'),
                        order: x,
                        parent: 0,
                    });


                    let y = 0;

                    jQuery(this).find('>ul>li').each(function () {

                        if(that.state.current_page.id === jQuery(this).data('id')){
                            current_page_new_level  = 2;
                            current_page_new_parent = jQuery(this).closest('ul').closest('li').data('id');
                        }

                        pages.push({
                            id: jQuery(this).data('id'),
                            order: y,
                            parent: jQuery(this).closest('ul').closest('li').data('id'),
                        });

                        let z = 0;

                        jQuery(this).find('>ul>li').each(function () {


                            if(that.state.current_page.id === jQuery(this).data('id')){
                                current_page_new_level  = 3;
                                current_page_new_parent = jQuery(this).closest('ul').closest('li').data('id');
                            }

                            pages.push({
                                id: jQuery(this).data('id'),
                                order: z,
                                parent: jQuery(this).closest('ul').closest('li').data('id'),
                            });


                            let t = 0;

                            jQuery(this).find('>ul>li').each(function () {


                                if(that.state.current_page.id === jQuery(this).data('id')){
                                    current_page_new_level  = 4;
                                    current_page_new_parent = jQuery(this).closest('ul').closest('li').data('id');
                                }

                                pages.push({
                                    id: jQuery(this).data('id'),
                                    order: t,
                                    parent: jQuery(this).closest('ul').closest('li').data('id'),
                                });

                                t++;
                            });


                            z++;
                        });

                        y++;
                    });

                    x++;
                });


                let current_page = that.state.current_page;
                current_page.parent = current_page_new_parent;
                current_page.level = current_page_new_level;

                that.setState({current_page: current_page});

                //console.log(pages);


                that.handleSortSidebarItems(pages);

            }
        });



    }

    objectSize(obj){

        let size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }

        return size;

    }


    changeIcon(id, key, page_section){

        this.setState({
            page_to_update_icon: {
                id: id,
                key: key,
                page_section: page_section
            },
            show_icon_selector: true
        });

        if((jQuery(window).height() - jQuery('#item-' +id).offset().top) < jQuery('.icons-selector').outerHeight()){

            //show icon selector at the top
            jQuery('.icons-selector').css({top: jQuery('#item-' +id).offset().top - jQuery('.icons-selector').outerHeight() - 60});

        }

        else{

            //show icon selector at the bottom
            jQuery('.icons-selector').css({top: jQuery('#item-' +id).offset().top - 30});
        }


        return false;
    }

    setPageIcon(icon_url){

        let page = {
            id: this.state.page_to_update_icon.id,
            key: this.state.page_to_update_icon.key,
            icon: icon_url,
        };

        this.setState({show_icon_selector: false});


        let pages = this.props.updatePagesArray(page, 'icon', this.state.page_to_update_icon.page_section);

        this.setState({sidebar_pages:pages});


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/update-page-icon', { page }, authHeader())

            .then(res => {

                if (!this._isMounted) return; //avoid memory leak

            })

            .catch((error) => {


            });
    }



    showMenuItemAction(page_slug){

        if (this.state.is_administrator){

        }


        return true;

    }


    getDateTitle(){


        var d = new Date();
        var month = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        let m = d.getMonth();

        return month[m] + ' ' + d.getFullYear();

    }

    render() {

        let that = this;

        if( !this.objectSize(this.state.sidebar_pages) ){

            return null;
        }


        setTimeout(function () {
            //that.setSidebarHeight();
            that.doSortableSidebarItems();
        },1);



        if (window.runAsideScripts){
            setTimeout(function () {
                window.runAsideScripts();
            },10)
        }





        var dashboard_url = '/';

        if(this.props.project_book_info && this.props.project_book_info.client_slug){

            dashboard_url = '/portal/dashboard/' + ( this.props.project_book_info.client_slug ) + '/';
        }

        return (


        <aside className={'aside aside-fixed' + (localStorage.getItem('minimized-aside')==='true'?' minimize':'')}>
            <div className="aside-header">
                <NavLink to={dashboard_url}  className="aside-logo">
                    <img src={logo} alt="" />
                </NavLink>
                <a href="#menu" className="aside-menu-link">
                    <i data-feather="menu"></i>
                    <i data-feather="x"></i>
                </a>
            </div>
            <div className="aside-body">

                <div className="project-book-sidebar">


                    {
                        ( this.props.match.params.page_section === 'page' || this.props.match.params.page_section === 'admin-portal') && this.state.other_portals.length > 0 &&

                        <div className="project-dropdown">
                            <div className="project-dropdown-lbl">Projects</div>
                            <div className="current-project"><span>{this.props.project_book_info.title}</span></div>
                            <ul>
                                {this.state.other_portals.map((portal,i) =>

                                    <li key={i}><NavLink to={portal.url}><img src={process.env.REACT_APP_URL + '/wp-content/themes/d6support/portal/icons/folder3.png'} alt="" /> <span>{portal.title}</span></NavLink></li>
                                )}
                            </ul>
                        </div>
                    }


                    <div className="project-book-sidebar-inner">


                    <div className={'icons-selector ' + ( (this.state.show_icon_selector)?' show':'')}>
                        <span className="sel-close" onClick={()=>this.setState({show_icon_selector:false})}>&times;</span>
                        <div className="icons-selector-inner">
                            <ul>
                                {this.state.icons.map((icon,i) =>
                                    <li key={i} onClick={()=>this.setPageIcon(icon.url)}><img src={icon.url} alt=""/></li>
                                )}
                            </ul>
                        </div>
                    </div>


                    <ul id="sidebar-pages">
                    {
                        this.state.sub_pages[this.props.page] && this.state.sub_pages[this.props.page].map((pppp,z) =>
                            <li className="sub-page-item" key={z}>
                                <div className="sub-page-title">

                                    {/*<span className="section-title" data-key={pppp.key}>{ pppp.title }</span>*/}

                                    {
                                        false && this.showMenuItemAction(pppp.slug) &&
                                        <span className="action" onClick={()=>this.addPage({index: 0, parent_id: 0, title: 'New Page', page_section: pppp.slug})} title="Add Page"><i className="fas fa-plus"></i></span>
                                    }
                                </div>

                                    <div className="sidebar-menu">


                                        <ul className={'root list-sortable connected-sortable list-lvl1'} data-id="0">


                                            {
                                                this.state.sidebar_pages[pppp.slug] && this.state.sidebar_pages[pppp.slug].map((page,i) =>



                                                <li key={page.id} className={ (page.id ? '':'no-id-yet') + ' ' + (this.state.open_pages.includes(parseInt(page.id))?'show-items':'') + ' lvl1'} id={'item-'+page.id} data-id={page.id}>


                                                    <div className="item-wrap">
                                                        <div className="text-icon">

                                                            {
                                                                (page.children.length > 0) &&
                                                                <span className="toggle-items" onClick={()=>{this.toggleSubMenu(page.id, true , 'arrow')}}></span>
                                                            }


                                                            <NavLink className={!permissionCan('edit', this.props.current_user.role, page.permissions)?'read-only image-icon':'image-icon'}  activeClassName='is-active' exact={true} to={'/portal/pages/'+this.props.match.params.project_book_slug+'/'+ this.props.page +'/'+ pppp.slug +'/'+ page.key + '/' + page.slug + '/'}>

                                                                <span className="page-icon" onClick={()=>{this.changeIcon(page.id, page.key, pppp.slug)}}>
                                                                    {
                                                                        (page.icon) &&
                                                                        <img src={page.icon} alt=""/>
                                                                    }

                                                                    {
                                                                        (!page.icon && this.props.page !== 'seo-reports') &&
                                                                        <img src={process.env.REACT_APP_URL + '/wp-content/themes/d6support/portal/icons/folder.png'} alt=""/>
                                                                    }

                                                                    {
                                                                        (!page.icon && this.props.page === 'seo-reports') &&
                                                                        <img src={process.env.REACT_APP_URL + '/wp-content/themes/d6support/portal/icons/folder1.png'} alt=""/>
                                                                    }

                                                                </span>

                                                                <span className="title" data-key={page.key} onClick={()=>{this.toggleSubMenu(page.id, true, 'title')}}><span className="title-in">{page.title}</span></span>

                                                            </NavLink>

                                                            {
                                                                this.showMenuItemAction(pppp.slug) &&

                                                                <span className="action" onClick={()=>{this.showActionMenu(page.id)}}><i className="fas fa-ellipsis-h"></i></span>
                                                            }

                                                        </div>

                                                        {
                                                            this.showMenuItemAction(pppp.slug) &&

                                                            <div className="action-menu" id={'action-menu-' + page.id}>

                                                                {
                                                                    permissionCan('edit', this.props.current_user.role, page.permissions) &&
                                                                    <div className="item" data-key={page.key} data-id={page.id} data-index={i} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:i},'rename', pppp.slug)} data-action="rename"><i className="fas fa-pencil-alt"></i> Rename</div>
                                                                }

                                                                <div className="item" data-key={page.key} data-id={page.id} data-index={i} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:i},'new-section', pppp.slug)} data-action="new-section"><i className="fas fa-file"></i> New Page</div>

                                                                {
                                                                    (page.children.length <= 0 && this.state.sidebar_pages[pppp.slug].length > 1) &&
                                                                    permissionCan('delete', this.props.current_user.role, page.permissions) &&
                                                                    <div className="item" data-key={page.key} data-id={page.id} data-index={i} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:i},'delete-confirm', pppp.slug)} data-action="delete"><i className="fas fa-trash-alt"></i> Delete</div>
                                                                }

                                                            </div>
                                                        }

                                                    </div>




                                                    {
                                                        (page.children.length)
                                                            ?
                                                            <ul className="list-lvl2" id={'sub-menu-'+page.id}  data-id={page.id} style={(this.state.open_pages.includes(parseInt(page.id))?{display:'block'}:{})}>
                                                                {page.children.map((page,x) =>
                                                                    <li className={(page.id?'':'no-id-yet')} key={page.id} data-key={x} data-id={page.id} id={'item-'+page.id}>


                                                                        <div className="item-wrap">
                                                                            <div className="text-icon">

                                                                                {
                                                                                    this.showMenuItemAction(pppp.slug) &&
                                                                                    <span className="action" onClick={()=>{this.showActionMenu(page.id)}}><i className="fas fa-ellipsis-h"></i></span>
                                                                                }

                                                                                {
                                                                                    this.showMenuItemAction(pppp.slug) &&

                                                                                    <div className="action-menu" id={'action-menu-' + page.id}>

                                                                                        {
                                                                                            permissionCan('edit', this.props.current_user.role, page.permissions) &&
                                                                                            <div className="item" data-key={page.key} data-id={page.id} data-index={x} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:x},'rename', pppp.slug)} data-action="rename"><i className="fas fa-pencil-alt"></i> Rename</div>
                                                                                        }

                                                                                        <div className="item" data-key={page.key} data-id={page.id} data-index={i} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:i},'new-section', pppp.slug)} data-action="new-section"><i className="fas fa-file"></i> New Page</div>

                                                                                        {
                                                                                            (page.children.length <= 0 ) &&
                                                                                            permissionCan('delete', this.props.current_user.role, page.permissions) &&
                                                                                            <div className="item" data-key={page.key} data-id={page.id} data-index={x} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:x},'delete-confirm', pppp.slug)} data-action="delete"><i className="fas fa-trash-alt"></i> Delete</div>
                                                                                        }

                                                                                    </div>
                                                                                }


                                                                                {
                                                                                    (page.children.length > 0) &&
                                                                                    <span className="toggle-items" onClick={()=>{this.toggleSubMenu(page.id, true , 'arrow')}}></span>
                                                                                }

                                                                                <NavLink className={!permissionCan('edit', this.props.current_user.role, page.permissions)?'read-only image-icon':'image-icon'} activeClassName='is-active' exact={true} to={'/portal/pages/'+this.props.match.params.project_book_slug+'/'+ this.props.page +'/'+ pppp.slug +'/'+ page.key + '/' + page.slug + '/'}>

                                                                                    <span className="page-icon" onClick={()=>{this.changeIcon(page.id, page.key, pppp.slug)}}>
                                                                                        {
                                                                                            (page.icon) &&
                                                                                            <img src={page.icon} alt=""/>
                                                                                        }

                                                                                        {
                                                                                            (!page.icon) &&
                                                                                            <img src={process.env.REACT_APP_URL + '/wp-content/themes/d6support/portal/icons/disabled.png'} alt=""/>
                                                                                        }

                                                                                    </span>

                                                                                    <span className="title" onClick={()=>{this.toggleSubMenu(page.id, true, 'title')}} data-key={page.key}><span className="title-in">{page.title}</span></span>
                                                                                </NavLink>

                                                                            </div>
                                                                        </div>



                                                                        {
                                                                            (page.children.length)
                                                                                ?
                                                                                <ul className="list-lvl3" data-id={page.id}>
                                                                                    {page.children.map((page,y) =>
                                                                                        <li className={(page.id?'':'no-id-yet')} key={page.id} data-id={page.id} id={'item-'+page.id}>
                                                                                            <div className="item-wrap">
                                                                                                <div className="text-icon">

                                                                                                    {
                                                                                                        this.showMenuItemAction(pppp.slug) &&
                                                                                                        <span className="action" onClick={()=>{this.showActionMenu(page.id)}}><i className="fas fa-ellipsis-h"></i></span>
                                                                                                    }

                                                                                                    {
                                                                                                        this.showMenuItemAction(pppp.slug) &&

                                                                                                        <div className="action-menu" id={'action-menu-' + page.id}>

                                                                                                            {
                                                                                                                permissionCan('edit', this.props.current_user.role, page.permissions) &&
                                                                                                                <div className="item" data-key={page.key} data-id={page.id} data-index={y} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:y},'rename', pppp.slug)} data-action="rename"><i className="fas fa-pencil-alt"></i> Rename</div>
                                                                                                            }

                                                                                                            <div className="item" data-key={page.key} data-id={page.id} data-index={i} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:i},'new-section', pppp.slug)} data-action="new-section"><i className="fas fa-file"></i> New Page</div>

                                                                                                            {
                                                                                                                (page.children.length <= 0 ) &&
                                                                                                                permissionCan('delete', this.props.current_user.role, page.permissions) &&
                                                                                                                <div className="item" data-key={page.key} data-id={page.id} data-index={y} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:y},'delete-confirm', pppp.slug)} data-action="delete"><i className="fas fa-trash-alt"></i> Delete</div>
                                                                                                            }

                                                                                                        </div>
                                                                                                    }


                                                                                                    <NavLink className={!permissionCan('edit', this.props.current_user.role, page.permissions)?'read-only image-icon':' image-icon'} activeClassName='is-active' exact={true} to={'/portal/pages/'+this.props.match.params.project_book_slug+'/'+ this.props.page +'/'+ pppp.slug +'/'+ page.key + '/' + page.slug + '/'}>

                                                                                                        <span className="page-icon" onClick={()=>{this.changeIcon(page.id, page.key, pppp.slug)}}>
                                                                                                            {
                                                                                                                (page.icon) &&
                                                                                                                <img src={page.icon} alt=""/>
                                                                                                            }

                                                                                                            {
                                                                                                                (!page.icon) &&
                                                                                                                <img src={process.env.REACT_APP_URL + '/wp-content/themes/d6support/portal/icons/disabled.png'} alt=""/>
                                                                                                            }

                                                                                                        </span>

                                                                                                        <span className="title" data-key={page.key}><span className="title-in">{page.title}</span></span>
                                                                                                    </NavLink>
                                                                                                </div>
                                                                                            </div>


                                                                                            {
                                                                                                (page.children.length)
                                                                                                    ?
                                                                                                    <ul className="list-lvl4" data-id={page.id}>
                                                                                                        {page.children.map((page,z) =>
                                                                                                                <li className={(page.id?'':'no-id-yet')} key={page.id} data-id={page.id} id={'item-'+page.id}>
                                                                                                                    <div className="item-wrap">
                                                                                                                        <div className="text-icon">

                                                                                                                            {
                                                                                                                                this.showMenuItemAction(pppp.slug) &&
                                                                                                                                <span className="action" onClick={()=>{this.showActionMenu(page.id)}}><i className="fas fa-ellipsis-h"></i></span>
                                                                                                                            }

                                                                                                                            {
                                                                                                                                this.showMenuItemAction(pppp.slug) &&

                                                                                                                                <div className="action-menu" id={'action-menu-' + page.id}>

                                                                                                                                    {
                                                                                                                                        permissionCan('edit', this.props.current_user.role, page.permissions) &&
                                                                                                                                        <div className="item" data-key={page.key} data-id={page.id} data-index={y} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:z},'rename', pppp.slug)} data-action="rename"><i className="fas fa-pencil-alt"></i> Rename</div>
                                                                                                                                    }

                                                                                                                                    {
                                                                                                                                        permissionCan('delete', this.props.current_user.role, page.permissions) &&
                                                                                                                                        <div className="item" data-key={page.key} data-id={page.id} data-index={y} onClick={()=>this.doMenuUpdate({key:page.key, id:page.id, index:z},'delete-confirm', pppp.slug)} data-action="delete"><i className="fas fa-trash-alt"></i> Delete</div>

                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                            }


                                                                                                                            <NavLink className={!permissionCan('edit', this.props.current_user.role, page.permissions)?'read-only image-icon':' image-icon'} activeClassName='is-active' exact={true} to={'/portal/pages/'+this.props.match.params.project_book_slug+'/'+ this.props.page +'/'+ pppp.slug +'/'+ page.key + '/' + page.slug + '/'}>

                                                                                                                                <span className="page-icon" onClick={()=>{this.changeIcon(page.id, page.key, pppp.slug)}}>
                                                                                                                                    {
                                                                                                                                        (page.icon) &&
                                                                                                                                        <img src={page.icon} alt=""/>
                                                                                                                                    }

                                                                                                                                    {
                                                                                                                                        (!page.icon) &&
                                                                                                                                        <img src={process.env.REACT_APP_URL + '/wp-content/themes/d6support/portal/icons/newspaper.png'} alt=""/>
                                                                                                                                    }

                                                                                                                                </span>

                                                                                                                                <span className="title" data-key={page.key}><span className="title-in">{page.title}</span></span>
                                                                                                                            </NavLink>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                        )}
                                                                                                    </ul>

                                                                                                    :
                                                                                                    ''

                                                                                            }


                                                                                        </li>
                                                                                    )}
                                                                                </ul>

                                                                                :
                                                                                ''

                                                                        }


                                                                    </li>
                                                                )}
                                                            </ul>

                                                            :
                                                            ''

                                                    }
                                                </li>

                                            )}


                                        </ul>




                                </div>



                            </li>
                        )
                    }
                    </ul>



                        {
                            ( this.props.match.params.page_section === 'page' || this.props.match.params.page_section === 'admin-portal') && this.state.other_portals.length > 0 &&


                            <div className="project-list">
                                <h4>Other Projects</h4>
                                <ul>




                                    {this.state.other_portals.map((portal,i) =>

                                        <li key={i}><NavLink to={portal.url}><img src={process.env.REACT_APP_URL + '/wp-content/themes/d6support/portal/icons/folder3.png'} alt="" /> <span>{portal.title}</span></NavLink></li>
                                    )}



                                </ul>
                            </div>
                        }


                    </div>



                    {
                        (
                            true
                            ||
                            this.props.match.params.page_section === 'sitemap-page-content'

                        )


                        &&

                        <div className="actions" style={{display:'none'}}>


                            {
                                this.props.match.params.page_section === 'report' && this.state.is_administrator &&

                                <div className="add-item" onClick={()=>this.addReportPage()}> {/*showAddSectionModal*/}
                                    <span><i className="fas fa-plus"></i><span className="title-in">Add Report</span></span>
                                </div>
                            }


                            {
                                this.props.match.params.page_section !== 'report' &&

                                <div className="add-item" onClick={()=>this.addPage({index: 0, parent_id: 0, title: 'New Page', page_section: this.props.match.params.page_section})}> {/*showAddSectionModal*/}
                                    <span><i className="fas fa-plus"></i><span className="title-in">Add Page</span></span>
                                </div>
                            }



                            {/*<div className="add-item" onClick={()=>this.addPage({index: 0, parent_id: this.state.current_page.id, title: 'New Page', page_section: this.props.match.params.page_section})}> /!*showAddSectionModal*!/*/}
                                {/*<span><i className="fas fa-plus"></i>Add Section</span>*/}
                            {/*</div>*/}

                        </div>
                    }




                    <SweetAlert
                        show={this.state.show_confirm_delete_popup}
                        customClass="container-delete-alert"
                        title="Delete Page?"
                        //type="warning"
                        html={true}
                        text="This will permanently delete all content.<br>You can't undo this."
                        confirmButtonText="Delete"
                        showCancelButton
                        onConfirm={() => {
                            this.deletePage(this.state.page_key_to_delete, this.state.page_page_section_to_delete);
                            this.setState({ show_confirm_delete_popup: false });
                        }}

                        onCancel={() => {
                            this.setState({ show_confirm_delete_popup: false });
                        }}
                    />


                </div>
            </div>
        </aside>

        );
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectBookSidebar));