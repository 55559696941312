import React, { Component } from "react";
import { withRouter } from "react-router-dom";//Route,
import { connect } from "react-redux";

import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};

function mapDispatchToProps(dispatch) {
    return {

    };
}


class DesignTemplate extends Component {

    constructor(props){
        super(props);

        this.state = {

            current_page: {}
        }
    }




    componentDidMount(){

        this.setState({current_page: this.props.current_page});

        jQuery('body').addClass('project-book-custom-page');


    }

    componentWillUnmount() {

        jQuery('body').removeClass('project-book-custom-page');
    }

    componentWillReceiveProps(nextProps) {


    }

    render() {


        return (

            <div className="custom-page-wrap">
                <div className="text-center">Coming Soon</div>
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DesignTemplate));