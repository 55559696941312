import React, { Component } from "react";
import '../../assets/styles/Login.scss';

class LogOut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',

        };
    }

    componentDidMount(){

        localStorage.setItem('currentUser', JSON.stringify({}));
        window.location.href = '/';

    }

    handleSubmit(event) {


    }

    render() {

        return (
            <div className="page-wrap">
                <div className="logout-content">
                    <div className="text-center">Logging out...</div>
                </div>
            </div>
        );

    }
}

export default LogOut;