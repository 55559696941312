import React, { Component } from "react";
import axios from 'axios';
import '../../assets/styles/Login.scss';
import logo from '../../assets/images/d6-support-logo.png';
import { withRouter, NavLink } from "react-router-dom";

class LostPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            email: '',
            password: '',
            btnEnabled: true,
        };



        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);


    }


    componentWillUnmount(){

        window.$j('body').removeClass('login-page');
    }

    componentDidMount(){

        window.$j('body').addClass('login-page');

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmit(event) {

        event.preventDefault();

        this.setState({'btnEnabled': false});

        let formData = new FormData();

        formData.append('user_login', this.state.email);

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/forgot-password', formData)
            .then(res => {


                var errorMsg = '';
                var msg = '';

                if (res.data.success){

                    msg = res.data.msg;

                }else{

                    errorMsg = res.data.msg;
                }


                this.setState({
                    email: '',
                    msg: msg,
                    errorMsg: errorMsg,
                    btnEnabled: true
                });


            })

            .catch((error) => {


                this.setState({
                    errorMsg: 'Error: please try again later.',
                    btnEnabled: true
                });

                //console.log(error.config);
            });

    }


    render() {


        return (
            <div className="page-wrap">

                <div>
                    <main>
                        <div className="login-form">

                            <form className="card" onSubmit={this.handleSubmit}  ref={(f) => {this.formRef = f;}}>

                                <div className="login-logo"><img src={logo} className="App-logo" alt="logo" /></div>

                                <p style={{fontSize:'13px', lineHeight:1.4}}>Please enter your username or email address.</p>

                                <div className="form-group">
                                    <input type="text" name="email" placeholder="Username / Email Address" className="form-control" value={this.state.email} onChange={this.handleInputChange}/>
                                </div>

                                <button disabled={!this.state.btnEnabled} className="btn btn-primary"><span>Get New Password</span></button>

                                <div className="text-center login-links">
                                    <NavLink to={'/login'}>Login</NavLink>
                                </div>

                                {
                                    (this.state.errorMsg)
                                        ?(<div className="alert alert-danger">{this.state.errorMsg}</div>)
                                        :('')
                                }

                                {
                                    (this.state.msg)
                                        ?(<div className="alert alert-success">{this.state.msg}</div>)
                                        :('')
                                }
                            </form>
                        </div>
                    </main>
                </div>

            </div>
        );
    }
}

export default withRouter(LostPassword);