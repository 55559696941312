import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";


//import Header from './partials/header'
import Header from '../header'
import ProjectBook from './project-book'
//import Projects from '../support/projects'
import ProjectApprovalForm from '../support/project-approval-form';
//import Dashboard from '../project-book/dashboard';
//import MakeRequest from '../support/make-request'
//import SingleRequestClient from '../support/single-request-client'
import MainWithAside from './main-with-aside'


//import jQuery from 'jquery';
//jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {

    };
}


class PortalHome extends Component {

    constructor(props){
        super(props);

        this.state = {

        }

    }

    componentWillUnmount(){

        localStorage.setItem('redirect',this.props.location.pathname);
    }


    componentDidMount(){

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {

        return (

            <div>
                <Header />

                <Switch>

                    {/*<Route path="/portal/projects/:client_slug/make-request" component={MakeRequest}/>*/}
                    {/*<Route path="/portal/projects/:client_slug/request/:request_long_id" component={SingleRequestClient}/>*/}
                    {/*<Route path="/portal/projects/:client_slug/:status" component={Projects}/>*/}
                    {/*<Route path="/portal/projects/:client_slug" component={Projects}/>*/}
                    {/*<Route path="/portal/projects/" component={Projects}/>*/}
                    {/*<Route path="/portal/dashboard/:client_slug" component={Dashboard}/>*/}

                    <Route path="/portal/project/:project_scope_slug" component={ProjectApprovalForm}/>
                    <Route path={`/portal/pages/:project_book_slug`} component={ProjectBook}  />

                    <Route path="" component={MainWithAside}/>

                </Switch>


            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PortalHome));