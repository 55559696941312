import React, { Component } from "react";
import axios from 'axios';
//import { NavLink } from "react-router-dom";

import ProfileForm from './partials/profile-form'
import '../../assets/styles/Profile.scss';

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: [],
            profile_fetched: false
        };

        this.onProfileUpdate = this.onProfileUpdate.bind(this);
    }

    componentWillUnmount(){

        window.$j('.sub-nav').css({display:'none'});

        window.$j('body').removeClass('client-account-page');
    }

    componentDidMount(){

        //console.log(this.props.match.params.client);

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/profile', headers)
            .then(res => {


                if (res.data.client){

                    this.setState({client: res.data.client})

                }else {

                    this.props.history.push('/');

                }


                this.setState({profile_fetched: true});

            })

            .catch((error) => {

                //this.setState({profile_fetched: true});
                //console.log(error.response.data);
                this.props.history.push('/login');

            });


        window.$j('.sub-nav').css({display:'block'});

        window.$j('body').addClass('client-account-page');

        /***
         * Important
         */
        //window.$j('main').css({'height':window.$j(window).height() -70});
        //window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});

    }

    onProfileUpdate(client){

        this.setState({client: client});

    }

    render() {


        if (!this.state.profile_fetched){

            return (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>);
        }


        return (


            <div style={{'maxWidth':'1000px','margin':'0 auto'}}>
                <div className="account-page-wrapper">

                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                                </ol>
                            </nav>


                            <h4 className="mg-b-10 tx-spacing--1">Profile</h4>

                        </div>
                        <div className="d-none d-md-block">

                        </div>
                    </div>

                    <main>
                        <div>
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center profile-meta">
                                        <div className="wd-80 mg-r-30 profile-photo">
                                            <img className="pd-2 rounded-circle wd-80 bd-gray-100 bd ht-80 lh-2 bd-5" src={this.state.client.photo} alt=""/>
                                        </div>

                                        <div className="profile-info">
                                            <h4 className="tx-18 tx-uppercase">{this.state.client.first_name} {this.state.client.last_name}</h4>
                                            <div className="tx-color-03 profile-email">{this.state.client.email}</div>
                                            <div className="tx-color-03 ">{this.state.client.company}</div>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">



                                    <ProfileForm profileUpdated={this.onProfileUpdate}  client={this.state.client} />

                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        );
    }
}

export default Profile;