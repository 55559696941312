import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import '../../assets/styles/Projects.scss';
import { authHeader, convertServerDateToLocal } from '../helpers/helpers'
import { setSiteData } from "../../redux-js/actions/index";
import { connect } from "react-redux";

//import jQuery from 'jquery';
//jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}



class Project extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fetched: false,
            dashboard: {

            }
        }
    }

    getDashboardContent(){


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/dashboard/?client_slug='+this.props.match.params.client_slug, authHeader())
            .then(res => {

                this.setState({
                    fetched: true,
                    dashboard:res.data,
                });

            })


            .catch((error) => {

                //this.props.history.push('/login');

            });

    }

    componentDidMount(){

        this.props.setSiteData({client_slug: this.props.match.params.client_slug, page: 'dashboard'});
        this.getDashboardContent();

    }



    componentWillUnmount(){

        localStorage.setItem('redirect',this.props.location.pathname);

    }

    getDate(date){


        var d = convertServerDateToLocal(date);

        return moment(d).fromNow(true) + ' ago';

    }

    getStatusColor(status){

        if (status === 'Pending'){
            return '#84cb88';
        }else if (status === 'Completed'){
            return '#7d94f4';
        }else{
            return '#fac166';//in progress
        }



    }

    render() {



        return (

            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">



                    <main>




                        <div style={{'maxWidth':'1000px','margin':'0 auto'}}>

                            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                <div>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">

                                            <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                                        </ol>
                                    </nav>
                                    <h4 className="mg-b-10 tx-spacing--1">Welcome to D6 Dashboard</h4>

                                </div>
                                <div className="d-none d-md-block">
                                    {
                                        this.props.match.params.client_slug &&

                                        <NavLink className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" to={'/portal/projects/'+this.props.match.params.client_slug+'/make-request/'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file wd-10 mg-r-5"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                            Make a Request
                                        </NavLink>
                                    }
                                </div>
                            </div>


                            {
                                !this.state.fetched &&
                                <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                            }

                            {
                                this.state.fetched &&

                                <div>
                                    <div className="card mg-b-10">
                                        <div
                                            className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                                            <h6 className="mg-b-0">Recently Updated Pages</h6>
                                        </div>
                                        <div className="card-body">

                                            {
                                                <div className="row row-xs">

                                                    {this.state.dashboard.updated_pages.map((page, i) =>
                                                        <div className="col-md-4" key={i}>
                                                            <div className="pd-t-5 pd-b-5">
                                                                <a href={page.url}
                                                                   className="d-flex align-items-center">
                                                                    <div
                                                                        className="pd-10 rounded-circle wd-40 bd-gray-100 bd bd-2 ht-40 lh-2 bd-2 mg-r-15">
                                                                        <img src={page.icon} className="" alt=""/>
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="tx-13 tx-inverse tx-normal mg-b-0">{page.title}</h6>
                                                                        <span className="d-block tx-11 text-muted"
                                                                              title={page.date}>{this.getDate(page.date)}</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }



                                        </div>
                                    </div>



                                    <div className="card mg-b-10">
                                        <div
                                            className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                                            <h6 className="mg-b-0">Recent Requests</h6>
                                        </div>
                                        <div className="card-body">

                                            {
                                                <div className="row row-xs">

                                                    {this.state.dashboard.requests.map((request, i) =>
                                                        <div className="col-md-4" key={i}>
                                                            <div className="pd-t-5 pd-b-5">
                                                                <a href={request.link} className="d-flex align-items-center">
                                                                    <div className="pd-8 text-center rounded-circle wd-40 bd-gray-100 bd bd-2 ht-40 lh-2 bd-2 mg-r-15" >
                                                                        <span className="d-inline-block rounded-circle wd-20 ht-20" style={{backgroundColor:this.getStatusColor(request.status)}}></span>
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="tx-13 tx-inverse tx-normal mg-b-0">{request.subject}</h6>
                                                                        <span className="d-block tx-11 text-muted">{request.status}</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {

                                                this.state.dashboard.requests.length <= 0 &&
                                                <div>
                                                    No requests
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </div>




                            }


                        </div>
                    </main>
                </div>
            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Project);