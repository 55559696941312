import { ADD_WORK_REQUEST, ADD_COMMENT, SET_WORK_REQUEST_SLUG, SET_PAYMENT_MADE, SET_CURRENT_USER, SET_SITE_DETAILS, CACHE_CONTENT, SET_PROJECT_BOOK_SLUG, SET_PROJECT_BOOK_PAGE_PAGES, SET_PROJECT_BOOK_INFO, SET_PROJECT_BOOK_CURRENT_PAGE, SET_SITE_DATA } from "../constants/action-types";

const initialState = {
    work_requests: [],
    comments: [],
    work_request_slug: '',
    project_book_slug: '',
    payment_made: false,
    current_user: {},
    site_details: {},
    cache_contents:[],
    project_book_page_pages:[],
    project_book_current_page:[],
    project_book_info:{},
    site_data:{}
};


function rootReducer(state = initialState, action) {

    if (action.type === ADD_WORK_REQUEST) {


        //state.articles.push(action.payload);
        //it valid code but breaks the main Redux principle: immutability.
        //so use below

        if ( !Object.entries(action.payload).length){

            state.work_requests.splice(0, state.work_requests.length); //reset requests

        }else{

            return Object.assign({}, state, {
                work_requests: state.work_requests.concat(action.payload)
            });

        }

    }


    if (action.type === ADD_COMMENT) {

        if ( !Object.entries(action.payload).length){

            //if payload is object {} and payload is empty and state.comments has content
            state.comments.splice(0, state.comments.length); //reset comments

        }else{

            return Object.assign({}, state, {
                comments: state.comments.concat(action.payload)
            });

        }

    }


    if (action.type === SET_WORK_REQUEST_SLUG) {

        state.work_request_slug = action.payload;
    }

    if (action.type === SET_PROJECT_BOOK_SLUG) {

        state.project_book_slug = action.payload;

    }

    if (action.type === SET_PAYMENT_MADE) {

        state.payment_made = action.payload;
    }


    if (action.type === SET_CURRENT_USER) {

        state.current_user = action.payload;
    }

    if (action.type === SET_SITE_DETAILS) {

        state.site_details = action.payload;
    }

    if (action.type === CACHE_CONTENT) {

        state.cache_contents[action.payload.key] = action.payload.content;

    }

    if (action.type === SET_PROJECT_BOOK_PAGE_PAGES) {

        state.project_book_page_pages =  action.payload;

    }

    if (action.type === SET_PROJECT_BOOK_INFO) {

        state.project_book_info =  action.payload;

    }

    if (action.type === SET_PROJECT_BOOK_CURRENT_PAGE) {

        state.project_book_current_page =  action.payload


    }

    if (action.type === SET_SITE_DATA) {

        state.site_data =  action.payload

    }


    return state;
}

export default rootReducer;