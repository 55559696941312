import React, { Component } from "react";

//import { NavLink, Link } from "react-router-dom";
import logo from '../../assets/images/D6-Flex-Logo.png';
import '../../assets/styles/Signup.scss';
import { connect } from "react-redux";
//import store from "../redux-js/store/index";
//import axios from "axios";
//import { authHeader } from './helpers/helpers'

import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class Signup extends Component {

    constructor(props){
        super(props);

        this.state = {
            signup_data: {
                customer    : 'current',
                email       : '',
            },
            name:'',
            cur_step: 1,
            steps: [{step:'Step 1'}, {step:'Enter Email'},{step: 'Setup Credit Card'},{step: 'Done'}]
        }

        this.handleChange       = this.handleChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);

    }



    handleChange(event) {

        const target = event.target;

        var value = target.value;


        const name = target.name;

        var signup_data = this.state.signup_data;

        signup_data[name] = value

        this.setState({signup_data});
    }


    nextStep(){

        jQuery('.step' + this.state.cur_step).addClass('done');
        jQuery('.step' + (this.state.cur_step+1)).addClass('active');

        this.setState({cur_step: this.state.cur_step+1});
    }

    prevStep(){

        jQuery('.step' + this.state.cur_step).removeClass('active');
        jQuery('.step' + (this.state.cur_step-1)).removeClass('done').addClass('active');

        this.setState({cur_step: this.state.cur_step-1});
    }

    handleSubmit(event) {

        event.preventDefault();

        //const signup_data = this.state.signup_data;

        this.setState({btnEnabled: false});

        this.nextStep();

    }



    componentDidMount(){

    }

    componentWillUnmount() {



    }

    componentWillReceiveProps(nextProps) {


    }


    render() {


        setTimeout(function () {
            jQuery('.signup-panel-wrap').addClass('show');
            //jQuery('.step1').addClass('active');
        },1)

        return (

            <div className="onboard-page pd-t-80 pd-lg-t-0">

                <div className="signup-logo"><img src={logo} alt=""/></div>

                <div className={'onboard-progress-bar-wrap ' + (this.state.cur_step > 1?'show':'')}>
                    <div className="step">
                        Step {this.state.cur_step}/{this.state.steps.length}:
                        <span>{this.state.steps[this.state.cur_step-1].step}</span>
                    </div>

                    <div className="onboard-progress-bar">
                        <div className="onboard-bar" style={{width: ((this.state.cur_step / this.state.steps.length) * 100) + '%'}}></div>
                    </div>
                </div>

                <div className="onboard-page-section step1 active">
                    <div className="onboard-page-section-content">
                        <form onSubmit={this.handleSubmit} >
                            <div className="d-block d-lg-flex align-items-center signup-panel-wrap">
                                <div className="signup-panel w-100 w-lg-50 signup-right-panel d-block d-lg-flex pd-t-50 pd-b-50 pd-lg-0 align-items-center">
                                    <div className="signup-panel-content">
                                        <h1>A few clicks away from getting help.</h1>

                                        <div className="text-slider-wrap">
                                            <div className="text-slider">
                                                <div className="slide-item">
                                                    <h3>Web Support</h3>
                                                    <p>Manage your website through our portal</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="signup-panel w-100 w-lg-50 signup-left-panel d-block d-lg-flex pd-t-50 pd-b-50 pd-lg-0 align-items-center">
                                    <div className="signup-panel-content">
                                        <h1>Hello, how may we help you?</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, assumenda eius eveniet facere harum in iure maiores natus voluptate voluptates.</p>

                                        <div className="cards">
                                            <label className={'card card-body ' + (this.state.signup_data.customer === 'current'?'active':'')}>
                                                <div className="d-flex align-items-center">
                                                    <div className="icon"><div className="fa fa-users"></div></div>
                                                    <div>
                                                        <h4 className="tx-uppercase">Current Customer</h4>
                                                        <p className="tx-16">I am current customer, & need my login information.</p>
                                                        <input type="radio" name="customer" value="current" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                            </label>

                                            <label className={'card card-body ' + (this.state.signup_data.customer === 'previous'?'active':'')}>
                                                <div className="d-flex align-items-center">
                                                    <div className="icon"><div className="fa fa-users-cog"></div></div>
                                                    <div>
                                                        <h4 className="tx-uppercase">Previous Customer</h4>
                                                        <p>I have worked with D6 in the past & would like to make support request.</p>
                                                        <input type="radio" name="customer" value="previous" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>


                                        <div className="form-wrap">
                                            <div className="form-group">
                                                <div className="form-label tx-13">Please enter your email address</div>
                                                <input type="email" className="form-control" value={this.state.signup_data.email} onChange={this.handleChange} name="email" placeholder="john.appleseed@d6flex.com"/>
                                            </div>

                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block"><span>CONTINUE</span></button>
                                            </div>

                                            <div className="tx-12 mg-t-10">
                                                New Customer? <a href="#test" className="tx-semibold tx-color-01">Click Here</a> to Setup an Account
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>


                <div className="onboard-page-section step2">
                    <div className="onboard-page-section-content d-flex align-items-center justify-content-center">
                        <div className="text-center step-container">
                            <h2>Hello, what's your email?</h2>
                            <p>We will search our records to match your email or domain to our client roster.</p>
                            <div className="form-wrap">
                                <div className="form-group"><input type="text" className="form-control" placeholder="john.appleseed@d6flex.com"/></div>
                                {/*<button className="btn btn-secondary " onClick={()=>this.prevStep()}>Back</button> */}
                                <button className="btn btn-primary btn-block" onClick={()=>this.nextStep()}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="onboard-page-section step3">
                    <div className="onboard-page-section-content d-flex align-items-center justify-content-center">
                        <div className="text-center step-container">
                            <h2>Looks like we don’t have your your card on file</h2>
                            <p>We require a card to be on file in order to provide service. Please input the card & we’ll proceed. </p>

                            <div className="form-wrap">
                                <div className="form-group"><input type="text" className="form-control" placeholder="xxxx-xxxx-xxxx-xxxx"/></div>
                                {/*<button className="btn btn-secondary" onClick={()=>this.prevStep()}>Back</button> */}
                                <button className="btn btn-primary btn-block" onClick={()=>this.nextStep()}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="onboard-page-section step4">
                    <div className="onboard-page-section-content d-flex align-items-center justify-content-center">
                        <div className="text-center step-container">
                            <h2>Thank You!</h2>
                        </div>
                    </div>
                </div>


            </div>

        )

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Signup);