import React, { Component } from "react";
import axios from 'axios';
import {CardElement, injectStripe} from 'react-stripe-elements';

import { connect } from "react-redux";





const mapStateToProps = state => {
    return { payment_made: state.payment_made };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}



class SubscribeForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            payment: {
                first_name:'',
                last_name: '',
                email: '',
                plan_id: '',
                token: ''
            }
        };


        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
    }



    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        var payment = this.state.payment;
        payment[name] = value;

        this.setState({payment});
    }


    async handleSubmit() {

        this.setState({errorMsg: '', msg: ''});

        if (this.state.payment.first_name === '') {
            this.setState({errorMsg: 'First name field is required.'});
            return false;
        }

        if (this.state.payment.last_name === '') {
            this.setState({errorMsg: 'Last name field is required.'});
            return false;
        }

        if (this.state.payment.email === '') {
            this.setState({errorMsg: 'Email field is required.'});
            return false;
        }



        let payment = this.state.payment;

        this.setState({btnEnabled: false});


        let card_holder_name = this.state.payment.first_name +' '+this.state.payment.last_name;

        let stripe_response = await this.props.stripe.createToken({name: card_holder_name});

        //console.log(token);

        if (stripe_response.error){

            //console.log(error.response.data);
            this.setState({errorMsg: stripe_response.error.message});
            this.setState({btnEnabled:true});


        }else{



            if (stripe_response.token){

                payment.token = stripe_response.token;


                let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

                axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/plan/subscribe', { payment }, headers)
                    .then(res => {

                        if(res.data.success){

                            this.setState(
                                {
                                    payment: {
                                        first_name:'',
                                        last_name: '',
                                        email: '',
                                        plan_id: '',
                                        token: '',
                                    },

                                    msg: res.data.msg
                                }
                            );


                            this.props.clientSubscribe(res.data.plan);


                            this._element.clear();


                        }else{

                            this.setState({errorMsg: res.data.msg});

                        }

                        this.setState({btnEnabled: true});
                    })


                    .catch((error) => {

                        //console.log(error.response.data);
                        this.setState({errorMsg: error.response.data.message});
                        this.setState({btnEnabled:true});

                    });

            }else{

                this.setState({btnEnabled: true});
            }

        }


    }

    componentDidMount(){

        let payment = this.state.payment;

        payment['plan_id'] = this.props.plan_id

        this.setState({payment})

    }


    render() {

        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;

        return (

            <div>
                <p>With the automatic credit card payment from D6 Interactive, now there’s an easy and convenient way to make sure your payments are always paid correctly, and paid on time.</p>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="text" name="first_name" placeholder="First Name"  value={this.state.payment.first_name} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="text" name="last_name" placeholder="Last Name"  value={this.state.payment.last_name} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="text" name="email" placeholder="Email Address" value={this.state.payment.email} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group card-field">
                            <CardElement onReady={(c) => this._element = c}/>
                        </div>
                    </div>

                </div>


                <div style={{marginTop:'20px'}}>
                    {errorMsg &&

                    <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: errorMsg}}></div>
                    }

                    {msg &&
                    <div className="alert alert-success" dangerouslySetInnerHTML={{__html: msg}}></div>
                    }
                </div>

                <div className="text-center">
                    <button disabled={!this.state.btnEnabled} onClick={this.handleSubmit} type="button" className="btn btn-primary"><span>Submit</span></button>
                </div>

                {
                    !this.state.btnEnabled &&
                    <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>

                }

            </div>

        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(SubscribeForm));
