import React, { Component } from "react";
import {  withRouter } from "react-router-dom";//Link,
import axios from 'axios';
import { connect } from "react-redux";
import moment from 'moment';
import '../../assets/styles/Request.scss';
import Header from '../header';
import { setSiteData, addWorkRequest } from "../../redux-js/actions/index";

//import jQuery from 'jquery';
//jQuery = window.$j;

const mapStateToProps = state => {
    return { work_requests: state.work_requests, ...state };
};




function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data)),
        addWorkRequest: work_request => dispatch(addWorkRequest(work_request))
    };
}


class Admin extends Component {

    constructor(props){
        super(props);

        this.state = {

            portals: [],
            projects: [],
            updated_pages: [],
            fetched: false
        }
    }

    componentWillUnmount(){

        this.props.addWorkRequest({});//reset requests in the store so that it will not show in other work request
    }



    componentDidMount(){


        this.props.setSiteData({admin:true});


        if (this.props.current_user.role !== 'administrator' && this.props.current_user.role !== 'staff'){
            this.props.history.push('/login');

            return;
        }

        this.getWorkRequests();

        /***
         * Important
         */
        //jQuery('main').css({'height':jQuery(window).height() -70});
        //jQuery('main .wrap').css({'min-height':jQuery(window).height() -69});

    }


    getWorkRequests(){

        //if (window.location.pathname.split("/").pop() === '' || window.location.pathname.split("/").pop() === 'requests'){

        /*
         * Run only this block if this is really a /requests page
         * Because it also run even on single work request page
         */


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/support-admin/', headers)
            .then(res => {

                if (res.data.success) {


                    this.setState({
                        portals:res.data.portals,
                        projects:res.data.projects,
                        updated_pages: res.data.updated_pages
                    });

                } else {

                    //redirect here
                }

                this.setState({fetched: true});
            })


            .catch((error) => {

                this.props.history.push('/login');

                //console.log(error.response.data);
                //this.setState({fetched: true});

            });

    }

    convertServerDateToLocal(date) {

        // EST - UTC offset: 5 hours
        var offset = 5.0,

        /*
         - calculate the difference between the server date and UTC
         - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
         - the time-zone offset is the difference, in minutes, between UTC and local time
         - 60000 milliseconds = 60 seconds = 1 minute
         */
        serverDate = new Date(date),
        utc = serverDate.getTime() - (serverDate.getTimezoneOffset() * 60000),

        /*
         - apply the offset between UTC and EST (5 hours)
         - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
         */
        clientDate = new Date(utc + (3600000 * offset));

        return clientDate.toLocaleString();
    }

    getDate(date){

        //var d = new Date(date + ' UTC');
        var d = this.convertServerDateToLocal(date);

        return moment(d).fromNow();
    }


    render() {

        return (
            <div className="page-wrap">

                <div>

                    <Header />

                    <div className="content content-fixed">
                        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                            <main>

                                {

                                    !this.state.fetched
                                        ?
                                        (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>)
                                        :
                                        ('')
                                }


                                {
                                    this.state.fetched &&
                                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">

                                        <div>

                                            <div className="card mg-b-10">
                                                <div className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Portals</h6>
                                                </div>
                                                <div className="card-body">

                                                    {
                                                        <div className="row row-xs">

                                                            {this.state.portals.map((portal,i) =>
                                                                <div className="col-md-4" key={i}>
                                                                    <div className="pd-t-5 pd-b-5">
                                                                        <a href={portal.url} className="d-flex align-items-center">
                                                                            <div className="pd-10 rounded-circle wd-40 bd-gray-100 bd ht-40 lh-2 bd-2 mg-r-15">
                                                                                <img src={portal.photo} className="" alt="" />
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="tx-13 tx-inverse tx-normal mg-b-0">{portal.title}</h6>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }

                                                </div>
                                            </div>


                                            <div className="card mg-b-10">
                                                <div className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Projects</h6>
                                                </div>
                                                <div className="card-body">

                                                    {
                                                        <div className="row row-xs">

                                                            {this.state.projects.map((project,i) =>
                                                                <div className="col-md-4" key={i}>
                                                                    <div className="pd-t-5 pd-b-5">
                                                                        <a href={project.url} className="d-flex align-items-center">
                                                                            <div className="pd-10 rounded-circle wd-40 bd-gray-100 bd ht-40 lh-2 bd-2 mg-r-15">
                                                                                <img src={project.photo} className="" alt="" />
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="tx-13 tx-inverse tx-normal mg-b-0">{project.title}</h6>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }




                                                </div>
                                            </div>



                                            <div className="card mg-b-10">
                                                <div className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Recently Updated/Created Pages</h6>
                                                </div>
                                                <div className="card-body">

                                                    {
                                                        <div className="row row-xs">

                                                            {this.state.updated_pages.map((page,i) =>
                                                                <div className="col-md-4" key={i}>
                                                                    <div className="pd-t-5 pd-b-5">
                                                                        <a href={page.url} className="d-flex align-items-center">
                                                                            <div className="pd-10 rounded-circle wd-40 bd-gray-100 bd bd-2 ht-40 lh-2 bd-2 mg-r-15">
                                                                                <img src={page.photo} className="" alt="" />
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="tx-13 tx-inverse tx-normal mg-b-0">{page.title}</h6>
                                                                                <span className="d-block tx-11 text-muted" title={page.date}>{this.getDate(page.date)}</span>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }



                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                }

                            </main>
                        </div>
                    </div>
                </div>
            </div>

        );


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));