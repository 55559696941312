import React, { Component } from 'react';

//https://github.com/sstur/draft-js-utils
//https://www.npmjs.com/package/draft-js-export-html
import {stateToHTML} from 'draft-js-export-html';
//import {Editor} from "@tinymce/tinymce-react/lib/cjs/components/Editor.d";

//https://www.npmjs.com/package/draft-js-import-html
//import {stateFromHTML} from 'draft-js-import-html';

import { EditorState, ContentState, RichUtils } from 'draft-js';
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor';
import createInlineToolbarPlugin, { Separator } from 'draft-js-inline-toolbar-plugin';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';

//EditorState, ContentState

import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    //CodeButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    CodeBlockButton,
} from 'draft-js-buttons';






class HeadlinesPicker extends Component {
    componentDidMount() {
        setTimeout(() => { window.addEventListener('click', this.onWindowClick); });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
    }

    onWindowClick = () =>
        // Call `onOverrideContent` again with `undefined`
        // so the toolbar can show its regular content again.
        this.props.onOverrideContent(undefined);

    render() {
        const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
        return (
            <span className="draftJsToolbar__buttonWrapper__1Dmqh">
                {buttons.map((Button, i) => // eslint-disable-next-line
                    <Button key={i} {...this.props} />
                )}
            </span>
        );
    }
}

class HeadlinesButton extends Component {
    // When using a click event inside overridden content, mouse down
    // events needs to be prevented so the focus stays in the editor
    // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
    onMouseDown = (event) => event.preventDefault()

    onClick = () =>
        // A button can call `onOverrideContent` to replace the content
        // of the toolbar. This can be useful for displaying sub
        // menus or requesting additional information from the user.
        this.props.onOverrideContent(HeadlinesPicker);

    render() {
        return (
            <span onMouseDown={this.onMouseDown} className="draftJsToolbar__buttonWrapper__1Dmqh">
                <button onClick={this.onClick} className="draftJsToolbar__button__qi1gf">
                    H
                </button>
            </span>
        );
    }
}



const linkPlugin = createLinkPlugin();

const inlineToolbarPlugin = createInlineToolbarPlugin();

const { InlineToolbar } = inlineToolbarPlugin;

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin, inlineToolbarPlugin, linkPlugin];
const text = '';


export default class SimpleStaticToolbarEditor extends Component {


    constructor(props) {
        super(props);

        this.state = {
            editorState: createEditorStateWithText(text),
            placeholder: ''
        };




        this.onChange   = this.onChange.bind(this);
        this.focus      = this.focus.bind(this);

        this.onContentChange    = (editorState) => this.setState({editorState});
        this.handleKeyCommand   = (command)     => this._handleKeyCommand(command);
        this.onTab              = (e)           => this._onTab(e);
        this.toggleBlockType    = (type)        => this._toggleBlockType(type);
        this.toggleInlineStyle  = (style)       => this._toggleInlineStyle(style);


    }



    _handleKeyCommand(command) {
        const {editorState} = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onContentChange(newState);
            return true;
        }
        return false;
    }

    _onTab(e) {
        const maxDepth = 4;
        this.onContentChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    _toggleBlockType(blockType) {
        this.onContentChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onContentChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }



    onChange (editorState) {

        this.setState({
            editorState
        });


        let editorContent = stateToHTML(editorState.getCurrentContent());

        this.props.onChange(editorContent);

    }

    focus(){

        this.editor.focus();
    }


    clearEditor(){

        //Reset editor
        let editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
        this.setState({ editorState });

        //this.setState({editorState: createEditorStateWithText(text)});
    }

    componentDidMount(){

        this.setState({
            placeholder:this.props.placeholder,
        });

        if (this.props.editorState){
            this.setState({
                editorState:this.props.editorState,
            });
        }
        //console.log(this.props.onChange);

    }


    render() {
        return (
            <div onClick={this.focus}>

                <div className="editor-static-toolbar">
                    <Toolbar>
                        {
                            // may be use React.Fragment instead of div to improve perfomance after React 16
                            (externalProps) => (
                                <div>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                    {/*<CodeButton {...externalProps} />*/}
                                    <Separator {...externalProps} />
                                    <HeadlinesButton {...externalProps} />
                                    <UnorderedListButton {...externalProps} />
                                    <OrderedListButton {...externalProps} />
                                    <BlockquoteButton {...externalProps} />
                                    <CodeBlockButton {...externalProps} />
                                </div>
                            )
                        }
                    </Toolbar>
                </div>


                <div>
                    <Editor
                        handleKeyCommand        ={this.handleKeyCommand}
                        editorState             ={this.state.editorState}
                        onChange                ={this.onChange}
                        plugins                 ={plugins}
                        placeholder             ={this.state.placeholder}
                        ref                     ={(element) => { this.editor = element; }}
                        onTab                   ={this.onTab}
                        spellCheck              ={true}
                    />

                    <div className="inline-toolbar">
                        <InlineToolbar>
                            {
                                // may be use React.Fragment instead of div to improve perfomance after React 16
                                (externalProps) => (
                                    <div>
                                        <BoldButton {...externalProps} />
                                        <ItalicButton {...externalProps} />
                                        <UnderlineButton {...externalProps} />
                                        <UnorderedListButton {...externalProps} />
                                        <OrderedListButton {...externalProps} />
                                        <linkPlugin.LinkButton {...externalProps} />
                                    </div>
                                )
                            }
                        </InlineToolbar>
                    </div>
                </div>

            </div>
        );
    }
}