import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import carmelo from '../../assets/images/carmelo.jpg';

import '../../assets/styles/ServicePlan.scss';



const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {

    };
}



class ServicePlan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            service_plan_text: '',
            payment_policy_text: '',
            service_plan: [],
            service_plan_fetched: false,
            carmelo_msg: '',
            main_services: '',
            hours_allocation: '',
            questionnaire_info: '',
            policies: '',
            plan: {},
            max_hours_reached : 0,
            proceed_within_hours : 0,
            payment_policies:[],
        };



        this.handleInputChange  = this.handleInputChange.bind(this);

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;


        this.setState({[name]: value}, function(){
            this.saveChanges()
        }.bind(this));


    }



    componentWillUnmount(){

        window.$j('.sub-nav').css({display:'none'});
        window.$j('body').removeClass('client-account-page');
    }

    saveChanges(){



        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };


        let data = {
            max_hours_reached    : this.state.max_hours_reached,
            proceed_within_hours : this.state.proceed_within_hours,
            plan_id              : this.state.plan.plan_id
        };


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/service-plan/save', data, headers)
            .then(res => {


                if (!res.data.success){

                    //window.location.href = '/login';
                }

            })

            .catch((error) => {

                window.location.href = '/login';
                //this.setState({service_plan_fetched: true});
                //console.log(error.response.data);

            });



    }

    componentDidMount(){


        this.setState({service_plan_fetched: false});


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/service-plan', headers)
            .then(res => {


                if (false && res.data.service_plan){

                    this.setState({

                        service_plan            : res.data.service_plan,
                        main_services           : res.data.main_services,
                        carmelo_msg             : res.data.carmelo_msg,
                        hours_allocation        : res.data.hours_allocation,
                        questionnaire_info      : res.data.questionnaire_info,
                        policies                : res.data.policies,
                        plan                    : res.data.plan,
                        max_hours_reached       : res.data.plan.max_hours_reached,
                        proceed_within_hours    : res.data.plan.proceed_within_hours,

                    })
                }

                this.setState({
                    service_plan_fetched: true,
                    service_plan_text: res.data.service_plan_text,
                    payment_policy_text: res.data.payment_policy_text,
                    payment_policies: res.data.payment_policies,
                    service_plan: res.data.service_plan
                });

            })

            .catch((error) => {


                //window.location.href = '/login';
                //this.setState({service_plan_fetched: true});
                //console.log(error.response.data);

            });


        window.$j('.sub-nav').css({display:'block'});

        window.$j('body').addClass('client-account-page');

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});
    }


    render() {

        if (!this.state.service_plan_fetched){

            return (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>);
        }


        return (
            <div style={{'maxWidth':'1000px','margin':'0 auto'}}>


                <div>
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                                </ol>
                            </nav>

                            <h4 className="mg-b-10 tx-spacing--1">Service Plan</h4>

                        </div>
                        <div className="d-none d-md-block">

                        </div>
                    </div>

                    <main>
                        <div>
                            {
                                this.state.service_plan_text !== '' &&
                                <div className="card">
                                    <div className="card-body">

                                        <div className="service-plan-content">

                                            <div className="service-plan-content-text">
                                                <div dangerouslySetInnerHTML={{__html: this.state.service_plan_text}}></div>

                                                {
                                                    this.state.service_plan.length !== 0 &&

                                                    <div className="accordion" id="accordionServicePlans">

                                                        {this.state.service_plan.map((item, i) =>

                                                            <div className="card" key={i}>
                                                                <div className="card-header" id={'headingx' + i}>
                                                                    <h2 className="mb-0">
                                                                        <button className="btn btn-link collapsed"
                                                                                type="button"
                                                                                data-toggle="collapse"
                                                                                data-target={'#collapsex' + i}
                                                                                aria-expanded="true"
                                                                                aria-controls={'collapsex' + i}>
                                                                            <span
                                                                                dangerouslySetInnerHTML={{__html: item.title}}></span>
                                                                        </button>
                                                                    </h2>
                                                                </div>

                                                                <div id={'collapsex' + i}
                                                                     className="collapse card-content"
                                                                     aria-labelledby={'headingx' + i}
                                                                     data-parent="#accordionServicePlans">
                                                                    <div className="card-body">
                                                                        <div
                                                                            dangerouslySetInnerHTML={{__html: item.content}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}


                                                    </div>

                                                }


                                            </div>


                                        </div>


                                    </div>
                                </div>

                            }


                            {
                                this.state.payment_policy_text !=='' &&

                                <div className="card mg-t-15">
                                    <div className="card-body">

                                        <div className="service-plan-content">




                                                <div className="service-plan-content-text">
                                                    <div dangerouslySetInnerHTML={{__html: this.state.payment_policy_text}}></div>


                                                    {
                                                        this.state.payment_policies.length &&

                                                        <div className="accordion" id="accordionPaymentPolicies">

                                                            {this.state.payment_policies.map((item, i) =>

                                                                <div className="card" key={i}>
                                                                    <div className="card-header" id={'heading' + i}>
                                                                        <h2 className="mb-0">
                                                                            <button className="btn btn-link collapsed" type="button"
                                                                                    data-toggle="collapse" data-target={'#collapse' + i}
                                                                                    aria-expanded="true" aria-controls={'collapse' + i}>
                                                                                <span dangerouslySetInnerHTML={{__html: item.title}}></span>
                                                                            </button>
                                                                        </h2>
                                                                    </div>

                                                                    <div id={'collapse' + i} className="collapse card-content"
                                                                         aria-labelledby={'heading' + i}
                                                                         data-parent="#accordionPaymentPolicies">
                                                                        <div className="card-body">
                                                                            <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}


                                                        </div>

                                                    }


                                                </div>




                                        </div>


                                    </div>
                                </div>
                            }




                        </div>
                    </main>
                </div>




            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServicePlan));