import React, { Component } from "react";
import { withRouter } from "react-router-dom";//Route,
import { connect } from "react-redux";
import axios from 'axios';


import jQuery from 'jquery';
jQuery = window.$j;


const mapStateToProps = state => {
    return { ...state };
};

function mapDispatchToProps(dispatch) {
    return {

    };
}


class TaskTemplate extends Component {

    constructor(props){
        super(props);

        this.state = {
            current_page: {},
            templateData:{
                tasks: [],
                clients: []
            },
            edit_title: false,
            data_fetched: false,
            edit_popup: '',
            task:{},
            selected_task_idx: -1,
            selected_task_parent_idx: -1,

        }

        this.handleTitleChange = this.handleTitleChange.bind(this);

    }

    handleTitleChange(e){

        this.setState({edit_title:false});

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/task/title/save', { page_id: this.state.current_page.id ,title: e.currentTarget.textContent } , {})
            .then(res => {

            })

            .catch((error) => {

            });

    }

    handleTaskChange(e, idx, parent_idx){


        let templateData = this.state.templateData;

        var task = {};


        if (parent_idx !== -1){

            task = templateData.tasks[parent_idx].sub_tasks[idx];

        }else{

            task = templateData.tasks[idx];
        }


        task.edit = false;
        task.title = e.currentTarget.textContent;

        this.setState(templateData);


        if (task.id){

            //edit
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/task/save', { task } , {})
                .then(res => {


                })

                .catch((error) => {

                });

        }else{

            //add
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/task/add', {page_id: this.state.current_page.id , task } , {})
                .then(res => {


                    if(res.data.task.id) {
                        task.id = res.data.task.id;
                        task.new_task = false;
                        this.setState(templateData);
                    }

                })

                .catch((error) => {

                });

        }

    }


    templateData(current_page){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/template/data/?template=tasks-template&page_id=' + current_page.id, {})
            .then(res => {


                this.setState({
                    templateData: res.data,
                    data_fetched: true,
                });


            })

            .catch((error) => {


            });


    }


    setPage(current_page){

        this.setState({current_page: current_page});

        this.templateData(current_page);


    }


    sortArray(arr, old_index, new_index) {

        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

        return arr; // for testing

    }


    doSort(){

        let templateData = JSON.parse(JSON.stringify(this.state.templateData));


        let tasks = this.sortArray(templateData.tasks, window.task_current_idx, window.task_new_idx);



        let ds = this;


        //JSON.parse(JSON.stringify(this.state.templateData)).tasks.push({'title': '', sub_tasks: [], status: 'pending'});// fix sorting issue

        this.setState({templateData});

        setTimeout(function () {


            templateData.tasks = tasks;
            ds.setState({templateData:templateData, edit_popup: ''}); //edit_popup is need here so that popup disappear when sorting to avoid index issue

        }, 0);




        let tasks_arr = [];

        tasks.forEach(function (v, i) {



            if (v.sub_tasks.length > 1){

                var sub_tasks_arr = [];

                v.sub_tasks.forEach(function (v, x) {
                    sub_tasks_arr.push({id: v.id})
                });

                tasks_arr.push({id: v.id, title: v.title, sub_tasks: sub_tasks_arr});

            } else{

                tasks_arr.push({id: v.id, title: v.title});
            }

        });


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/task/sort/save', { tasks: tasks_arr } , {})
            .then(res => {

            })

            .catch((error) => {

            });


    }

    initSortable(){


        let ds = this;

        setTimeout(function () {

            if ( !jQuery( ".tasks-wrap-items" ).data('sortable-initialized') ){

                jQuery( ".tasks-wrap-items" ).data('sortable-initialized' , true);

                jQuery( ".tasks-wrap-items" ).sortable({
                    cancel: ':input,button,.task-editable',//fix contenteditable editing and focus issue
                    //connectWith: ".task-item",
                    containment: "parent",
                    cursor: 'move',
                    distance: 5,
                    //items: ".task-item",
                    //handle: '.handle',

                    beforeStop: function(ev, ui) {

                        window.task_new_idx = ui.item.index();
                        ds.doSort();

                        jQuery(this).sortable('cancel');
                    },

                    start: function( event, ui ) {

                        window.task_current_idx = ui.item.index();

                    },
                    update:function( event, ui ) {

                        window.task_new_idx = ui.item.index();

                        ds.doSort( );

                        //ds.saveSitemap();
                    }

                });//.disableSelection();


                setTimeout(function () {
                    //window.task_current_idx = 4;
                    //window.task_new_idx = 0;

                    //ds.doSort( );

                }, 3000);
            }

        },100);



    }

    componentDidMount(){

        jQuery('body').addClass('project-book-custom-page');

        jQuery(document).bind('keypress', function (event) {

            let keycode = (event.keyCode ? event.keyCode : event.which);


            if(parseInt(keycode) === 13){

                var $el = jQuery(event.target);

                if($el.attr('contenteditable')){

                    $el.trigger('blur');
                    return false;
                }

            }
        });

        this.setPage(this.props.current_page);

        let ds = this;

        jQuery(document).on('click.taskClickAnyWhere', function () {

            setTimeout(function () {

                if(!window.edit_popup_clicked){

                    ds.setState({edit_popup:''});
                }

                window.edit_popup_clicked = false;

            },100)
        });





    }

    componentWillUnmount() {

        jQuery('body').removeClass('project-book-custom-page');

        jQuery(document).off("click.taskClickAnyWhere");
    }

    componentWillReceiveProps(nextProps) {

        this.setPage(nextProps.current_page);

    }


    pasteEvent(e){
        e.preventDefault();
        var text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    }

    noCopyPasteFormatting(){

        /**
         * Disable formating in contenteditable
         **/

        let ds = this;

        setTimeout(function () {

            if (document.querySelector('[contenteditable="true"]')) {

                document.querySelector('[contenteditable="true"]').removeEventListener("paste", ds.pasteEvent);

                document.querySelector('[contenteditable="true"]').addEventListener("paste", ds.pasteEvent);

            }

        }, 10)

    }

    newTask(){


        let templateData = this.state.templateData;
        templateData.tasks.push({title: 'New Task', status: 'pending', new_task: true, edit: true, parent: 0, client: {name:'', id:''}, assignees: [], sub_tasks: []});


        let ds = this;

        setTimeout(function(){

            jQuery('.task-editable').focus();
            document.execCommand('selectAll', false, null);

            ds.noCopyPasteFormatting();

            //ds.moveCursorToEnd(jQuery('.task-editable')[0]);



        },100);

        this.setState(templateData);
    }

    addSubTask(idx, parent_id){


        let templateData = this.state.templateData;
        templateData.tasks[idx].sub_tasks.push({title: 'New Task', status: 'pending', new_task: true, edit: true, parent: parent_id, client: {name:'', id:''}, assignees: []});


        let ds = this;

        setTimeout(function(){


            jQuery('#task-item-'+idx).addClass('expanded').find('.task-sub-tasks').slideDown('fast');

            jQuery('.task-editable').focus();
            document.execCommand('selectAll', false, null);

            ds.noCopyPasteFormatting();


            //ds.moveCursorToEnd(jQuery('.task-editable')[0]);

        },100);

        this.setState(templateData);


    }

    moveCursorToEnd(el){
        if(el.innerText && document.createRange)
        {
            window.setTimeout(() =>
                {
                    let selection = document.getSelection();
                    let range = document.createRange();

                    range.setStart(el.childNodes[0],el.innerText.length);
                    range.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
                ,1);
        }
    }

    editTask(idx, parent_idx){

        let templateData = this.state.templateData;

        var task = {};

        if (parent_idx !== -1){

            task = templateData.tasks[parent_idx].sub_tasks[idx];

        }else{

            task = templateData.tasks[idx];
        }

        task.edit = true;

        this.setState(templateData);

        let ds = this;

        setTimeout(function(){


            jQuery('.task-editable').focus();
            document.execCommand('selectAll', false, null);

            ds.noCopyPasteFormatting();

            //ds.moveCursorToEnd(jQuery('.task-editable')[0]);

        },100);

    }


    deleteTask(idx, parent_idx){

        let templateData = this.state.templateData;


        var task = {};

        if (parent_idx !== -1){
            task = templateData.tasks[parent_idx].sub_tasks[idx];
        }else{
            task = templateData.tasks[idx];
        }


        //edit
        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/task/delete', { id: task.id } , {})
            .then(res => {

            })

            .catch((error) => {

            });


        if (parent_idx !== -1){
            templateData.tasks[parent_idx].sub_tasks.splice(idx, 1);
        }else{
            templateData.tasks.splice(idx, 1);
        }


        this.setState(templateData);

    }

    expandTask(evt){


        jQuery(evt.target).closest('.task-item').toggleClass('expanded').find('.task-sub-tasks').slideToggle('fast');

    }


    getStatus(status){

        return status.charAt(0).toUpperCase() + status.slice(1)

    }

    setTaskStatus(status, idx, parent_idx){


        let templateData = this.state.templateData;

        var task = {};

        if(parent_idx !== -1 ){

            task = templateData.tasks[parent_idx].sub_tasks[idx];

        }else{

            task = templateData.tasks[idx];
        }


        task.edit = false;
        task.status = status;

        this.setState(templateData);


        if (task.id){

            //edit
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/task/save', {task} , {})
                .then(res => {

                })

                .catch((error) => {

                });

        }


    }

    editTitle(){

        this.setState({edit_title:true});

        let ds = this;

        setTimeout(function(){

            jQuery('.custom-page-title').focus();
            document.execCommand('selectAll', false, null);

            ds.noCopyPasteFormatting();

            //ds.moveCursorToEnd(jQuery('.custom-page-title')[0]);

        },100);

    }

    toggleItemPopup(popup, task, evt, idx, parent_idx){


        evt.persist();


        this.setState({edit_popup: popup, selected_task: task, selected_task_idx: idx, selected_task_parent_idx: parent_idx});

        window.edit_popup_clicked = true;

        setTimeout(function () {

            var elPos = '';

            if(jQuery(evt.target).hasClass('col-editable-popup')){
                elPos = jQuery(evt.target).position();
            }else{
                elPos = jQuery(evt.target).closest('.col-editable-popup').position();
            }


            jQuery('.task-item-popup-'+ popup).css({left: elPos.left, top: elPos.top}).focus();


        }, 10);
        //.css({top:e.pageY, left: e.pageX});


    }

    isTaskAssignee(task, assignee){

        let assignees = task['assignees'];

        var found = -1;

        if (assignees.length > 0){

            assignees.forEach(function (v, i ) {
                if(assignee.id === v.id){
                    found = i;
                }
            });

        }

        return found;
    }

    updateTask( column, value){


        let templateData = this.state.templateData;

        var task = {};

        if(this.state.selected_task_parent_idx !== -1 ){

            task = templateData.tasks[this.state.selected_task_parent_idx].sub_tasks[this.state.selected_task_idx];

        }else{

            task = templateData.tasks[this.state.selected_task_idx];
        }


        if(column === 'assignees'){

            let assignee_idx = this.isTaskAssignee(task, value);

            if (assignee_idx !== -1){

                //remove
                task[column].splice(assignee_idx, 1);

            } else{

                //add
                task[column].push(value);
            }



        } else{

            task[column] = value;

        }



        this.setState({templateData: templateData, edit_popup: ''});


        //edit
        axios.post(process.env.REACT_APP_API_ENDPOINT + '/portal/template/task/save', {task} , {})
            .then(res => {

            })

            .catch((error) => {

            });



    }

    render() {


        return (

            <div className="custom-page-wrap" style={{'marginBottom': '150px'}}>
                {
                    !this.state.data_fetched &&
                    <div>
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    </div>
                }

                {
                    this.state.data_fetched &&

                    <div>

                        <div className="d-sm-flex align-items-center justify-content-between mg-b-20">
                            <div className="custom-page-title-wrap">
                                {/*onInput={e => console.log('Text inside div', e.currentTarget.textContent)}*/}
                                <h4 className="mg-b-10 tx-spacing--1 custom-page-title" onClick={()=>this.editTitle()} contentEditable={(this.state.edit_title && (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff')) ? true : false} suppressContentEditableWarning={true} onBlur={this.handleTitleChange}>{this.state.templateData.title}</h4>

                            </div>
                            <div className="d-md-block">

                            </div>
                        </div>

                        <div className="card tasks-wrap-card">
                            <div className="card-body">
                                <div className="tasks-wrap">
                                    <div className="tasks-scroller">

                                        <div className="task-item task-header">
                                            <div className="task-row">
                                                <div className="task-col-7">
                                                    Tasks
                                                </div>
                                                <div className="task-col-1 col-client">
                                                    Client
                                                </div>
                                                <div className="task-col-2 col-assignees">
                                                    Assignees
                                                </div>
                                                <div className="task-col-2 col-status">
                                                    Status
                                                </div>
                                            </div>
                                        </div>



                                        {
                                            this.state.templateData.tasks.length > 0 &&


                                            <div className="tasks-wrap-items">
                                            {



                                                this.state.templateData.tasks.map((task, i)=>
                                                    <div id={'task-item-'+i} className={'task-item ' + (task.sub_tasks.length?'has-child':'')} key={i} data-key={i}>


                                                        <div className="task-row">
                                                            <div className="task-col-7">
                                                                <div className="task-title">
                                                                    {
                                                                        task.sub_tasks.length > 0 &&

                                                                        <span onClick={(e)=>this.expandTask(e)} className="expand-btn action-btn link-03 fa fa-chevron-down"></span>
                                                                    }


                                                                    <div className="task-checkbox-wrap">

                                                                        {
                                                                            (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&
                                                                            <div onClick={()=>this.setTaskStatus('completed', i, -1)} className={'task-checkbox ' + task.status}></div>
                                                                        }

                                                                        {
                                                                            (this.props.current_user.role !== 'administrator' && this.props.current_user.role !== 'staff') &&
                                                                            <div className={'task-checkbox ' + task.status}></div>
                                                                        }


                                                                        {
                                                                            false && (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&

                                                                            <div className="status-change-container">
                                                                                <div className="pending" onClick={()=>this.setTaskStatus('pending', i, -1)}>Pending</div>
                                                                                <div className="in-progress" onClick={()=>this.setTaskStatus('in-progress', i, -1)}>In-progress</div>
                                                                                <div className="completed" onClick={()=>this.setTaskStatus('completed', i, -1)}>Completed</div>
                                                                            </div>
                                                                        }

                                                                    </div>

                                                                    <div className={'d-inline-block ' + (task.edit? 'task-editable':'')} onBlur={(e)=>this.handleTaskChange(e, i, -1)} suppressContentEditableWarning={true} contentEditable={task.edit?true:false}>
                                                                        {task.title}
                                                                    </div>

                                                                    {
                                                                        (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&

                                                                        <span className="task-actions">
                                                                            <span onClick={()=>this.editTask(i, -1)} className="action-btn link-03 fa fa-pencil-alt"></span>
                                                                            <span onClick={()=>this.addSubTask(i, task.id)} className="action-btn link-03 fa fa-plus"></span>

                                                                                {
                                                                                    task.sub_tasks.length <= 0 &&
                                                                                    <span onClick={()=>this.deleteTask(i, -1)} className="action-btn link-03 fa fa-trash-alt"></span>
                                                                                }



                                                                        </span>
                                                                    }



                                                                </div>
                                                            </div>
                                                            <div className="task-col-1 col-editable-popup col-client" onClick={(e)=>this.toggleItemPopup('client', task, e, i, -1)}>
                                                                {
                                                                    task.client.photo !== '' &&
                                                                    <div className="pd-5 rounded-circle wd-30 bd-gray-100 bd ht-30 lh-1 bd-2">
                                                                        <img src={task.client.photo} alt="" />
                                                                    </div>
                                                                }

                                                                {
                                                                    task.client.photo === '' &&
                                                                    <span>{task.client.name}</span>
                                                                }

                                                            </div>
                                                            <div className="task-col-2 col-editable-popup col-assignees" onClick={(e)=>this.toggleItemPopup('assignees', task, e, i, -1)}>

                                                                {
                                                                    task.assignees.map((assignee, y)=>
                                                                        <span key={y} style={{lineHeight: '32px'}} className={'mg-r-3 tx-white wd-30 ht-30 rounded-circle d-inline-block text-center tx-semibold tx-11 bg-'+assignee.color}>{assignee.initial}</span>
                                                                    )
                                                                }

                                                                {
                                                                    task.assignees.length <= 0 &&
                                                                    <span style={{lineHeight: '32px'}} className={'mg-r-3 tx-white wd-30 ht-30 rounded-circle d-inline-block text-center tx-semibold tx-11 bg-gray-100'}>
                                                                    </span>
                                                                }
                                                            </div>
                                                            <div className="task-col-2 col-editable-popup col-status"  onClick={(e)=>this.toggleItemPopup('status', task , e , i, -1)}>
                                                                <span className={'task-status ' + task.status}>{this.getStatus(task.status)}</span>
                                                            </div>
                                                        </div>


                                                        {

                                                            task.sub_tasks.length > 0 &&

                                                            <div className="task-sub-tasks">

                                                                {
                                                                    task.sub_tasks.map((task, x)=>
                                                                        <div className="task-item" key={x}>
                                                                            <div className="task-row">
                                                                                <div className="task-col-7">
                                                                                    <div className="task-title">

                                                                                        <div className="task-checkbox-wrap">




                                                                                            {
                                                                                                (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&
                                                                                                <div onClick={()=>this.setTaskStatus('completed', x, i)} className={'task-checkbox ' + task.status}></div>
                                                                                            }

                                                                                            {
                                                                                                (this.props.current_user.role !== 'administrator' && this.props.current_user.role !== 'staff') &&
                                                                                                <div className={'task-checkbox ' + task.status}></div>
                                                                                            }


                                                                                            {
                                                                                                false && (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&

                                                                                                <div className="status-change-container">
                                                                                                    <div className="pending" onClick={()=>this.setTaskStatus('pending', x, i)}>Pending</div>
                                                                                                    <div className="in-progress" onClick={()=>this.setTaskStatus('in-progress', x, i)}>In-progress</div>
                                                                                                    <div className="completed" onClick={()=>this.setTaskStatus('completed', x, i)}>Completed</div>
                                                                                                </div>
                                                                                            }

                                                                                        </div>

                                                                                        <div className={'d-inline-block ' + (task.edit? 'task-editable':'')} onBlur={(e)=>this.handleTaskChange(e, x, i)} suppressContentEditableWarning={true} contentEditable={task.edit?true:false}>
                                                                                            {task.title}
                                                                                        </div>

                                                                                        {
                                                                                            (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&

                                                                                            <span className="task-actions">
                                                                                                <span onClick={()=>this.editTask(x,i)} className="action-btn link-03 fa fa-pencil-alt"></span>
                                                                                                <span onClick={()=>this.deleteTask(x,i)} className="action-btn link-03 fa fa-trash-alt"></span>
                                                                                            </span>
                                                                                        }



                                                                                    </div>
                                                                                </div>
                                                                                <div className="task-col-1 col-editable-popup col-client" >
                                                                                    {/*onClick={(e)=>this.toggleItemPopup('client', task , e , x, i)}*/}
                                                                                    {/*<div className="pd-5 rounded-circle wd-30 bd-gray-100 bd ht-30 lh-1 bd-2">*/}
                                                                                        {/*<img src={task.client.photo} alt="" />*/}
                                                                                    {/*</div>*/}

                                                                                </div>
                                                                                <div className="task-col-2 col-editable-popup col-assignees" onClick={(e)=>this.toggleItemPopup('assignees', task, e, x, i)}>

                                                                                    {
                                                                                        task.assignees.map((assignee, z)=>
                                                                                                <span key={z} style={{lineHeight: '32px'}} className={'mg-r-3 tx-white wd-30 ht-30 rounded-circle d-inline-block text-center tx-semibold tx-11 bg-'+assignee.color}>{assignee.initial}</span>
                                                                                        )
                                                                                    }

                                                                                    {
                                                                                        task.assignees.length <= 0 &&
                                                                                        <span style={{lineHeight: '32px'}} className={'mg-r-3 tx-white wd-30 ht-30 rounded-circle d-inline-block text-center tx-semibold tx-11 bg-gray-100'}></span>
                                                                                    }

                                                                                </div>

                                                                                <div className="task-col-2 col-editable-popup col-status" onClick={(e)=>this.toggleItemPopup('status', task , e , x, i)}>
                                                                                    <span className={'task-status ' + task.status}>{this.getStatus(task.status)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                }

                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            }

                                            {this.initSortable()}

                                            </div>

                                        }


                                        {
                                            (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&

                                            <div className="task-item task-footer">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="new-task d-inline-block" onClick={() =>this.newTask()}>+ New Task</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }





                                        {
                                            (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') && this.state.edit_popup === 'client' &&

                                            <div className="task-item-popup task-item-popup-client">
                                                <h4>Client</h4>
                                                <ul>
                                                    {
                                                        this.state.templateData.clients.map((client, i)=>
                                                            <li className="d-flex align-items-center" key={i} onClick={()=>this.updateTask('client', {id: client.id, name: client.company, photo: client.photo})}>

                                                                <div className="pd-5 rounded-circle wd-30 bd-gray-100 bd ht-30 lh-2 bd-2 mg-r-10" style={{flex: '0 0 30px'}}>
                                                                    <img src={client.photo} alt="" />
                                                                </div>

                                                                <span>
                                                                    {client.company}
                                                                    <span className={this.state.selected_task.client.id === client.id?'fa fa-check':''}></span>
                                                                </span>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        }



                                        {
                                            (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') && this.state.edit_popup === 'assignees' &&

                                            <div className="task-item-popup task-item-popup-assignees">
                                                <h4>Assignees</h4>
                                                <ul>
                                                    {
                                                        this.state.templateData.assignees.map((assignee, i)=>
                                                                <li className="d-flex align-items-center" key={i} onClick={()=>this.updateTask('assignees', {id: assignee.id, name: assignee.name, photo: assignee.photo, initial: assignee.initial, color: assignee.color})}>
                                                                    <span style={{lineHeight: '27px', flex: '0 0 25px'}} className={'mg-r-10 tx-white wd-25 ht-25 rounded-circle d-inline-block text-center tx-semibold tx-11 bg-'+assignee.color}>
                                                                        {assignee.initial}
                                                                    </span>
                                                                    <span>
                                                                        {assignee.name}

                                                                        <span className={this.isTaskAssignee(this.state.selected_task, assignee) !== -1 ?'fa fa-check':''}></span>
                                                                    </span>
                                                                </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        }



                                        {
                                            (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') && this.state.edit_popup === 'status' &&

                                            <div className="task-item-popup task-item-popup-status">
                                                <h4>Status</h4>
                                                <ul>
                                                    <li onClick={()=>this.updateTask('status', 'pending')}>Pending <span className={this.state.selected_task.status ==='pending'?'fa fa-check':''}></span></li>
                                                    <li onClick={()=>this.updateTask('status', 'in-progress')}>In-progress <span className={this.state.selected_task.status ==='in-progress'?'fa fa-check':''}></span></li>
                                                    <li onClick={()=>this.updateTask('status', 'completed')}>Completed <span className={this.state.selected_task.status ==='completed'?'fa fa-check':''}></span></li>
                                                </ul>
                                            </div>
                                        }



                                    </div>

                                </div>
                            </div>
                        </div>





                    </div>

                }

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaskTemplate));