import React, { Component } from "react";
import Header from '../header';
import {StripeProvider, Elements} from 'react-stripe-elements';
import MakeAPaymentForm from './partials/make-a-payment-form';
//import { Link } from "react-router-dom";
import { setSiteData } from "../../redux-js/actions/index";

import { connect } from "react-redux";

const mapStateToProps = state => {
    return { ...state };
};

function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data)),
    };
}





class MakeAPayment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            payment: {
                first_name:'',
                last_name: '',
                email: '',
                post_name: '',
                token: ''
            }
        };



    }

    componentDidMount(){


        this.props.setSiteData({page:'make-payment'});

    }

    componentWillUnmount(){


    }

    onChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    focus = () => {
        this.editor.focus();
    };

    render() {

        return (

        <div className="page-wrap">

            <div>

                <Header />

                <div className="content content-fixed">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">

                        <main>
                            <div>
                                <div>
                                    <div style={{maxWidth:'500px', margin: '0  auto'}}>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="mg-0">Make a Payment</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="text-center"  >
                                                    <StripeProvider apiKey={this.props.site_details.stripe_publishable_key}>
                                                        <Elements>
                                                            <MakeAPaymentForm />
                                                        </Elements>
                                                    </StripeProvider>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

        </div>


        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MakeAPayment);