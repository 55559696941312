import React, { Component } from "react";
//import Header from '../header-v2';

//import { Link } from "react-router-dom";
import '../../assets/styles/Request.scss';
import { connect } from "react-redux";
import { setSiteData } from "../../redux-js/actions/index";
import Header from '../header'
import RequestSingle from './partials/request-single'
import jQuery from 'jquery';
jQuery = window.$j;

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class SingleRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {


        };

    }


    componentDidMount(){

        this.props.setSiteData({page: 'single-request-public'});

        jQuery('body').addClass('make-request-page single-request-page');

    }


    componentWillUnmount(){

        jQuery('body').removeClass('make-request-page single-request-page');
        localStorage.setItem('redirect',this.props.location.pathname);

    }


    render() {



        return (

            <div>

                <div>
                    <Header />
                    <div className="content content-fixed">
                        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                            <main>
                                <RequestSingle request_long_id={this.props.match.params.request_long_id} public={true}/>
                            </main>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleRequest);