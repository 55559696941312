import React, { Component } from "react";


import { connect } from "react-redux";
import { Route } from "react-router-dom";

import RegularMakeRequestForm from './partials/make-request-form'
import InteractiveMakeRequestForm from './partials/make-request-interactive-form'
import { setSiteData } from "../../redux-js/actions/index";
import Header from '../header'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setSiteData: site_data => dispatch(setSiteData(site_data))
    };
}


class MakeRequest extends Component {


    constructor(props){
        super(props);

        this.state = {
        }

    }

    componentDidMount(){

        window.$j('body').addClass('make-request-page');


        this.props.setSiteData({page: 'make-a-request-public'});


        if (this.props.current_user.role === 'client'){

            //console.log(this.props.current_user);



            ///

            this.props.history.push("/portal/projects/"+this.props.current_user.slug+"/make-request/");

        }





    }

    componentWillUnmount(){

        window.$j('body').removeClass('make-request-page');
    }

    render() {


        return (

        <div className="page-wrap project-book-page-wrap">

            <div>

                <Header />
                <div className="content content-fixed">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                        <div style={{'maxWidth':'1000px','margin':'0 auto'}}>
                            <main>

                                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                    <div>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                                <li className="breadcrumb-item">Work Requests</li>
                                                <li className="breadcrumb-item active" aria-current="page">Make a Request</li>
                                            </ol>
                                        </nav>
                                        <h4 className="mg-b-10 tx-spacing--1">Make a Request</h4>

                                    </div>
                                    <div className="d-none d-md-block">

                                    </div>
                                </div>

                                <div className="card">

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">

                                                <div className="make-request-form-wrap">

                                                    <div className="pd-t-0">
                                                        <p className="tx-13 tx-color-02 mg-b-0"  style={{'max-width':'700px'}}>Use this page to make a request to D6. We will review & get back to you with a scope/turnaround. </p>
                                                    </div>

                                                    <Route exact path={`${this.props.match.path}/`} component={RegularMakeRequestForm}/>
                                                    <Route exact path={`${this.props.match.path}/interactive-form`} component={InteractiveMakeRequestForm}/>

                                                    {/*<RequestForm client_slug={this.props.match.params.client} />*/}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </main>
                        </div>
                    </div>
                </div>
            </div>

        </div>



        );
    }
}
 

export default connect(mapStateToProps, mapDispatchToProps)(MakeRequest);