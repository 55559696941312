import React, { Component } from "react";
import axios from 'axios';
import {CardElement, injectStripe} from 'react-stripe-elements';

import { connect } from "react-redux";
import { setPaymentMade } from "../../../redux-js/actions/index";





const mapStateToProps = state => {
    return { payment_made: state.payment_made };
};


function mapDispatchToProps(dispatch) {
    return {
        setPaymentMade: payment_made => dispatch(setPaymentMade(payment_made))
    };
}



class MakeAPaymentForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            payment: {
                first_name:'',
                last_name: '',
                email: '',
                token: '',
                note: '',
                company:'',
                amount:'',
            }
        };


        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
    }



    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        var payment = this.state.payment;
        payment[name] = value;

        this.setState({payment});
    }


    async handleSubmit() {

        this.setState({errorMsg: '', msg: ''});

        if (this.state.payment.first_name === '') {
            this.setState({errorMsg: 'First name field is required.'});
            return false;
        }

        if (this.state.payment.last_name === '') {
            this.setState({errorMsg: 'Last name field is required.'});
            return false;
        }

        if (this.state.payment.email === '') {
            this.setState({errorMsg: 'Email field is required.'});
            return false;
        }

        if (this.state.payment.company === '') {
            this.setState({errorMsg: 'Company name field is required.'});
            return false;
        }

        if (this.state.payment.amount === '') {
            this.setState({errorMsg: 'Amount field is required.'});
            return false;
        }


        let payment = this.state.payment;

        this.setState({btnEnabled: false});


        let card_holder_name = this.state.payment.first_name +' '+this.state.payment.last_name;

        let stripe_response = await this.props.stripe.createToken({name: card_holder_name});

        //console.log(token);

        if (stripe_response.error){

            //console.log(error.response.data);
            this.setState({errorMsg: stripe_response.error.message});
            this.setState({btnEnabled:true});


        }else{

            if (stripe_response.token){

                payment.token = stripe_response.token;


                //let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

                let headers = { };

                axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/make-a-payment', { payment }, headers)
                    .then(res => {

                        if(res.data.success){

                            this.setState(
                                {
                                    payment: {
                                        first_name:'',
                                        last_name: '',
                                        email: '',
                                        amount: '',
                                        note: '',
                                        company: '',
                                        token: '',
                                    },

                                    msg: res.data.msg
                                }
                            );

                            this._element.clear();

                            this.props.setPaymentMade(true);

                        }else{

                            this.setState({errorMsg: res.data.msg});

                        }

                        this.setState({btnEnabled: true});
                    })


                    .catch((error) => {

                        //console.log(error.response.data);
                        this.setState({errorMsg: error.response.data.message});
                        this.setState({btnEnabled:true});

                    });

            }else{

                this.setState({btnEnabled: true});
            }

        }



    }

    componentDidMount(){



    }


    render() {

        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;

        return (

            <div>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="first_name" placeholder="First Name"  value={this.state.payment.first_name} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="last_name" placeholder="Last Name"  value={this.state.payment.last_name} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="email" placeholder="Email Address" value={this.state.payment.email} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" name="company" placeholder="Company Name"  value={this.state.payment.company} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="number" name="amount" placeholder="Amount" value={this.state.payment.amount} onChange={this.handleInputChange} className="form-control payment-form-control"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group card-field">
                                <CardElement onReady={(c) => this._element = c}/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <textarea type="note" name="note" placeholder="Payment Note" value={this.state.payment.note} onChange={this.handleInputChange} className="form-control payment-form-control"></textarea>
                            </div>
                        </div>


                    </div>


                    <div style={{marginTop:'20px'}}>
                        {errorMsg &&

                        <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: errorMsg}}></div>
                        }

                        {msg &&
                        <div className="alert alert-success" dangerouslySetInnerHTML={{__html: msg}}></div>
                        }
                    </div>

                    <div className="text-center">
                        <button disabled={!this.state.btnEnabled} onClick={this.handleSubmit} type="button" className="btn btn-primary"><span>Submit</span></button>
                    </div>

                    {
                        !this.state.btnEnabled &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>

                    }


            </div>

        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(MakeAPaymentForm));
